export enum UserRole {
  Admin = 1,
  CompanyAdmin = 2,
}

type User = {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  language?: string | null;
  companyId: string;
};

export default User;
