import {Box, Typography} from '@mui/material';
import {ElementType, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {ReactComponent as NumberFiveIcon} from '../../assets/icons/number-five.svg';
import {ReactComponent as NumberFourIcon} from '../../assets/icons/number-four.svg';
import {ReactComponent as NumberOneIcon} from '../../assets/icons/number-one.svg';
import {ReactComponent as NumberThreeIcon} from '../../assets/icons/number-three.svg';
import {ReactComponent as NumberTwoIcon} from '../../assets/icons/number-two.svg';
import Oven from '../../models/entities/oven';
import OvenGroup from '../../models/entities/oven-group';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import arrayUtils from '../../utils/arrays';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import {pageHeight} from '../navigation/Navbar';
import Chart from './Chart';

type OvenGroupMenuProps = {
  ovenGroup: OvenGroup;
  selectedOvenId?: string;
  onSelectOvenPanel?: (ovenId: string, ovenPanelId: string) => void;
};

function OvenGroupMenu(props: OvenGroupMenuProps) {
  const {ovenGroup, selectedOvenId, onSelectOvenPanel} = props;
  const {t} = useTranslation();

  function handleSelectOven(ovenId: string) {
    const oven = ovenGroup.ovens?.find((oven) => oven.id === ovenId);
    const ovenPanel = oven?.ovenPanels?.[0];
    if (oven != null && ovenPanel != null) {
      onSelectOvenPanel?.(oven.id, ovenPanel.id);
    }
  }

  const ovens = useMemo(
    () =>
      Array.from(ovenGroup.ovens ?? [])
        .sort((ovenA, ovenB) => (ovenB.ovenGroupOrder ?? 1) - (ovenA.ovenGroupOrder ?? 1))
        .slice(0, 2),
    [ovenGroup],
  );

  return (
    <Box>
      <Typography variant="body2" color="secondary" sx={{fontWeight: 'bold', marginBottom: 4}}>
        {t('statistics_select_oven_label')}
      </Typography>
      {ovens.map((oven) => (
        <Typography
          key={oven.id}
          variant="body2"
          color="secondary"
          sx={{
            fontWeight: 'bold',
            marginBottom: 2,
            opacity: selectedOvenId === oven.id ? 1 : 0.5,
            cursor: 'pointer',
          }}
          onClick={() => handleSelectOven(oven.id)}>
          {oven.ovenGroupOrder === 1 ? t('statistics_base_label') : t('statistics_top_label')}
        </Typography>
      ))}
    </Box>
  );
}

function getNumberIcon(index: number): ElementType<any> {
  switch (index) {
    case 2:
      return NumberTwoIcon;
    case 3:
      return NumberThreeIcon;
    case 4:
      return NumberFourIcon;
    case 5:
      return NumberFiveIcon;
    default:
      return NumberOneIcon;
  }
}

type OvenMenuProps = {
  oven: Oven;
  selectedOvenPanelId?: string;
  selectedOvenChamberId?: string;
  onSelectOvenPanel?: (ovenPanelId: string, ovenChamberId?: string) => void;
};

function OvenMenu(props: OvenMenuProps) {
  const {oven, selectedOvenPanelId, selectedOvenChamberId, onSelectOvenPanel} = props;
  const {t} = useTranslation();

  const ovenChambers = useMemo(
    () =>
      Array.from(oven.ovenChambers ?? []).sort(
        (ovenChamberA, ovenChamberB) =>
          (ovenChamberB.ovenOrder ?? 1) - (ovenChamberA.ovenOrder ?? 1),
      ),
    [oven],
  );

  const ovenPanels = useMemo(
    () =>
      Array.from(oven.ovenPanels ?? []).sort(
        (ovenPanelA, ovenPanelB) => (ovenPanelB.ovenOrder ?? 1) - (ovenPanelA.ovenOrder ?? 1),
      ),
    [oven],
  );

  function handleSelectOvenChamber(ovenChamberId: string) {
    const ovenChamber = oven.ovenChambers?.find((ovenChamber) => ovenChamber.id === ovenChamberId);
    const ovenPanel = ovenChamber?.ovenPanels?.[0];
    if (ovenChamber != null && ovenPanel != null) {
      onSelectOvenPanel?.(ovenPanel.id, ovenChamber.id);
    }
  }

  function handleSelectOvenPanel(ovenPanelId: string) {
    onSelectOvenPanel?.(ovenPanelId);
  }

  const renderOvenChamberOptions = !arrayUtils.isNullOrEmpty(oven.ovenChambers);
  const renderOvenPanelOptions =
    !renderOvenChamberOptions && !arrayUtils.isNullOrEmpty(oven.ovenPanels);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
      <Typography variant="body2" color="secondary" sx={{fontWeight: 'bold', marginBottom: 4}}>
        {t('statistics_select_chamber_label')}
      </Typography>
      {renderOvenChamberOptions &&
        ovenChambers.map((ovenChamber) => (
          <IconButton
            key={ovenChamber.id}
            IconComponent={getNumberIcon(ovenChamber.ovenOrder ?? 1)}
            isActive={ovenChamber.id === selectedOvenChamberId}
            activeColor="text.primary"
            inactiveColor="text.secondary"
            onClick={() => handleSelectOvenChamber(ovenChamber.id)}
          />
        ))}
      {renderOvenPanelOptions &&
        ovenPanels.map((ovenPanel) => (
          <IconButton
            key={ovenPanel.id}
            IconComponent={getNumberIcon(ovenPanel.ovenOrder ?? 1)}
            isActive={ovenPanel.id === selectedOvenPanelId}
            activeColor="text.primary"
            inactiveColor="text.secondary"
            onClick={() => handleSelectOvenPanel(ovenPanel.id)}
          />
        ))}
    </Box>
  );
}

export type OvenStatisticsProps = {
  selectedOvenGroup?: OvenGroup;
  selectedOven?: Oven;
  selectedOvenChamberId: string | null;
  selectedOvenPanelId: string | null;
  onSelectOvenPanel: (ovenId: string, ovenPanelId: string, ovenChamberId?: string) => void;
};

function OvenStatistics(props: OvenStatisticsProps) {
  const {
    selectedOvenGroup,
    selectedOven,
    selectedOvenChamberId,
    selectedOvenPanelId,
    onSelectOvenPanel,
  } = props;

  const user = useAuthStore((state) => state.user);

  const {data: statistics = [], isLoading} = useQuery({
    enabled: user != null && selectedOvenPanelId != null,
    queryKey: ['ovenPanelStatistics', selectedOvenPanelId],
    queryFn: () =>
      services.ovenPanel.getOvenPanelStatistics({
        params: {
          ovenPanelId: selectedOvenPanelId!,
        },
        query: {
          numberOfDays: 7,
        },
      }),
  });

  const renderOvenGroupMenu = selectedOvenGroup != null;
  const renderOvenMenu =
    selectedOvenGroup == null &&
    selectedOven != null &&
    (arrayUtils.hasMultipleItems(selectedOven.ovenChambers) ||
      arrayUtils.hasMultipleItems(selectedOven.ovenPanels));
  const renderChart = statistics != null;

  return (
    <Box sx={{display: 'flex', padding: 2}}>
      {renderOvenGroupMenu && (
        <Box sx={{width: '160px'}}>
          <OvenGroupMenu
            ovenGroup={selectedOvenGroup}
            selectedOvenId={selectedOven?.id ?? undefined}
            onSelectOvenPanel={(ovenId, ovenPanelId) => onSelectOvenPanel(ovenId, ovenPanelId)}
          />
        </Box>
      )}
      {renderOvenMenu && (
        <Box sx={{width: '160px'}}>
          <OvenMenu
            oven={selectedOven}
            selectedOvenPanelId={selectedOvenPanelId ?? undefined}
            selectedOvenChamberId={selectedOvenChamberId ?? undefined}
            onSelectOvenPanel={(ovenPanelId, ovenChamberId) =>
              onSelectOvenPanel(selectedOven.id, ovenPanelId, ovenChamberId)
            }
          />
        </Box>
      )}
      {renderChart && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: renderOvenMenu || renderOvenGroupMenu ? 'calc(65vw - 160px)' : '65vw',
            height: `calc(${pageHeight} - 144px)`,
            paddingBlock: 4,
          }}>
          <Chart statistics={statistics} />
        </Box>
      )}
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default OvenStatistics;
