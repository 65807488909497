import {Box, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import paths from '../../routes/paths';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import LoadingBackdrop from '../common/LoadingBackdrop';

// TODO: Get bakery id from search params
function BakeryInvite() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  const {t} = useTranslation();

  const [email, setEmail] = useState('');

  function handleInvite() {
    splash({
      title: t('splash_screen_bakery_assigned_title'),
      caption: t('splash_screen_bakery_assigned_caption'),
      nextAction: () => navigate(paths.dashboard),
    });
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{width: '25vw', marginBottom: '10vh', fontWeight: '500'}}>
        {t('signup_assign_bakery_title')}
      </Typography>
      <TextField
        variant="standard"
        label={t('signup_assign_bakery_email_input_label')}
        sx={{marginBottom: '25vh'}}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        onKeyUp={(event) => (event.key === 'Enter' ? handleInvite() : null)}
      />
      <Button onClick={handleInvite} disabled={stringUtils.isNullOrWhiteSpace(email)}>
        {t('signup_assign_bakery_assign_button_label')}
      </Button>
      <LoadingBackdrop isLoading={false} />
    </Box>
  );
}

export default BakeryInvite;
