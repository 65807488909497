import config from '../config';
import authService, {AuthService} from './auth';
import bakeryService, {BakeryService} from './bakery';
import baseService from './base';
import cityService, {CityService} from './city';
import cleaningService, {CleaningService} from './cleaning';
import companyService, {CompanyService} from './company';
import countryService, {CountryService} from './country';
import districtService, {DistrictService} from './district';
import mockAuthService from './mock/auth';
import mockBakeryService from './mock/bakery';
import mockCityService from './mock/city';
import mockCleaningService from './mock/cleaning';
import mockCompanyService from './mock/company';
import mockCountryService from './mock/country';
import mockDistrictService from './mock/district';
import mockNotificationService from './mock/notification';
import mockNotificationTypeService from './mock/notification-type';
import mockOvenService from './mock/oven';
import mockOvenChamberService from './mock/oven-chamber';
import mockOvenGroupService from './mock/oven-group';
import mockOvenModelService from './mock/oven-model';
import mockOvenPanelService from './mock/oven-panel';
import mockRecipeService from './mock/recipe';
import mockRecipeSchemaService from './mock/recipe-schema';
import mockRecipeTypeService from './mock/recipe-type';
import mockScheduleService from './mock/schedule';
import mockSubscriptionService from './mock/subscription';
import mockUserService from './mock/user';
import notificationService, {NotificationService} from './notification';
import notificationTypeService, {NotificationTypeService} from './notification-type';
import ovenService, {OvenService} from './oven';
import ovenChamberService, {OvenChamberService} from './oven-chamber';
import ovenGroupService, {OvenGroupService} from './oven-group';
import ovenModelService, {OvenModelService} from './oven-model';
import ovenPanelService, {OvenPanelService} from './oven-panel';
import recipeService, {RecipeService} from './recipe';
import recipeSchemaService, {RecipeSchemaService} from './recipe-schema';
import recipeTypeService, {RecipeTypeService} from './recipe-type';
import scheduleService, {ScheduleService} from './schedule';
import subscriptionService, {SubscriptionService} from './subscription';
import userService, {UserService} from './user';

export enum ServiceType {
  Web = 'web',
  Mock = 'mock',
}

export let serviceType = config.serviceType as ServiceType;

type BaseService = typeof baseService;

type Services = {
  base: BaseService;
  auth: AuthService;
  country: CountryService;
  district: DistrictService;
  city: CityService;
  company: CompanyService;
  bakery: BakeryService;
  user: UserService;
  notificationType: NotificationTypeService;
  notification: NotificationService;
  subscription: SubscriptionService;
  ovenModel: OvenModelService;
  ovenGroup: OvenGroupService;
  oven: OvenService;
  ovenChamber: OvenChamberService;
  ovenPanel: OvenPanelService;
  recipeType: RecipeTypeService;
  recipe: RecipeService;
  recipeSchema: RecipeSchemaService;
  cleaning: CleaningService;
  schedule: ScheduleService;
};

const services: Services = {
  get base(): BaseService {
    return baseService;
  },
  get auth(): AuthService {
    return serviceType === ServiceType.Web ? authService : mockAuthService;
  },
  get country(): CountryService {
    return serviceType === ServiceType.Web ? countryService : mockCountryService;
  },
  get district(): DistrictService {
    return serviceType === ServiceType.Web ? districtService : mockDistrictService;
  },
  get city(): CityService {
    return serviceType === ServiceType.Web ? cityService : mockCityService;
  },
  get company(): CompanyService {
    return serviceType === ServiceType.Web ? companyService : mockCompanyService;
  },
  get bakery(): BakeryService {
    return serviceType === ServiceType.Web ? bakeryService : mockBakeryService;
  },
  get user(): UserService {
    return serviceType === ServiceType.Web ? userService : mockUserService;
  },
  get notificationType(): NotificationTypeService {
    return serviceType === ServiceType.Web ? notificationTypeService : mockNotificationTypeService;
  },
  get notification(): NotificationService {
    return serviceType === ServiceType.Web ? notificationService : mockNotificationService;
  },
  get subscription(): SubscriptionService {
    return serviceType === ServiceType.Web ? subscriptionService : mockSubscriptionService;
  },
  get ovenModel(): OvenModelService {
    return serviceType === ServiceType.Web ? ovenModelService : mockOvenModelService;
  },
  get ovenGroup(): OvenGroupService {
    return serviceType === ServiceType.Web ? ovenGroupService : mockOvenGroupService;
  },
  get oven(): OvenService {
    return serviceType === ServiceType.Web ? ovenService : mockOvenService;
  },
  get ovenChamber(): OvenChamberService {
    return serviceType === ServiceType.Web ? ovenChamberService : mockOvenChamberService;
  },
  get ovenPanel(): OvenPanelService {
    return serviceType === ServiceType.Web ? ovenPanelService : mockOvenPanelService;
  },
  get recipeType(): RecipeTypeService {
    return serviceType === ServiceType.Web ? recipeTypeService : mockRecipeTypeService;
  },
  get recipe(): RecipeService {
    return serviceType === ServiceType.Web ? recipeService : mockRecipeService;
  },
  get recipeSchema(): RecipeSchemaService {
    return serviceType === ServiceType.Web ? recipeSchemaService : mockRecipeSchemaService;
  },
  get cleaning(): CleaningService {
    return serviceType === ServiceType.Web ? cleaningService : mockCleaningService;
  },
  get schedule(): ScheduleService {
    return serviceType === ServiceType.Web ? scheduleService : mockScheduleService;
  },
};

export default services;
