import {
  GetOvenChambersQuery,
  UpdateOvenChamberParams,
  UpdateOvenChamberRequest,
} from '../../models/requests/oven-chamber';
import {
  GetOvenChambersResponse,
  UpdateOvenChamberResponse,
} from '../../models/responses/oven-chamber';
import {ApplicationError} from '../../utils/errors';
import {OvenChamberService} from '../oven-chamber';
import {data} from './data';

const mockOvenChamberService: OvenChamberService = {
  getOvenChambers: function (args: {
    query: GetOvenChambersQuery;
  }): Promise<GetOvenChambersResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const ovenChambers = data.ovenChambers
          .filter(
            (ovenChamber) =>
              query.bakeryIds == null ||
              (ovenChamber.bakeryId != null && query.bakeryIds.includes(ovenChamber.bakeryId)),
          )
          .map((ovenChamber) => ({...ovenChamber}));

        resolve(ovenChambers);
      }, 500),
    );
  },
  updateOvenChamber: function (args: {
    params: UpdateOvenChamberParams;
    request: UpdateOvenChamberRequest;
  }): Promise<UpdateOvenChamberResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        const ovenChamber = data.ovenChambers.find(
          (ovenChamber) => ovenChamber.id === params.ovenChamberId,
        );

        if (ovenChamber == null) {
          reject(ApplicationError.notFound('Oven chamber'));
          return;
        }

        ovenChamber.ovenId = request.ovenId;
        ovenChamber.ovenOrder = request.ovenOrder;
        ovenChamber.bakeryId = request.bakeryId;
        ovenChamber.assignedAt = new Date().toISOString();

        resolve(ovenChamber.id);
      }, 500),
    );
  },
};

export default mockOvenChamberService;
