import {Box, Paper, Typography} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as FlagIcon} from '../../assets/icons/flag.svg';
import Notification from '../../models/entities/notification';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import dateUtils from '../../utils/dates';
import notificationUtils from '../../utils/notifications';
import stringUtils from '../../utils/strings';
import {Language} from '../../utils/types';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Span from '../common/Span';
import Switch from '../common/Switch';
import Tabs from '../common/Tabs';
import {pageHeight} from '../navigation/Navbar';
import NotificationList from './NotificationList';

function getTabIndex(tab?: string) {
  switch (tab) {
    case 'maintenance':
      return 1;
    case 'flagged':
      return 2;
    case 'warnings':
    default:
      return 0;
  }
}

function Notifications() {
  const navigate = useNavigate();
  const {tab, notificationId} = useParams();
  const {t, i18n} = useTranslation();
  const queryClient = useQueryClient();

  const user = useAuthStore((state) => state.user);

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [isSelectingMultiple, setIsSelectingMultiple] = useState(false);
  const [selectedNotificationIds, setSelectedNotificationIds] = useState<string[]>([]);

  const tabs = [
    {value: 'warnings', label: t('profile_notifications_tabs_warnings_label')},
    {value: 'maintenance', label: t('profile_notifications_tabs_maintenance_label')},
    {value: 'flagged', label: t('profile_notifications_tabs_flagged_label')},
  ];
  const selectedTabIndex = getTabIndex(tab);
  const selectedTab = tabs[selectedTabIndex];

  useEffect(() => {
    setBreadcrumbs([
      {
        title: t('profile_breadcrumb'),
        onClick: () => navigate(paths.profile),
      },
      {
        title: t('profile_notifications_breadcrumb'),
        onClick: () => {
          setIsSelectingMultiple(false);
          setSelectedNotificationIds([]);
          navigate(`${paths.profileNotifications}/warnings`);
        },
      },
      {title: selectedTab.label},
    ]);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const {data: notifications, isLoading: isLoadingNotifications} = useQuery({
    enabled: user != null,
    queryKey: ['notifications'],
    queryFn: () =>
      services.notification.getNotifications({
        query: {userId: user?.id, companyId: user?.companyId},
      }),
  });

  const {mutate: updateNotification} = useMutation({
    mutationFn: services.notification.updateNotification,
    onMutate: async ({request: updatedNotification}) => {
      await queryClient.cancelQueries('notifications');
      const previousNotifications = queryClient.getQueryData<Notification[]>(['notifications']);
      queryClient.setQueryData<Notification[] | undefined>(['notifications'], (notifications) =>
        notifications?.map((notification) =>
          notification.id === updatedNotification.id ? updatedNotification : notification,
        ),
      );
      return {previousNotifications};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(['notifications'], context?.previousNotifications);
    },
    onSettled: () => {
      queryClient.invalidateQueries('notifications');
    },
  });

  const {mutate: deleteNotification, isLoading: isLoadingDeleteNotification} = useMutation({
    mutationFn: services.notification.deleteNotification,
    onSuccess: (notificationId) => {
      queryClient.setQueryData<Notification[] | undefined>(['notifications'], (notifications) =>
        notifications?.filter((notification) => notification.id !== notificationId),
      );
      queryClient.invalidateQueries('notifications');
    },
  });

  const {mutate: deleteNotifications, isLoading: isLoadingDeleteNotifications} = useMutation({
    mutationFn: services.notification.deleteNotifications,
    onSuccess: (notificationIds) => {
      queryClient.setQueryData<Notification[] | undefined>(['notifications'], (notifications) =>
        notifications?.filter((notification) => !notificationIds.includes(notification.id)),
      );
      queryClient.invalidateQueries('notifications');
    },
  });

  const totals = useMemo(() => {
    let unseenWarnings = 0;
    let unseenMaintenance = 0;
    let flagged = 0;
    notifications?.forEach((notification) => {
      if (notificationUtils.isWarning(notification) && !notification.isSeen) {
        unseenWarnings++;
      }
      if (notificationUtils.isMaintenance(notification) && !notification.isSeen) {
        unseenMaintenance++;
      }
      if (notification.isFlagged) {
        flagged++;
      }
    });
    return {unseenWarnings, unseenMaintenance, flagged};
  }, [notifications]);

  const sortedNotifications = useMemo(
    () =>
      Array.from(notifications ?? []).sort((notificationA, notificationB) => {
        if (notificationA.isSeen === notificationB.isSeen) {
          return (
            new Date(notificationB.createdAt).getTime() -
            new Date(notificationA.createdAt).getTime()
          );
        }
        return notificationA.isSeen ? 1 : -1;
      }),
    [notifications],
  );

  const selectedNotification = notifications?.find(
    (notification) => notification.id === notificationId,
  );

  const filteredNotifications = sortedNotifications.filter(
    (notification) =>
      (selectedTabIndex === 0 && notificationUtils.isWarning(notification)) ||
      (selectedTabIndex === 1 && notificationUtils.isMaintenance(notification)) ||
      (selectedTabIndex === 2 && notification.isFlagged),
  );

  useEffect(() => {
    if (selectedNotification != null) {
      updateNotification({
        params: {notificationId: selectedNotification.id},
        request: {...selectedNotification, isSeen: true},
      });
    }
  }, [selectedNotification, updateNotification]);

  function handleChangeSelectedTab(tabIndex: number) {
    setIsSelectingMultiple(false);
    setSelectedNotificationIds([]);
    navigate(`${paths.profileNotifications}/${tabs[tabIndex].value}`);
  }

  function handleSelectAllNotifications(checked: boolean) {
    if (checked) {
      setIsSelectingMultiple(true);
      setSelectedNotificationIds(filteredNotifications.map((notification) => notification.id));
      return;
    }
    setIsSelectingMultiple(false);
    setSelectedNotificationIds([]);
  }

  function handleCheckNotification(notificationId: string, checked: boolean) {
    setSelectedNotificationIds((notificationIds) => {
      if (checked) {
        return [...notificationIds, notificationId];
      }
      return notificationIds.filter((id) => id !== notificationId);
    });
  }

  function handleFlagNotification(notificationId: string, isFlagged: boolean) {
    const notification = notifications?.find((notification) => notification.id === notificationId);
    if (notification != null) {
      updateNotification({
        params: {notificationId},
        request: {...notification, isFlagged: !isFlagged},
      });
    }
  }

  function handleDeleteNotification(notificationId: string) {
    deleteNotification({
      params: {notificationId},
    });
  }

  function handleDeleteSelectedNotifications() {
    deleteNotifications(
      {request: selectedNotificationIds},
      {onSuccess: () => setIsSelectingMultiple(false)},
    );
  }

  const isLoading =
    isLoadingNotifications || isLoadingDeleteNotifications || isLoadingDeleteNotification;

  const renderSelectAllSwitch = selectedNotification == null && filteredNotifications.length > 0;

  return (
    <Box sx={{width: '80vw'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '112px',
        }}>
        <Tabs
          hideIndicator
          values={[
            `${tabs[0].label}${totals.unseenWarnings > 0 ? ` (${totals.unseenWarnings})` : ''}`,
            `${tabs[1].label}${
              totals.unseenMaintenance > 0 ? ` (${totals.unseenMaintenance})` : ''
            }`,
            `${tabs[2].label}${totals.flagged > 0 ? ` (${totals.flagged})` : ''}`,
          ]}
          selectedTabIndex={selectedTabIndex}
          onTabClick={handleChangeSelectedTab}
        />
        {renderSelectAllSwitch && (
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '65vw', textAlign: 'right', padding: 2}}>
              <Typography variant="body2" sx={{fontWeight: 100, color: 'text.primary'}}>
                {t('profile_notifications_select_all_label')}
              </Typography>
            </Box>
            <Box sx={{width: '15vw', padding: 1}}>
              <Switch
                checked={isSelectingMultiple}
                onChange={(_, checked) => handleSelectAllNotifications(checked)}
              />
              {isSelectingMultiple && (
                <IconButton
                  IconComponent={DeleteIcon}
                  sx={{marginLeft: 2}}
                  onClick={handleDeleteSelectedNotifications}
                />
              )}
            </Box>
          </Box>
        )}
        {selectedNotification != null && (
          <Box sx={{display: 'flex', paddingBlock: 2}}>
            <Paper
              elevation={4}
              sx={{
                display: 'flex',
                width: '65vw',
                paddingBlock: 1,
                paddingInline: 2,
                borderRadius: '10px',
                backgroundColor: '#F4F4F4',
              }}>
              <Typography variant="body2" sx={{flex: 1}}>
                <Span sx={{fontWeight: 'bold'}}>{selectedNotification.ovenDescription}</Span>
                <Span sx={{marginInline: '5px'}}>|</Span>
                <Span>{selectedNotification.bakeryName}</Span>
              </Typography>
              <Typography variant="body2" sx={{fontWeight: 500, color: 'text.primary'}}>
                {dateUtils.toLocaleDateString(
                  new Date(selectedNotification.createdAt),
                  i18n.language as Language,
                )}
              </Typography>
            </Paper>
            <Box sx={{margin: 'auto'}}>
              <IconButton
                IconComponent={DeleteIcon}
                onClick={() => handleDeleteNotification(selectedNotification.id)}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{height: `calc(${pageHeight} - 112px)`, paddingBottom: 6}}>
        {selectedNotification != null && (
          <Box sx={{display: 'flex'}}>
            <Box sx={{width: '65vw', padding: 2}}>
              <Typography variant="body2" color="secondary" whiteSpace="pre-line">
                {stringUtils.format(
                  t(notificationUtils.getLabel(selectedNotification)),
                  selectedNotification.ovenPanelOrder ?? 1,
                )}
              </Typography>
            </Box>
            <Box sx={{marginInline: 'auto'}}>
              <IconButton
                IconComponent={FlagIcon}
                activeColor="primary"
                isActive={Boolean(selectedNotification.isFlagged)}
                onClick={() =>
                  handleFlagNotification(
                    selectedNotification.id,
                    Boolean(selectedNotification.isFlagged),
                  )
                }
              />
            </Box>
          </Box>
        )}
        {selectedNotification == null && (
          <NotificationList
            notifications={filteredNotifications}
            onSelectNotification={(notificationId) =>
              navigate(`${paths.profileNotifications}/${selectedTab.value}/${notificationId}`)
            }
            onCheckNotification={handleCheckNotification}
            onDeleteNotification={handleDeleteNotification}
            isSelectingMultiple={isSelectingMultiple}
            selectedNotificationIds={selectedNotificationIds}
            notificationsNotFoundLabel={isLoading ? '' : t('profile_notifications_not_found_label')}
          />
        )}
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default Notifications;
