import {GetCleaningsQuery} from '../../models/requests/cleaning';
import {GetCleaningsResponse} from '../../models/responses/cleaning';
import {CleaningService} from '../cleaning';
import {data} from './data';

const mockCleaningService: CleaningService = {
  getCleanings: function (args: {query?: GetCleaningsQuery}): Promise<GetCleaningsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const cleanings = data.cleanings
          .filter(
            (cleaning) => query?.ovenModelId == null || cleaning.ovenModelId === query.ovenModelId,
          )
          .map((cleaning) => ({...cleaning}));

        resolve(cleanings);
      }, 500),
    );
  },
};

export default mockCleaningService;
