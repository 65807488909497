import {ListItem, ListItemIcon, Typography} from '@mui/material';
import {ElementType, useState} from 'react';
import Icon from '../common/Icon';

type DrawerMenuItemProps = {
  icon: ElementType<any>;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

function DrawerMenuItem(props: DrawerMenuItemProps) {
  const {icon, text, onClick, disabled} = props;

  const [isHovered, setIsHovered] = useState(false);

  function getColor() {
    if (disabled) {
      return 'text.secondary';
    }
    return isHovered ? 'primary' : 'secondary';
  }

  return (
    <ListItem
      sx={{
        maxWidth: '200px',
        cursor: disabled ? undefined : 'pointer',
      }}
      onClick={() => (disabled ? null : onClick?.())}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <ListItemIcon>
        <Icon IconComponent={icon} color={getColor()} size="30px" />
      </ListItemIcon>
      {!disabled && isHovered && (
        <Typography color="primary">{text}</Typography>
      )}
    </ListItem>
  );
}

export default DrawerMenuItem;
