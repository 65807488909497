import {GetRecipeSchemasQuery} from '../models/requests/recipe-schema';
import {GetRecipeSchemasResponse} from '../models/responses/recipe-schema';
import baseService from './base';
import endpoints from './endpoints';

export type RecipeSchemaService = {
  getRecipeSchemas: (args: {query: GetRecipeSchemasQuery}) => Promise<GetRecipeSchemasResponse>;
};

const recipeSchemaService: RecipeSchemaService = {
  getRecipeSchemas: async function (args: {
    query: GetRecipeSchemasQuery;
  }): Promise<GetRecipeSchemasResponse> {
    const {query} = args;

    const result = await baseService.get<GetRecipeSchemasResponse, GetRecipeSchemasQuery>(
      endpoints.recipeSchemas,
      query,
    );

    return result;
  },
};

export default recipeSchemaService;
