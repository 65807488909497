import {Box, List, ListItem, Typography} from '@mui/material';
import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import {ReactComponent as CircleArrowUpRightIcon} from '../../assets/icons/circle-arrow-up-right.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import Bakery from '../../models/entities/bakery';
import City from '../../models/entities/city';
import Country from '../../models/entities/country';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import stringUtils from '../../utils/strings';
import ConfirmPopover from '../common/ConfirmPopover';
import IconButton from '../common/IconButton';

function UpdateNameContainer({
  defaultName,
  onUpdateName,
}: {
  defaultName: string;
  onUpdateName: (name: string) => void;
}) {
  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [name, setName] = useState(defaultName);

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleUpdateName() {
    if (!stringUtils.isNullOrWhiteSpace(name) && name !== defaultName) {
      onUpdateName(name);
    }
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box ref={containerRef} sx={{display: 'flex', justifyContent: 'center', width: '15vw'}}>
      <IconButton
        sx={{zIndex: open ? 1500 : undefined}}
        IconComponent={EditIcon}
        isActive={open}
        onClick={() => {
          setName(defaultName);
          setAnchorEl(open ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        anchorEl={anchorEl}
        placeHolder={t('recipe_add_recipe_type_label')}
        value={name}
        onChange={setName}
        onAccept={handleUpdateName}
        onDecline={() => {
          setName(defaultName);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

export type BakerySettingsProps = {
  selectedCountry: Country;
  selectedCity: City;
  selectedBakery: Bakery;
};

function BakerySettings(props: BakerySettingsProps) {
  const {selectedCountry, selectedCity, selectedBakery} = props;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const {splash} = useSplashScreen();

  const user = useAuthStore((state) => state.user);

  const {mutate: updateBakery} = useMutation({
    mutationFn: services.bakery.updateBakery,
    onMutate: async ({request}) => {
      await queryClient.cancelQueries('companyCountries');
      const previousData = queryClient.getQueryData<Country[]>([
        'companyCountries',
        user?.companyId,
      ]);
      queryClient.setQueryData<Country[] | undefined>(
        ['companyCountries', user?.companyId],
        (countries) =>
          countries?.map((country) => ({
            ...country,
            districts: country.districts?.map((district) => ({
              ...district,
              cities: district.cities?.map((city) => ({
                ...city,
                bakeries: city.bakeries?.map((bakery) => ({
                  ...bakery,
                  name: bakery.id === selectedBakery.id ? request.name : bakery.name,
                })),
              })),
            })),
          })),
      );
      return {previousData};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(['companyCountries', user?.companyId], context?.previousData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('companyCountries');
    },
  });

  const {mutate: deleteBakery} = useMutation({
    mutationFn: services.bakery.deleteBakery,
    onMutate: async ({params}) => {
      await queryClient.cancelQueries('companyCountries');
      const previousData = queryClient.getQueryData<Country[]>([
        'companyCountries',
        user?.companyId,
      ]);
      queryClient.setQueryData<Country[] | undefined>(
        ['companyCountries', user?.companyId],
        (countries) =>
          countries?.map((country) => ({
            ...country,
            districts: country.districts?.map((district) => ({
              ...district,
              cities: district.cities?.map((city) => ({
                ...city,
                bakeries: city.bakeries?.filter((bakery) => bakery.id !== params.bakeryId),
              })),
            })),
          })),
      );
      return {previousData};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(['companyCountries', user?.companyId], context?.previousData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('companyCountries');
      navigate(`${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}`);
    },
  });

  const {mutate: syncBakery} = useMutation({
    mutationFn: services.bakery.syncBakery,
  });

  function handleUpdateName(name: string) {
    updateBakery({
      params: {bakeryId: selectedBakery.id},
      request: {...selectedBakery, name},
    });
  }

  function handleDeleteBakery() {
    splash({
      title: t('splash_screen_delete_bakery_title'),
      caption: t('splash_screen_delete_bakery_caption'),
      acceptAction: () => deleteBakery({params: {bakeryId: selectedBakery.id}}),
    });
  }

  function handleSyncBakery() {
    syncBakery({
      params: {
        bakeryId: selectedBakery.id,
      },
    });
  }

  return (
    <List sx={{margin: 0, padding: 0}}>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {t('bakery_settings_name_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
          <Typography variant="body2">{selectedBakery.name}</Typography>
        </Box>
        <UpdateNameContainer defaultName={selectedBakery.name} onUpdateName={handleUpdateName} />
      </ListItem>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {t('bakery_settings_country_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
          <Typography variant="body2">{selectedCountry.name}</Typography>
        </Box>
      </ListItem>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {t('bakery_settings_city_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
          <Typography variant="body2">{selectedCity.name}</Typography>
        </Box>
      </ListItem>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {t('bakery_settings_add_oven_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw'}} />
        <Box sx={{width: '15vw', textAlign: 'center', padding: 1}}>
          <IconButton
            IconComponent={AddIcon}
            onClick={() =>
              navigate(
                `${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}/settings/add-oven`,
              )
            }
          />
        </Box>
      </ListItem>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {t('bakery_settings_delete_bakery_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw'}} />
        <Box sx={{width: '15vw', textAlign: 'center', padding: 1}}>
          <IconButton IconComponent={DeleteIcon} onClick={handleDeleteBakery} />
        </Box>
      </ListItem>
      <ListItem
        sx={{
          alignItems: 'center',
          margin: 0,
          padding: 0,
          color: 'text.primary',
        }}>
        <Box sx={{width: '35vw', padding: 2}}>
          <Typography variant="body2" sx={{fontWeight: 'bold'}}>
            {t('bakery_settings_sync_ovens_label')}
          </Typography>
        </Box>
        <Box sx={{width: '30vw'}} />
        <Box sx={{width: '15vw', textAlign: 'center', padding: 1}}>
          <IconButton IconComponent={CircleArrowUpRightIcon} onClick={handleSyncBakery} />
        </Box>
      </ListItem>
    </List>
  );
}

export default BakerySettings;
