import {Box} from '@mui/material';
import {PropsWithChildren} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {FirebaseSubscriber} from '../../firebase/Subscriber';
import {getSubscriptionToken} from '../../firebase/app';
import useEffectOnce from '../../hooks/common/use-effect-once';
import {persistedLogin} from '../../state/auth';
import ErrorModal from '../common/ErrorModal';
import SplashScreen from '../common/SplashScreen';
import {AuthNavbar, DashboardNavbar, pageHeight} from '../navigation/Navbar';

function AppContainer(props: PropsWithChildren) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffectOnce(() => {
    getSubscriptionToken();
    persistedLogin(location.pathname, navigate);
  });

  return <FirebaseSubscriber>{props.children}</FirebaseSubscriber>;
}

function Main({auth = false}) {
  return (
    <AppContainer>
      {auth ? <AuthNavbar /> : <DashboardNavbar />}
      <Box component="main" style={{width: '100vw', height: pageHeight}}>
        <Outlet />
      </Box>
      <ErrorModal />
      <SplashScreen />
    </AppContainer>
  );
}

export default Main;
