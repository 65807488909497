import {Box, Paper, Typography} from '@mui/material';
import {addDays, addSeconds, format, parse, startOfDay} from 'date-fns';
import {TFunction} from 'i18next';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import OvenModel from '../../models/entities/oven-model';
import {CreateOrUpdateSchedulesRequest} from '../../models/requests/schedule';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import cryptoUtils from '../../utils/crypto';
import dateUtils, {weekDays} from '../../utils/dates';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import numberUtils from '../../utils/numbers';
import scheduleUtils from '../../utils/schedules';
import stringUtils from '../../utils/strings';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import CleaningList from './CleaningList';
import CreateCleaningScheduleMenu from './CleaningScheduleMenu';
import OvenList from './OvenList';
import SelectTime from './SelectTime';

function getStepTitle(step: CleaningScheduleStep, t: TFunction) {
  switch (step) {
    case 'ovens':
      return t('programming_ovens_step_title');
    case 'cleaning':
      return t('programming_cleaning_step_title');
    case 'time':
      return t('programming_time_step_title');
  }
}

function getEndTime(startTime: string, duration: number) {
  const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  if (!timeRegex.test(startTime)) {
    return '-';
  }
  const endTime = format(addSeconds(parse(startTime, 'HH:mm', new Date()), duration), 'HH:mm');
  return endTime;
}

function hasMultipleOvenPanels(ovenModel?: OvenModel) {
  const ovenGroups = ovenModel?.ovenGroups ?? [];
  const ovens = ovenModel?.ovens?.filter((oven) => oven.ovenGroup == null) ?? [];
  let ovenPanelCount = 0;
  for (const ovenGroup of ovenGroups) {
    if (ovenPanelCount > 1) return true;
    for (const oven of ovenGroup.ovens ?? []) {
      if (ovenPanelCount > 1) return true;
      ovenPanelCount += oven.ovenChambers?.length ?? 0;
      ovenPanelCount += oven.ovenPanels?.length ?? 0;
    }
  }
  for (const oven of ovens) {
    if (ovenPanelCount > 1) return true;
    ovenPanelCount += oven.ovenChambers?.length ?? 0;
    ovenPanelCount += oven.ovenPanels?.length ?? 0;
  }
  return ovenPanelCount > 1;
}

export type CleaningScheduleSteps = {
  ovens: {visible: boolean; visited: boolean; error: boolean};
  cleaning: {visible: boolean; visited: boolean; error: boolean};
  time: {visible: boolean; visited: boolean; error: boolean};
};

export type CleaningScheduleStep = keyof CleaningScheduleSteps;

export type OvenPanelData = {
  id: string;
  ovenChamberId?: string | null;
  ovenId?: string | null;
  ovenGroupId?: string | null;
};

function CleaningSchedule() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {countryId, cityId, bakeryId, ovenModelId, weekDay, scheduleId} = useParams();
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [steps, setSteps] = useState<CleaningScheduleSteps>({
    ovens: {visible: true, visited: true, error: true},
    cleaning: {visible: false, visited: false, error: true},
    time: {visible: false, visited: false, error: true},
  });
  const [selectedStep, setSelectedStep] = useState<CleaningScheduleStep>('ovens');

  const [selectedOvenPanels, setSelectedOvenPanels] = useState<OvenPanelData[]>([]);
  const selectedOvenPanelIds = selectedOvenPanels.map((ovenPanel) => ovenPanel.id);
  const selectedOvenPanelIdsSet = new Set(selectedOvenPanelIds);
  const [selectedCleaningId, setSelectedCleaningId] = useState<number | null>(null);
  const [selectedWeekDays, setSelectedWeekDays] = useState<Day[]>([
    dateUtils.parseWeekDay(weekDay, 1),
  ]);
  const selectedWeekDaysSet = new Set(selectedWeekDays);
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [shadowStartTime, setShadowStartTime] = useState('00:00');
  const [suggestedStartTime, setSuggestedStartTime] = useState('00:00');
  const [hasOverlap, setHasOverlap] = useState(false);

  const {data: country, isLoading: isLoadingCountry} = useQuery({
    enabled: user != null && countryId != null,
    queryKey: ['countries', countryId],
    queryFn: () =>
      services.country.getCountry({
        params: {
          countryId: numberUtils.parseInt(countryId),
        },
      }),
  });

  const {data: city, isLoading: isLoadingCity} = useQuery({
    enabled: user != null && cityId != null,
    queryKey: ['cities', cityId],
    queryFn: () =>
      services.city.getCity({
        params: {
          cityId: numberUtils.parseInt(cityId),
        },
      }),
  });

  const {data: bakery, isLoading: isLoadingBakery} = useQuery({
    enabled: user != null && bakeryId != null,
    queryKey: ['bakeries', bakeryId],
    queryFn: () =>
      services.bakery.getBakery({
        params: {
          bakeryId: bakeryId!,
        },
      }),
  });

  const {data: ovenModel, isLoading: isLoadingOvenModel} = useQuery({
    enabled: user != null && bakery != null,
    queryKey: ['bakeryOvenModels', bakery?.id, ovenModelId],
    queryFn: () =>
      services.bakery.getBakeryOvenModel({
        params: {
          bakeryId: bakery!.id,
          ovenModelId: numberUtils.parseInt(ovenModelId, 1),
        },
        query: {
          expand: ['ovenGroups', 'ovens', 'ovenChambers', 'ovenPanels'],
        },
      }),
  });

  const {isLoading: isLoadingSchedule} = useQuery({
    enabled: user != null && scheduleId != null,
    queryFn: () =>
      services.schedule.getSchedule({
        params: {
          scheduleId: scheduleId!,
        },
        query: {
          expand: ['recurrences', 'recipe', 'recipePhases', 'ovenPanels'],
        },
      }),
    onSuccess: (schedule) => {
      if (schedule.cleaningId == null) {
        return;
      }
      setSelectedOvenPanels(
        schedule.ovenPanels?.map((ovenPanel) => ({
          id: ovenPanel.id,
          ovenChamberId: ovenPanel.ovenChamberId,
          ovenId: ovenPanel.ovenChamber?.ovenId ?? ovenPanel.ovenId,
          ovenGroupId: ovenPanel.ovenChamber?.oven?.ovenGroupId ?? ovenPanel.oven?.ovenGroupId,
        })) ?? [],
      );
      setSelectedCleaningId(schedule.cleaningId);
      setSelectedWeekDays(schedule.recurrences?.map((recurrence) => recurrence.weekDay) ?? []);
      const startTime = scheduleUtils.getStartTime(schedule);
      const endTime = getEndTime(startTime, schedule.cleaning!.duration);
      setStartTime(startTime);
      setEndTime(endTime);
      setSuggestedStartTime(startTime);
      setSteps({
        ovens: {visible: true, visited: true, error: false},
        cleaning: {visible: true, visited: true, error: false},
        time: {visible: true, visited: true, error: false},
      });
    },
    onError: () => navigate(paths.programming),
  });

  const {data: cleanings = [], isLoading: isLoadingCleanings} = useQuery({
    enabled: user != null,
    queryKey: ['cleanings', {ovenModel: ovenModel?.id}],
    queryFn: () =>
      services.cleaning.getCleanings({
        query: {ovenModelId: ovenModel?.id},
      }),
  });

  const selectedCleaning = cleanings.find((cleaning) => cleaning.id === selectedCleaningId);

  const {mutate: createOrUpdateSchedules, isLoading: isLoadingCreateOrUpdateSchedules} =
    useMutation({
      mutationFn: services.schedule.createOrUpdateSchedules,
      onSuccess: () => {
        queryClient.invalidateQueries('schedules');
        const selectedWeekDay = dateUtils.parseWeekDay(weekDay, 1);
        navigate(
          `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${ovenModel?.id}/${selectedWeekDay}`,
        );
      },
      onError: (error) =>
        errorUtils.handleMatch(error, [ApplicationErrorCode.ScheduleOverlapping], () =>
          setHasOverlap(true),
        ),
    });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('programming_breadcrumb'),
      onClick: () => navigate(paths.programming),
    });
    if (country != null) {
      breadcrumbs.push({
        title: country.name,
        onClick: () => navigate(`${paths.programming}/${country.id}`),
      });

      if (city != null) {
        breadcrumbs.push({
          title: city.name,
          onClick: () => navigate(`${paths.programming}/${country.id}/${city.id}`),
        });

        if (bakery != null) {
          breadcrumbs.push({
            title: bakery.name,
            onClick: () => navigate(`${paths.programming}/${country.id}/${city.id}/${bakery.id}`),
          });

          if (ovenModel != null) {
            breadcrumbs.push({
              title: ovenModel.description,
              onClick: () =>
                navigate(
                  `${paths.programming}/${country.id}/${city.id}/${bakery.id}/${ovenModel.id}`,
                ),
            });

            const selectedWeekDay = dateUtils.parseWeekDay(weekDay, 1);

            breadcrumbs.push({
              title: t(weekDays[selectedWeekDay].key),
              onClick: () =>
                navigate(
                  `${paths.programming}/${country.id}/${city.id}/${bakery.id}/${ovenModel.id}/${selectedWeekDay}`,
                ),
            });

            breadcrumbs.push({title: t('cleaning')});
          }
        }
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bakery, ovenModelId, selectedCleaningId]);

  function handleSelectOvenPanel(ovenPanel: OvenPanelData) {
    if (selectedOvenPanelIdsSet.has(ovenPanel.id)) {
      selectedOvenPanelIdsSet.delete(ovenPanel.id);
      setSelectedOvenPanels((selectedOvenPanels) =>
        selectedOvenPanels.filter((selectedOvenPanel) => selectedOvenPanel.id !== ovenPanel.id),
      );
    } else {
      selectedOvenPanelIdsSet.add(ovenPanel.id);
      setSelectedOvenPanels((selectedOvenPanels) => [...selectedOvenPanels, ovenPanel]);
    }
    setSteps((steps) => ({
      ...steps,
      ovens: {...steps.ovens, error: selectedOvenPanelIdsSet.size === 0},
      cleaning: {
        ...steps.cleaning,
        visible: steps.cleaning.visible || selectedOvenPanelIdsSet.size > 0,
      },
    }));
    if (selectedOvenPanelIdsSet.size === 1 && !hasMultipleOvenPanels(ovenModel)) {
      setSelectedStep('cleaning');
    }
  }

  function handleSelectCleaning(cleaningId: number) {
    const selectedCleaning = cleanings.find((cleaning) => cleaning.id === cleaningId);
    if (selectedCleaning != null) {
      setSelectedCleaningId(cleaningId);
      setSteps((steps) => ({
        ...steps,
        cleaning: {...steps.cleaning, error: false},
        time: {...steps.time, visible: true, error: false},
      }));
      const endTime = getEndTime(startTime, selectedCleaning.duration);
      setEndTime(endTime);
    }
  }

  function handleSelectWeekDay(weekDay: Day) {
    if (selectedWeekDaysSet.has(weekDay)) {
      selectedWeekDaysSet.delete(weekDay);
    } else {
      selectedWeekDaysSet.add(weekDay);
    }
    setSelectedWeekDays(Array.from(selectedWeekDaysSet));
  }

  function handleSelectAllWeekDays() {
    setSelectedWeekDays([0, 1, 2, 3, 4, 5, 6]);
  }

  function validateStartTime(startTime: string) {
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (selectedCleaning != null && timeRegex.test(startTime)) {
      const endTime = getEndTime(startTime, selectedCleaning.duration);
      if (endTime > startTime) {
        setEndTime(endTime);
        setSteps((steps) => ({
          ...steps,
          time: {...steps.time, error: false},
        }));
      }
    }
  }

  function handleSetStartTime(time: string) {
    setStartTime(time);
    setShadowStartTime('');
    setSteps((steps) => ({...steps, time: {...steps.time, error: true}}));
    validateStartTime(time);
  }

  function handleStartTimeFocus() {
    setShadowStartTime(startTime);
    setStartTime('');
    setSteps((steps) => ({...steps, time: {...steps.time, error: true}}));
  }

  function handleStartTimeBlur() {
    if (stringUtils.isNullOrWhiteSpace(startTime)) {
      if (!stringUtils.isNullOrWhiteSpace(shadowStartTime)) {
        setStartTime(shadowStartTime);
        validateStartTime(shadowStartTime);
        return;
      }
      setStartTime(suggestedStartTime);
      validateStartTime(suggestedStartTime);
    }
  }

  function handleCreateOrUpdateSchedules() {
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (
      user == null ||
      selectedCleaning == null ||
      arrayUtils.isNullOrEmpty(selectedOvenPanelIds) ||
      arrayUtils.isNullOrEmpty(selectedWeekDays) ||
      stringUtils.isNullOrWhiteSpace(startTime) ||
      !timeRegex.test(startTime)
    ) {
      return;
    }
    const schedules: CreateOrUpdateSchedulesRequest = [];
    // oven groups can only have a single oven cleaning at a time,
    // if selected multiple ovens from the same group, we need to increase the time slot
    // the time increase is working only for oven groups with 2 ovens, which is the only scenario we have at this moment
    const ovenPanelIds: string[] = [];
    const ovenPanelIdsForTimeIncrease: string[] = [];
    const ovenGroupIdsSet = new Set<string>();
    selectedOvenPanels.forEach((ovenPanel) => {
      let needsTimeIncrease = false;

      if (ovenPanel.ovenGroupId != null) {
        if (ovenGroupIdsSet.has(ovenPanel.ovenGroupId)) {
          needsTimeIncrease = true;
        }
        ovenGroupIdsSet.add(ovenPanel.ovenGroupId);
      }

      if (needsTimeIncrease) {
        ovenPanelIdsForTimeIncrease.push(ovenPanel.id);
      } else {
        ovenPanelIds.push(ovenPanel.id);
      }
    });
    schedules.push({
      id: scheduleId ?? cryptoUtils.uuid(),
      cleaningId: selectedCleaning.id,
      companyId: user.companyId,
      recurrences: selectedWeekDays.map((weekDay) => {
        let startDate = dateUtils.setTime(new Date(), startTime);
        startDate = dateUtils.nextDateOfDay(startDate, weekDay);

        if (startDate < new Date()) {
          const nextDay = startOfDay(addDays(startDate, 1));
          startDate = startOfDay(dateUtils.nextDateOfDay(nextDay, weekDay));
        }

        return {
          id: cryptoUtils.uuid(),
          startDate: format(startDate, 'yyyy-MM-dd'),
          startTime: startTime + ':00',
          weekDay,
          repeat: true,
        };
      }),
      ovenPanels: ovenPanelIds.map((ovenPanelId) => ({
        id: ovenPanelId,
      })),
    });
    if (!arrayUtils.isNullOrEmpty(ovenPanelIdsForTimeIncrease)) {
      const _startTime = format(
        addSeconds(parse(startTime, 'HH:mm', new Date()), selectedCleaning.duration + 60),
        'HH:mm',
      );

      schedules.push({
        id: scheduleId ?? cryptoUtils.uuid(),
        cleaningId: selectedCleaning.id,
        companyId: user.companyId,
        recurrences: selectedWeekDays.map((weekDay) => {
          let startDate = dateUtils.setTime(new Date(), _startTime);
          startDate = dateUtils.nextDateOfDay(startDate, weekDay);

          if (startDate < new Date()) {
            const nextDay = startOfDay(addDays(startDate, 1));
            startDate = startOfDay(dateUtils.nextDateOfDay(nextDay, weekDay));
          }

          return {
            id: cryptoUtils.uuid(),
            startDate: format(startDate, 'yyyy-MM-dd'),
            startTime: _startTime + ':00',
            weekDay,
            repeat: true,
          };
        }),
        ovenPanels: ovenPanelIdsForTimeIncrease.map((ovenPanelId) => ({
          id: ovenPanelId,
        })),
      });
    }
    createOrUpdateSchedules({request: schedules});
  }

  const isLoading =
    isLoadingCountry ||
    isLoadingCity ||
    isLoadingBakery ||
    isLoadingOvenModel ||
    isLoadingSchedule ||
    isLoadingCreateOrUpdateSchedules;
  const renderOverlappingError = selectedStep === 'time' && hasOverlap;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
          marginBlock: '112px',
        }}>
        <CreateCleaningScheduleMenu
          steps={steps}
          setSteps={setSteps}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          onFinish={handleCreateOrUpdateSchedules}
        />
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{display: 'flex'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '65vw',
              height: '112px',
            }}>
            <Paper
              elevation={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBlock: 2,
                paddingBlock: 1,
                paddingInline: 2,
                borderRadius: '10px',
                backgroundColor: '#F4F4F4',
              }}>
              <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                {t('programming_cleaning_programming_title')}
              </Typography>
            </Paper>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color: 'text.primary',
                paddingInline: 2,
              }}>
              {getStepTitle(selectedStep, t)}
            </Typography>
            <Box sx={{height: '23px'}}>
              {renderOverlappingError && (
                <Typography
                  variant="caption"
                  sx={{
                    paddingTop: '3px',
                    paddingInline: 2,
                    fontWeight: 'bold',
                    color: 'primary.main',
                  }}>
                  {t('programming_overlapping_times_error')}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{width: '15vw', textAlign: 'center', padding: 2.2}}>
            <IconButton
              IconComponent={DeclineIcon}
              activeColor="secondary"
              onClick={() =>
                navigate(
                  `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${ovenModel?.id}/${weekDay}`,
                )
              }
            />
          </Box>
        </Box>
        <Box>
          {selectedStep === 'ovens' && (
            <OvenList
              ovenGroups={ovenModel?.ovenGroups ?? []}
              ovens={ovenModel?.ovens?.filter((oven) => oven.ovenGroupId == null) ?? []}
              selectedOvenPanelIds={selectedOvenPanelIds}
              onSelectOvenPanel={handleSelectOvenPanel}
              isCleaning
              ovensNotFoundMessage={
                isLoadingOvenModel ? '' : t('programming_ovens_not_found_label')
              }
            />
          )}
          {selectedStep === 'cleaning' && (
            <CleaningList
              cleanings={cleanings}
              selectedCleaningId={selectedCleaningId}
              onSelectCleaning={handleSelectCleaning}
              cleaningsNotFoundMessage={
                isLoadingCleanings ? '' : t('programming_cleanings_not_found_label')
              }
            />
          )}
          {selectedStep === 'time' && (
            <SelectTime
              startTime={startTime}
              endTime={endTime}
              setStartTime={handleSetStartTime}
              selectedWeekDays={selectedWeekDays}
              onSelectWeekDay={handleSelectWeekDay}
              onSelectAllWeekDays={handleSelectAllWeekDays}
              error={steps.time.error}
              onStartTimeFocus={handleStartTimeFocus}
              onStartTimeBlur={handleStartTimeBlur}
            />
          )}
        </Box>
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default CleaningSchedule;
