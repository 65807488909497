import {Box, InputBase, Typography} from '@mui/material';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as AcceptSimpleIcon} from '../../../../../assets/icons/accept-simple.svg';
import {ReactComponent as CircleArrowDownIcon} from '../../../../../assets/icons/circle-arrow-down.svg';
import {ReactComponent as CircleArrowUpIcon} from '../../../../../assets/icons/circle-arrow-up.svg';
import {ReactComponent as DeclineSimpleIcon} from '../../../../../assets/icons/decline-simple.svg';
import ModulramImage from '../../../../../assets/images/modulram-front.png';
import useAuthStore from '../../../../../state/auth';
import stringUtils from '../../../../../utils/strings';
import Icon from '../../../../common/Icon';
import IconButton from '../../../../common/IconButton';
import Image from '../../../../common/Image';
import QuantitySpinner from '../../../../common/QuantitySpinner';
import {SerialNumberStepProps} from './SerialNumberStep';

function ModulramSerialNumberStep(props: SerialNumberStepProps) {
  const {
    ovens,
    ovenChambers,
    steps,
    setSteps,
    selectedStep,
    setSelectedStep,
    addOvenData,
    setAddOvenData,
  } = props;
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const [pairOvenChamberIndex, setPairOvenChamberIndex] = useState(0);

  const targetOvenChambers = useMemo(() => {
    return addOvenData.ovenChamberSerialNumbers.map((serialNumber, index) =>
      ovenChambers.find(
        (ovenChamber) =>
          (addOvenData.selectedOvenId != null &&
            index !== pairOvenChamberIndex &&
            ovenChamber.serialNumber === serialNumber) ||
          (ovenChamber.serialNumber === serialNumber && ovenChamber.bakeryId === user?.companyId),
      ),
    );
  }, [
    ovenChambers,
    addOvenData.ovenChamberSerialNumbers,
    addOvenData.selectedOvenId,
    pairOvenChamberIndex,
    user,
  ]);

  const oven = useMemo(
    () => ovens.find((oven) => oven.id === addOvenData.selectedOvenId),
    [ovens, addOvenData.selectedOvenId],
  );

  useEffect(() => {
    if (oven != null) {
      setAddOvenData((addOvenData) => ({
        ...addOvenData,
        ovenChamberSerialNumbers: [
          '',
          ...[...(oven.ovenChambers ?? [])]
            .reverse()
            .map((ovenChamber) => ovenChamber.serialNumber),
        ],
        description: oven.description ?? '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oven]);

  useEffect(() => {
    let isValid = false;
    if (addOvenData.selectedOvenId) {
      const pairOvenChamber = ovenChambers.find(
        (ovenChamber) =>
          ovenChamber.serialNumber === addOvenData.ovenChamberSerialNumbers[pairOvenChamberIndex] &&
          ovenChamber.bakeryId === user?.companyId,
      );
      isValid = pairOvenChamber != null;
    } else {
      isValid =
        addOvenData.ovenChamberSerialNumbers.length > 0 &&
        addOvenData.ovenChamberSerialNumbers.every((serialNumber) =>
          ovenChambers.some(
            (ovenChamber) =>
              ovenChamber.serialNumber === serialNumber && ovenChamber.bakeryId === user?.companyId,
          ),
        );
    }
    setSteps((steps) => ({
      ...steps,
      serialNumber: {...steps.serialNumber, error: !isValid},
      description: {
        ...steps.description,
        error: stringUtils.isNullOrWhiteSpace(addOvenData.description),
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ovenChambers,
    addOvenData.ovenChamberSerialNumbers,
    addOvenData.selectedOvenId,
    pairOvenChamberIndex,
    user,
  ]);

  function handleChangePairOvenChamberIndex(offset: number) {
    setPairOvenChamberIndex(pairOvenChamberIndex + offset);
    setAddOvenData((addOvenData) => {
      const ovenChamberSerialNumbers = [...addOvenData.ovenChamberSerialNumbers];
      const replaceSerialNumber = ovenChamberSerialNumbers[pairOvenChamberIndex + offset];
      ovenChamberSerialNumbers[pairOvenChamberIndex + offset] =
        ovenChamberSerialNumbers[pairOvenChamberIndex];
      ovenChamberSerialNumbers[pairOvenChamberIndex] = replaceSerialNumber;
      return {...addOvenData, ovenChamberSerialNumbers};
    });
  }

  function handleAddOvenChamber() {
    setAddOvenData((addOvenData) => ({
      ...addOvenData,
      ovenChamberSerialNumbers: ['', ...addOvenData.ovenChamberSerialNumbers],
    }));
    setSteps((steps) => ({
      ...steps,
      chambersNumber: {visible: true, error: false},
      serialNumber: {...steps.serialNumber, error: true},
    }));
  }

  function handleRemoveOvenChamber() {
    setAddOvenData((addOvenData) => ({
      ...addOvenData,
      ovenChamberSerialNumbers: addOvenData.ovenChamberSerialNumbers.slice(1),
    }));
    setSteps((steps) => ({
      ...steps,
      chambersNumber: {visible: true, error: addOvenData.ovenChamberSerialNumbers.length - 1 === 0},
      serialNumber: {
        ...steps.serialNumber,
        error: addOvenData.ovenChamberSerialNumbers.length - 1 === 0,
      },
    }));
  }

  function handleChangeSerialNumber(valueIndex: number, value: string) {
    setAddOvenData((addOvenData) => ({
      ...addOvenData,
      ovenChamberSerialNumbers: addOvenData.ovenChamberSerialNumbers.map((serialNumber, index) =>
        index === valueIndex ? value : serialNumber,
      ),
    }));
    setSteps((steps) => ({
      ...steps,
      serialNumber: {visible: true, error: true},
      description: {
        ...steps.description,
        error: stringUtils.isNullOrWhiteSpace(addOvenData.description),
      },
    }));
  }

  function handleChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    setAddOvenData((addOvenData) => ({...addOvenData, description: event.target.value}));
    setSteps((steps) => ({
      ...steps,
      description: {visible: true, error: stringUtils.isNullOrWhiteSpace(event.target.value)},
    }));
  }

  if (!steps.serialNumber.visible) {
    return (
      <Box
        sx={{display: 'flex', alignItems: 'center', gap: 2, padding: 2}}
        onClick={() => setSelectedStep('chambersNumber')}>
        <Typography sx={{color: 'text.primary'}} variant="body2">
          {t('add_oven_oven_chambers_number_label')}
        </Typography>
        <QuantitySpinner
          value={addOvenData.ovenChamberSerialNumbers.length}
          minValue={0}
          maxValue={5}
          onIncrease={handleAddOvenChamber}
          onDecrease={handleRemoveOvenChamber}
        />
      </Box>
    );
  }

  if (addOvenData.selectedOvenId != null) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'space-between', padding: 2}}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
          {oven != null && (
            <Box sx={{opacity: 0.5}}>
              <Typography sx={{fontWeight: 'bold', color: 'text.primary'}} variant="body2">
                {oven.description ?? oven.serialNumber}
              </Typography>
              <Typography sx={{color: 'text.primary'}} variant="body2">{`${t(
                'add_oven_serial_number_label',
              )}: ${oven.serialNumber}`}</Typography>
            </Box>
          )}
          <Box
            sx={{opacity: selectedStep === 'serialNumber' ? 1 : 0.5}}
            onClick={() => setSelectedStep('serialNumber')}>
            <Typography sx={{color: 'text.primary'}} variant="body2">
              {t('add_oven_oven_chamber_serial_number_label')}
            </Typography>
            <InputBase
              sx={{
                width: '25vw',
                marginTop: 2,
                fontSize: '0.875rem',
                color: 'text.primary',
                borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
              }}
              value={addOvenData.ovenChamberSerialNumbers[pairOvenChamberIndex] ?? ''}
              onChange={(event) =>
                handleChangeSerialNumber(pairOvenChamberIndex, event.target.value)
              }
              endAdornment={
                stringUtils.isNullOrWhiteSpace(
                  addOvenData.ovenChamberSerialNumbers[pairOvenChamberIndex],
                ) ? undefined : (
                  <Icon
                    IconComponent={
                      targetOvenChambers[pairOvenChamberIndex] == null
                        ? DeclineSimpleIcon
                        : AcceptSimpleIcon
                    }
                    color="custom.grey"
                    size="16px"
                  />
                )
              }
            />
            <Box sx={{minHeight: '22px', textAlign: 'end'}}>
              {!stringUtils.isNullOrWhiteSpace(
                addOvenData.ovenChamberSerialNumbers[pairOvenChamberIndex],
              ) &&
                targetOvenChambers[pairOvenChamberIndex] == null && (
                  <Typography sx={{color: 'primary.main'}} variant="caption">
                    {t('add_oven_invalid_code_label')}
                  </Typography>
                )}
            </Box>
          </Box>
          <Box
            sx={{
              display: steps.description.visible ? 'block' : 'none',
              opacity: selectedStep === 'description' ? 1 : 0.5,
            }}
            onClick={() => setSelectedStep('description')}>
            <Typography sx={{color: 'text.primary'}} variant="body2">
              {t('add_oven_oven_description_label')}
            </Typography>
            <InputBase
              sx={{
                width: '25vw',
                marginTop: 2,
                fontSize: '0.875rem',
                color: 'text.primary',
                borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
              }}
              value={addOvenData.description}
              onChange={handleChangeDescription}
            />
          </Box>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
          {targetOvenChambers.map((ovenChamber, index) => (
            <Box key={index} sx={{position: 'relative'}}>
              <Image
                key={index}
                sx={{
                  width: '200px',
                  marginBottom: ovenChamber == null ? 4 : 0,
                  opacity:
                    ovenChamber == null ||
                    (targetOvenChambers[pairOvenChamberIndex] != null &&
                      index !== pairOvenChamberIndex)
                      ? 0.5
                      : 1,
                }}
                src={ModulramImage}
                alt={`Modulram Chamber ${index}`}
              />
              {targetOvenChambers[pairOvenChamberIndex] != null &&
                index === 0 &&
                pairOvenChamberIndex < targetOvenChambers.length - 1 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 'calc(-15vw - 16px)',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '15vw',
                    }}>
                    <IconButton
                      IconComponent={CircleArrowDownIcon}
                      onClick={() => handleChangePairOvenChamberIndex(1)}
                    />
                  </Box>
                )}
              {targetOvenChambers[pairOvenChamberIndex] != null &&
                index === targetOvenChambers.length - 1 &&
                pairOvenChamberIndex > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 'calc(-15vw - 16px)',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '15vw',
                    }}>
                    <IconButton
                      IconComponent={CircleArrowUpIcon}
                      onClick={() => handleChangePairOvenChamberIndex(-1)}
                    />
                  </Box>
                )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: 2}}>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            marginBottom: 2,
            opacity: selectedStep === 'chambersNumber' ? 1 : 0.5,
          }}
          onClick={() => setSelectedStep('chambersNumber')}>
          <Typography sx={{color: 'text.primary'}} variant="body2">
            {t('add_oven_oven_chambers_number_label')}
          </Typography>
          <QuantitySpinner
            value={addOvenData.ovenChamberSerialNumbers.length}
            minValue={0}
            maxValue={5}
            onIncrease={handleAddOvenChamber}
            onDecrease={handleRemoveOvenChamber}
          />
        </Box>
        {selectedStep === 'description' && (
          <Typography sx={{color: 'text.primary', marginBottom: 2, opacity: 0.5}} variant="body2">
            {t('add_oven_oven_chamber_serial_numbers_label')}
          </Typography>
        )}
        {addOvenData.ovenChamberSerialNumbers.map((serialNumber, index) => (
          <Box
            key={index}
            sx={{opacity: selectedStep === 'serialNumber' ? 1 : 0.5}}
            onClick={() => setSelectedStep('serialNumber')}>
            {selectedStep === 'serialNumber' && (
              <Typography sx={{color: 'text.primary'}} variant="body2">
                {`${t('add_oven_oven_chamber_serial_number_label')} ${
                  addOvenData.ovenChamberSerialNumbers.length > 1
                    ? addOvenData.ovenChamberSerialNumbers.length - index
                    : ''
                } ${
                  addOvenData.ovenChamberSerialNumbers.length > 1
                    ? index === 0
                      ? `(${t('add_oven_top_label')})`
                      : index === addOvenData.ovenChamberSerialNumbers.length - 1
                      ? `(${t('add_oven_bottom_label')})`
                      : ''
                    : ''
                }`}
              </Typography>
            )}
            <InputBase
              sx={{
                width: '25vw',
                marginTop: selectedStep === 'serialNumber' ? 2 : 0,
                fontSize: '0.875rem',
                color: 'text.primary',
                borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
              }}
              value={serialNumber}
              onChange={(event) => handleChangeSerialNumber(index, event.target.value)}
              endAdornment={
                stringUtils.isNullOrWhiteSpace(serialNumber) ? undefined : (
                  <Icon
                    IconComponent={
                      targetOvenChambers[index] == null ? DeclineSimpleIcon : AcceptSimpleIcon
                    }
                    color="custom.grey"
                    size="16px"
                  />
                )
              }
            />
            {selectedStep === 'serialNumber' && (
              <Box sx={{minHeight: '22px', textAlign: 'end'}}>
                {!stringUtils.isNullOrWhiteSpace(serialNumber) &&
                  targetOvenChambers[index] == null && (
                    <Typography sx={{color: 'primary.main'}} variant="caption">
                      {t('add_oven_invalid_code_label')}
                    </Typography>
                  )}
              </Box>
            )}
          </Box>
        ))}
        <Box
          sx={{
            marginTop: selectedStep === 'description' ? 4 : 0,
            display: steps.description.visible ? 'block' : 'none',
            opacity: selectedStep === 'description' ? 1 : 0.5,
          }}
          onClick={() => setSelectedStep('description')}>
          <Typography sx={{color: 'text.primary'}} variant="body2">
            {t('add_oven_oven_description_label')}
          </Typography>
          <InputBase
            sx={{
              width: '25vw',
              marginTop: 2,
              fontSize: '0.875rem',
              color: 'text.primary',
              borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
            }}
            value={addOvenData.description}
            onChange={handleChangeDescription}
          />
        </Box>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
        {targetOvenChambers.map((ovenChamber, index) => (
          <Image
            key={index}
            sx={{width: '200px', opacity: ovenChamber == null ? 0.5 : 1}}
            src={ModulramImage}
            alt={`Modulram Chamber ${index}`}
          />
        ))}
      </Box>
    </Box>
  );
}

export default ModulramSerialNumberStep;
