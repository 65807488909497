import {Box, List, ListItem, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import Notification from '../../models/entities/notification';
import dateUtils from '../../utils/dates';
import notificationUtils from '../../utils/notifications';
import stringUtils from '../../utils/strings';
import {Language} from '../../utils/types';
import GradientOverflow from '../common/GradientOverflow';
import IconButton from '../common/IconButton';
import Span from '../common/Span';
import Switch from '../common/Switch';

type NotificationListItemProps = {
  notification: Notification;
  onSelect: () => void;
  onCheck: (checked: boolean) => void;
  onDelete: () => void;
  isSelectingMultiple: boolean;
  selected: boolean;
};

function NotificationListItem(props: NotificationListItemProps) {
  const {notification, onSelect, onCheck, onDelete, isSelectingMultiple, selected} = props;
  const {t, i18n} = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  function getColor() {
    if (isHovered) return 'primary.main';
    return notification.isSeen ? 'text.secondary' : 'text.primary';
  }

  return (
    <ListItem sx={{alignItems: 'center', color: getColor(), margin: 0, padding: 0}}>
      <Box
        sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onSelect}>
        <Box sx={{width: '50vw', padding: 2}}>
          <Typography variant="body2">
            <Span sx={{fontWeight: 700}}>{`${notification.ovenDescription ?? ''} | `}</Span>
            <Span>{`${notification.bakeryName ?? ''} | `}</Span>
            <Span sx={{fontWeight: 100}}>
              {stringUtils.format(
                t(notificationUtils.getLabel(notification)),
                notification.ovenPanelOrder ?? 1,
              )}
            </Span>
          </Typography>
        </Box>
        <Box sx={{width: '15vw', textAlign: 'right', padding: 2}}>
          <Typography variant="body2">
            {dateUtils.toLocaleDateString(
              new Date(notification.createdAt),
              i18n.language as Language,
            )}
          </Typography>
        </Box>
      </Box>
      <Box sx={{width: '15vw', padding: 1}}>
        {isSelectingMultiple ? (
          <Switch checked={selected} onChange={(_, checked) => onCheck?.(checked)} />
        ) : (
          <IconButton IconComponent={DeclineIcon} onClick={onDelete} />
        )}
      </Box>
    </ListItem>
  );
}

type NotificationListProps = {
  notifications: Notification[];
  onSelectNotification: (notificationId: string) => void;
  onCheckNotification: (notificationId: string, checked: boolean) => void;
  onDeleteNotification: (notificationId: string) => void;
  isSelectingMultiple: boolean;
  selectedNotificationIds: string[];
  notificationsNotFoundLabel: string;
};

function NotificationList(props: NotificationListProps) {
  const {
    notifications,
    onSelectNotification,
    onDeleteNotification,
    onCheckNotification,
    isSelectingMultiple,
    selectedNotificationIds,
    notificationsNotFoundLabel,
  } = props;

  return (
    <GradientOverflow hideScrollbar>
      <List sx={{margin: 0, padding: 0}}>
        {notifications.length === 0 && (
          <ListItem sx={{margin: 0, padding: 0}}>
            <Typography variant="body2" sx={{color: 'text.primary', padding: 2}}>
              {notificationsNotFoundLabel}
            </Typography>
          </ListItem>
        )}
        {notifications.map((notification) => (
          <NotificationListItem
            key={notification.id}
            notification={notification}
            onSelect={() => onSelectNotification(notification.id)}
            onCheck={(checked) => onCheckNotification(notification.id, checked)}
            onDelete={() => onDeleteNotification(notification.id)}
            isSelectingMultiple={isSelectingMultiple}
            selected={selectedNotificationIds.includes(notification.id)}
          />
        ))}
      </List>
    </GradientOverflow>
  );
}

export default NotificationList;
