import Oven from './oven';
import OvenGroup from './oven-group';

export enum OvenModelId {
  Compactram = 1,
  Electram = 2,
  Modulram = 3,
  Rotoram = 4,
  Turboram = 5,
}

type OvenModel = {
  id: number;
  description: string;
  ovens?: Oven[];
  ovenGroups?: OvenGroup[];
};

export default OvenModel;
