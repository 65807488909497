import {create} from 'zustand';

type ErrorStore = {
  errors: string[];
  setErrors: (errors: string[]) => void;
  clearErrors: () => void;
};

const useErrorStore = create<ErrorStore>((set) => ({
  errors: [],
  setErrors: (errors: string[]) => set({errors}),
  clearErrors: () => set({errors: []}),
}));

export function clearErrorStore() {
  useErrorStore.setState({errors: []});
}

export default useErrorStore;
