import {Box, Typography} from '@mui/material';
import {Fragment, useMemo, useState} from 'react';
import {ReactComponent as Compactram} from '../../assets/images/compactram.svg';
import {ReactComponent as Electram} from '../../assets/images/electram.svg';
import {ReactComponent as Modulram} from '../../assets/images/modulram.svg';
import {ReactComponent as Rotoram} from '../../assets/images/rotoram.svg';
import {ReactComponent as Turboram} from '../../assets/images/turboram.svg';
import OvenModel, {OvenModelId} from '../../models/entities/oven-model';

function getOven(ovenModelId: OvenModelId) {
  switch (ovenModelId) {
    case OvenModelId.Compactram:
      return <Compactram />;
    case OvenModelId.Electram:
      return <Electram />;
    case OvenModelId.Modulram:
      return <Modulram />;
    case OvenModelId.Rotoram:
      return <Rotoram />;
    case OvenModelId.Turboram:
      return <Turboram />;
  }
}

type OvenModelListItemProps = {
  ovenModel: OvenModel;
  selected?: boolean;
  onClick?: () => void;
};

function OvenModeListItem(props: OvenModelListItemProps) {
  const {ovenModel, selected, onClick} = props;

  const [isHovered, setIsHovered] = useState(false);

  const isSelectedOrHovered = selected || isHovered;

  return (
    <Box
      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Box
        sx={{
          position: 'relative',
          width: '75px',
          height: '75px',
          cursor: 'pointer',
          '&:after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            content: selected ? undefined : '" "',
            background: 'rgba(255, 255, 255, 0.5)',
            pointerEvents: 'none',
          },
        }}
        onClick={onClick}>
        {getOven(ovenModel.id)}
      </Box>
      <Box sx={{height: '20px'}}>
        {isSelectedOrHovered && (
          <Typography
            variant="body2"
            sx={{
              color: 'text.primary',
              marginTop: '5px',
              opacity: selected ? 1 : 0.5,
            }}>
            {ovenModel.description.toLowerCase()}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export type OvenModelListProps = {
  ovenModels: OvenModel[];
  selectedOvenModelId?: OvenModelId;
  onSelectOvenModel?: (ovenModelId: OvenModelId) => void;
};

function OvenModelList(props: OvenModelListProps) {
  const {ovenModels, selectedOvenModelId, onSelectOvenModel} = props;

  const sortedOvenModels = useMemo(
    () => Array.from(ovenModels).sort((ovenModelA, ovenModelB) => ovenModelA.id - ovenModelB.id),
    [ovenModels],
  );

  return (
    <Box>
      {sortedOvenModels.map((ovenModel, index) => {
        const renderSeparator = index < sortedOvenModels.length - 1;
        return (
          <Fragment key={ovenModel.id}>
            <OvenModeListItem
              ovenModel={ovenModel}
              selected={ovenModel.id === selectedOvenModelId}
              onClick={() => onSelectOvenModel?.(ovenModel.id)}
            />
            {renderSeparator && <Box height="24px" />}
          </Fragment>
        );
      })}
    </Box>
  );
}

export default OvenModelList;
