import {Box, List, ListItem, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Recipe from '../../models/entities/recipe';
import recipeUtils from '../../utils/recipes';
import GradientOverflow from '../common/GradientOverflow';
import {pageHeight} from '../navigation/Navbar';

type RecipeListItemProps = {
  recipe: Recipe;
  selected: boolean;
  onSelectRecipe: () => void;
};

function RecipeListItem(props: RecipeListItemProps) {
  const {recipe, selected, onSelectRecipe} = props;
  const {t} = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  const selectedOrHovered = selected || isHovered;

  return (
    <ListItem
      sx={{
        margin: 0,
        padding: 2,
        cursor: 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onSelectRecipe}>
      <Box sx={{width: '40vw'}}>
        <Typography
          variant="body2"
          sx={{
            fontWeight: selectedOrHovered ? 'bold' : 'normal',
            color: selectedOrHovered ? 'primary.main' : 'text.primary',
          }}>
          {recipe.name}
        </Typography>
      </Box>
      <Box sx={{width: '25vw', textAlign: 'right'}}>
        <Typography
          variant="body2"
          sx={{
            color: selectedOrHovered ? 'primary.main' : 'text.primary',
          }}>{`${t('programming_duration_label')}: ${recipeUtils.getDuration(recipe) / 60} ${t(
          'programming_minutes_label',
        )}`}</Typography>
      </Box>
    </ListItem>
  );
}

type RecipeListProps = {
  recipes: Recipe[];
  selectedRecipeId: string | null;
  onSelectRecipe: (recipeId: string) => void;
  recipesNotFoundMessage?: string;
};

function RecipeList(props: RecipeListProps) {
  const {recipes, selectedRecipeId, onSelectRecipe, recipesNotFoundMessage} = props;

  return (
    <Box
      sx={{
        width: '65vw',
        height: `calc(${pageHeight} - 112px)`,
        paddingBottom: 6,
      }}>
      <GradientOverflow hideScrollbar>
        <List sx={{margin: 0, padding: 0}}>
          {recipes.map((recipe) => (
            <RecipeListItem
              key={recipe.id}
              recipe={recipe}
              selected={recipe.id === selectedRecipeId}
              onSelectRecipe={() => onSelectRecipe(recipe.id)}
            />
          ))}
          {recipes.length === 0 && (
            <ListItem sx={{margin: 0, padding: 2}}>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                {recipesNotFoundMessage}
              </Typography>
            </ListItem>
          )}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default RecipeList;
