import {
  LoginRequest,
  RecoverPasswordRequest,
  ResetPasswordRequest,
  SignupRequest,
} from '../models/requests/auth';
import {
  LoginResponse,
  RecoverPasswordResponse,
  ResetPasswordResponse,
  SignupResponse,
} from '../models/responses/auth';
import baseService from './base';
import endpoints from './endpoints';

export type AuthService = {
  signup: (args: {request: SignupRequest}) => Promise<SignupResponse>;
  login: (args: {request: LoginRequest}) => Promise<LoginResponse>;
  recoverPassword: (args: {request: RecoverPasswordRequest}) => Promise<RecoverPasswordResponse>;
  resetPassword: (args: {request: ResetPasswordRequest}) => Promise<ResetPasswordResponse>;
};

const authService: AuthService = {
  signup: async function (args: {request: SignupRequest}): Promise<SignupResponse> {
    const {request} = args;

    const result = await baseService.post<SignupResponse, SignupRequest>(endpoints.signup, request);

    return result;
  },
  login: async function (args: {request: LoginRequest}): Promise<LoginResponse> {
    const {request} = args;

    const result = await baseService.post<LoginResponse, LoginRequest>(endpoints.login, request);

    return result;
  },
  recoverPassword: async function (args: {
    request: RecoverPasswordRequest;
  }): Promise<RecoverPasswordResponse> {
    const {request} = args;

    const result = await baseService.post<RecoverPasswordResponse, RecoverPasswordRequest>(
      endpoints.recoverPassword,
      request,
    );

    return result;
  },
  resetPassword: async function (args: {
    request: ResetPasswordRequest;
  }): Promise<ResetPasswordResponse> {
    const {request} = args;

    const result = await baseService.post<ResetPasswordResponse, ResetPasswordRequest>(
      endpoints.resetPassword,
      request,
    );

    return result;
  },
};

export default authService;
