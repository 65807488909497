import {GetDistrictsQuery} from '../models/requests/district';
import {GetDistrictsResponse} from '../models/responses/district';
import baseService from './base';
import endpoints from './endpoints';

export type DistrictService = {
  getDistricts: (args: {query?: GetDistrictsQuery}) => Promise<GetDistrictsResponse>;
};

const districtService: DistrictService = {
  getDistricts: async function (args: {query?: GetDistrictsQuery}): Promise<GetDistrictsResponse> {
    const {query} = args;

    const result = await baseService.get<GetDistrictsResponse, GetDistrictsQuery>(
      endpoints.districts,
      query,
    );

    return result;
  },
};

export default districtService;
