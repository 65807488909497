import {
  GetCompanyCountriesParams,
  GetCompanyCountriesQuery,
  GetCompanyOvenModelsParams,
  GetCompanyOvenModelsQuery,
} from '../models/requests/company';
import {
  GetCompanyCountriesResponse,
  GetCompanyOvenModelsResponse,
} from '../models/responses/company';
import baseService from './base';
import endpoints from './endpoints';

export type CompanyService = {
  getCompanyCountries: (args: {
    params: GetCompanyCountriesParams;
    query?: GetCompanyCountriesQuery;
  }) => Promise<GetCompanyCountriesResponse>;
  getCompanyOvenModels: (args: {
    params: GetCompanyOvenModelsParams;
    query?: GetCompanyOvenModelsQuery;
  }) => Promise<GetCompanyOvenModelsResponse>;
};

const companyService: CompanyService = {
  getCompanyCountries: function (args: {
    params: GetCompanyCountriesParams;
    query?: GetCompanyCountriesQuery;
  }): Promise<GetCompanyCountriesResponse> {
    const {params, query} = args;

    const result = baseService.get<GetCompanyCountriesResponse, GetCompanyCountriesQuery>(
      `${endpoints.companies}/${params.companyId}/countries`,
      query,
    );

    return result;
  },
  getCompanyOvenModels: async function (args: {
    params: GetCompanyOvenModelsParams;
    query?: GetCompanyOvenModelsQuery;
  }): Promise<GetCompanyOvenModelsResponse> {
    const {params, query} = args;

    const result = await baseService.get<GetCompanyOvenModelsResponse, GetCompanyOvenModelsQuery>(
      `${endpoints.companies}/${params.companyId}/oven-models`,
      query,
    );

    return result;
  },
};

export default companyService;
