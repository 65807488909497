import {useTranslation} from 'react-i18next';
import {ReactComponent as OvenIcon} from '../../assets/icons/oven.svg';
//import {ReactComponent as RecipesIcon} from '../../assets/icons/recipes.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings.svg';
import Menu from '../common/Menu';

export type BakeryMenuItem = 'settings' | 'ovens' | 'recipes';

export type BakeryMenuProps = {
  selectedItem?: BakeryMenuItem;
  onSelectItem?: (item: BakeryMenuItem) => void;
};

function BakeryMenu(props: BakeryMenuProps) {
  const {selectedItem, onSelectItem} = props;
  const {t} = useTranslation();

  return (
    <Menu
      textColor="transparent"
      items={[
        {
          text: t('bakery_menu_settings_label'),
          icon: SettingsIcon,
          selected: selectedItem === 'settings',
          onSelectItem: () => onSelectItem?.('settings'),
        },
        {
          text: t('bakery_menu_ovens_label'),
          icon: OvenIcon,
          selected: selectedItem === 'ovens',
          onSelectItem: () => onSelectItem?.('ovens'),
        },
        // {
        //   text: t('bakery_menu_recipes_label'),
        //   icon: RecipesIcon,
        //   disabled: true,
        // },
      ]}
    />
  );
}

export default BakeryMenu;
