import {Navigate, createBrowserRouter} from 'react-router-dom';
import AuthLayout from '../components/auth/AuthLayout';
import Login from '../components/auth/Login';
import PasswordRecovery from '../components/auth/PasswordRecovery';
import PasswordReset from '../components/auth/PasswordReset';
import Signup from '../components/auth/Signup';
import Welcome from '../components/auth/Welcome';
import Bakeries from '../components/bakeries/Bakeries';
import BakeriesLayout from '../components/bakeries/BakeriesLayout';
import BakeryInvite from '../components/bakeries/BakeryInvite';
import CreateBakery from '../components/bakeries/CreateBakery';
import AddOven from '../components/bakeries/add-oven/AddOven';
import NotFound from '../components/common/NotFound';
import Main from '../components/layout/Main';
import Dashboard from '../components/navigation/Dashboard';
import ChangePassword from '../components/profile/ChangePassword';
import Information from '../components/profile/Information';
import MigrateAccount from '../components/profile/MigrateAccount';
import Notifications from '../components/profile/Notifications';
import ProfileLayout from '../components/profile/ProfileLayout';
import Settings from '../components/profile/Settings';
import CleaningSchedule from '../components/programming/CleaningSchedule';
import ProgrammingLayout from '../components/programming/ProgrammingLayout';
import RecipeSchedule from '../components/programming/RecipeSchedule';
import Schedules from '../components/programming/Schedules';
import Recipes from '../components/recipes/Recipes';
import RecipesLayout from '../components/recipes/RecipesLayout';
import RecipeSettings from '../components/recipes/settings/RecipeSettings';
import Statistics from '../components/statistics/Statistics';
import StatisticsLayout from '../components/statistics/StatisticsLayout';
import PrivateRoute from './PrivateRoute';
import paths from './paths';

const router = createBrowserRouter([
  {
    element: <Main auth />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {path: paths.home, element: <Navigate to={paths.welcome} />},
          {path: paths.welcome, element: <Welcome />},
          {path: paths.signup, element: <Signup />},
          {path: paths.login, element: <Login />},
          {path: paths.passwordRecovery, element: <PasswordRecovery />},
          {path: paths.passwordReset, element: <PasswordReset />},
          {
            element: <PrivateRoute />,
            children: [
              {path: paths.profileChangePassword, element: <ChangePassword />},
              {path: paths.profileMigrateAccount, element: <MigrateAccount />},
              {path: paths.bakeriesCreate, element: <CreateBakery />},
              {path: paths.bakeriesInvite, element: <BakeryInvite />},
            ],
          },
        ],
      },
    ],
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        element: <Main />,
        children: [
          {path: paths.dashboard, element: <Dashboard />},
          {
            element: <ProfileLayout />,
            children: [
              {
                path: paths.profile,
                element: <Navigate to={`${paths.profileInformation}/preferences`} replace />,
              },
              {path: paths.profileInformation, element: <Information />},
              {path: `${paths.profileInformation}/:tab`, element: <Information key="tab" />},
              {path: paths.profileNotifications, element: <Notifications />},
              {path: `${paths.profileNotifications}/:tab`, element: <Notifications key="tab" />},
              {
                path: `${paths.profileNotifications}/:tab/:notificationId`,
                element: <Notifications key="notification" />,
              },
              {path: paths.profileSettings, element: <Settings />},
              {path: `${paths.profileSettings}/:tab`, element: <Settings key="tab" />},
            ],
          },
          {
            element: <BakeriesLayout />,
            children: [
              {path: paths.bakeries, element: <Bakeries />},
              {path: `${paths.bakeries}/:countryId`, element: <Bakeries key="country" />},
              {path: `${paths.bakeries}/:countryId/:cityId`, element: <Bakeries key="city" />},
              {
                path: `${paths.bakeries}/:countryId/:cityId/:bakeryId`,
                element: <Bakeries key="bakery" />,
              },
              {
                path: `${paths.bakeries}/:countryId/:cityId/:bakeryId/:menuItem`,
                element: <Bakeries key="menu" />,
              },
              {
                path: `${paths.bakeries}/:countryId/:cityId/:bakeryId/settings/add-oven`,
                element: <AddOven />,
              },
              {
                path: `${paths.bakeries}/:countryId/:cityId/:bakeryId/:menuItem/:ovenModelId`,
                element: <Bakeries key="ovenModel" />,
              },
            ],
          },
          {
            element: <RecipesLayout />,
            children: [
              {path: paths.recipes, element: <Recipes />},
              {path: `${paths.recipes}/:ovenModelId`, element: <Recipes key="ovenModel" />},
              {
                path: `${paths.recipes}/:ovenModelId/:recipeTypeId`,
                element: <Recipes key="recipeType" />,
              },
              {
                path: `${paths.recipes}/:ovenModelId/:recipeTypeId/:action`,
                element: <RecipeSettings />,
              },
              {
                path: `${paths.recipes}/:ovenModelId/:recipeTypeId/:action/:recipeId`,
                element: <RecipeSettings />,
              },
            ],
          },
          {
            element: <StatisticsLayout />,
            children: [
              {path: paths.statistics, element: <Statistics />},
              {path: `${paths.statistics}/:countryId`, element: <Statistics key="country" />},
              {path: `${paths.statistics}/:countryId/:cityId`, element: <Statistics key="city" />},
              {
                path: `${paths.statistics}/:countryId/:cityId/:bakeryId`,
                element: <Statistics key="bakery" />,
              },
              {
                path: `${paths.statistics}/:countryId/:cityId/:bakeryId/:ovenModelId`,
                element: <Statistics key="ovenModel" />,
              },
            ],
          },
          {
            element: <ProgrammingLayout />,
            children: [
              {path: paths.programming, element: <Schedules />},
              {path: `${paths.programming}/:countryId`, element: <Schedules key="country" />},
              {path: `${paths.programming}/:countryId/:cityId`, element: <Schedules key="city" />},
              {
                path: `${paths.programming}/:countryId/:cityId/:bakeryId`,
                element: <Schedules key="bakery" />,
              },
              {
                path: `${paths.programming}/:countryId/:cityId/:bakeryId/:ovenModelId`,
                element: <Schedules key="ovenModel" />,
              },
              {
                path: `${paths.programming}/:countryId/:cityId/:bakeryId/:ovenModelId/:weekDay`,
                element: <Schedules key="weekDay" />,
              },
              {
                path: `${paths.programming}/:countryId/:cityId/:bakeryId/:ovenModelId/:weekDay/recipe`,
                element: <RecipeSchedule />,
              },
              {
                path: `${paths.programming}/:countryId/:cityId/:bakeryId/:ovenModelId/:weekDay/recipe/:scheduleId`,
                element: <RecipeSchedule />,
              },
              {
                path: `${paths.programming}/:countryId/:cityId/:bakeryId/:ovenModelId/:weekDay/cleaning`,
                element: <CleaningSchedule />,
              },
              {
                path: `${paths.programming}/:countryId/:cityId/:bakeryId/:ovenModelId/:weekDay/cleaning/:scheduleId`,
                element: <CleaningSchedule />,
              },
            ],
          },
        ],
      },
    ],
  },
  {path: '*', element: <NotFound />},
]);

export default router;
