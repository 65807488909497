import {
  CreateOrUpdateScheduleParams,
  CreateOrUpdateScheduleRequest,
  CreateOrUpdateSchedulesRequest,
  CreateScheduleRequest,
  DeleteScheduleParams,
  GetScheduleParams,
  GetScheduleQuery,
  GetSchedulesQuery,
  UpdateScheduleParams,
  UpdateScheduleRequest,
  ValidateScheduleRequest,
} from '../models/requests/schedule';
import {
  CreateOrUpdateScheduleResponse,
  CreateOrUpdateSchedulesResponse,
  CreateScheduleResponse,
  DeleteScheduleResponse,
  GetScheduleResponse,
  GetSchedulesResponse,
  UpdateScheduleResponse,
  ValidateScheduleResponse,
} from '../models/responses/schedule';
import baseService from './base';
import endpoints from './endpoints';

export type ScheduleService = {
  getSchedules: (args: {query?: GetSchedulesQuery}) => Promise<GetSchedulesResponse>;
  getSchedule: (args: {
    params: GetScheduleParams;
    query?: GetScheduleQuery;
  }) => Promise<GetScheduleResponse>;
  createSchedule: (args: {request: CreateScheduleRequest}) => Promise<CreateScheduleResponse>;
  updateSchedule: (args: {
    params: UpdateScheduleParams;
    request: UpdateScheduleRequest;
  }) => Promise<UpdateScheduleResponse>;
  deleteSchedule: (args: {params: DeleteScheduleParams}) => Promise<DeleteScheduleResponse>;
  validateSchedule: (args: {request: ValidateScheduleRequest}) => Promise<ValidateScheduleResponse>;
  createOrUpdateSchedule: (args: {
    params: CreateOrUpdateScheduleParams;
    request: CreateOrUpdateScheduleRequest;
  }) => Promise<CreateOrUpdateScheduleResponse>;
  createOrUpdateSchedules: (args: {
    request: CreateOrUpdateSchedulesRequest;
  }) => Promise<CreateOrUpdateSchedulesResponse>;
};

const scheduleService: ScheduleService = {
  getSchedules: async function (args: {query?: GetSchedulesQuery}): Promise<GetSchedulesResponse> {
    const {query} = args;

    const result = await baseService.get<GetSchedulesResponse>(endpoints.schedules, query);

    return result;
  },
  getSchedule: function (args: {
    params: GetScheduleParams;
    query?: GetScheduleQuery;
  }): Promise<GetScheduleResponse> {
    const {params, query} = args;

    const result = baseService.get<GetScheduleResponse>(
      `${endpoints.schedules}/${params.scheduleId}`,
      query,
    );

    return result;
  },
  createSchedule: async function (args: {
    request: CreateScheduleRequest;
  }): Promise<CreateScheduleResponse> {
    const {request} = args;

    const result = await baseService.post<CreateScheduleResponse, CreateScheduleRequest>(
      endpoints.schedules,
      request,
    );

    return result;
  },
  updateSchedule: async function (args: {
    params: UpdateScheduleParams;
    request: UpdateScheduleRequest;
  }): Promise<UpdateScheduleResponse> {
    const {params, request} = args;

    const result = await baseService.patch<UpdateScheduleResponse, UpdateScheduleRequest>(
      `${endpoints.schedules}/${params.scheduleId}`,
      request,
    );

    return result;
  },
  deleteSchedule: async function (args: {
    params: DeleteScheduleParams;
  }): Promise<DeleteScheduleResponse> {
    const {params} = args;

    const result = await baseService.delete<DeleteScheduleResponse>(
      `${endpoints.schedules}/${params.scheduleId}`,
    );

    return result;
  },
  validateSchedule: async function (args: {
    request: ValidateScheduleRequest;
  }): Promise<ValidateScheduleResponse> {
    const {request} = args;

    const result = await baseService.post<ValidateScheduleResponse, ValidateScheduleRequest>(
      `${endpoints.schedules}/validate`,
      request,
    );

    return result;
  },
  createOrUpdateSchedule: async function (args: {
    params: CreateOrUpdateScheduleParams;
    request: CreateOrUpdateScheduleRequest;
  }): Promise<CreateOrUpdateScheduleResponse> {
    const {params, request} = args;

    const result = await baseService.put<
      CreateOrUpdateScheduleResponse,
      CreateOrUpdateScheduleRequest
    >(`${endpoints.schedules}/${params.scheduleId}`, request);

    return result;
  },
  createOrUpdateSchedules: async function (args: {
    request: CreateOrUpdateSchedulesRequest;
  }): Promise<CreateOrUpdateSchedulesResponse> {
    const {request} = args;

    const result = await baseService.put<
      CreateOrUpdateSchedulesResponse,
      CreateOrUpdateSchedulesRequest
    >(`${endpoints.schedules}/batch`, request);

    return result;
  },
};

export default scheduleService;
