import {
  Box,
  List,
  ListItem,
  ListItemProps,
  ListProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import {forwardRef, Fragment, useRef, useState} from 'react';
import useEffectOnce from '../../hooks/common/use-effect-once';
import useSearchableScroll from '../../hooks/common/use-searchable-scroll';

function getTypographyProps(
  selected?: boolean,
  disableSelectedStyle?: boolean,
  hovered?: boolean,
): TypographyProps {
  return {
    variant: 'body2',
    sx: {
      fontWeight: disableSelectedStyle ? (hovered ? 'bold' : '400') : selected ? 'bold' : '400',
      color: disableSelectedStyle
        ? hovered
          ? 'primary.main'
          : 'secondary.main'
        : selected
        ? 'primary.main'
        : 'secondary.main',
    },
  };
}

export type SimpleListOption = string | {id: string; primary: string; secondary?: string};

type SimpleListItemProps = {
  option: SimpleListOption;
  onClick?: () => void;
  selected?: boolean;
  disableSelectedStyle?: boolean;
};

const SimpleListItem = forwardRef<HTMLLIElement, SimpleListItemProps>((props, ref) => {
  const {option, onClick, selected, disableSelectedStyle} = props;

  const [hovered, setHovered] = useState(false);

  const typographyProps = getTypographyProps(selected, disableSelectedStyle, hovered);

  return (
    <ListItem
      ref={ref}
      sx={{
        paddingTop: 0,
        paddingBottom: 4,
        paddingInline: 0,
        cursor: 'pointer',
        '&.Mui-selected': {
          backgroundColor: 'inherit',
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}>
      {typeof option === 'string' ? (
        <Typography {...typographyProps}>{option}</Typography>
      ) : (
        <Fragment>
          <Typography {...typographyProps} sx={{...typographyProps.sx, flex: 1}}>
            {option.primary}
          </Typography>
          {option.secondary && (
            <Typography {...typographyProps} sx={{...typographyProps.sx, fontWeight: '400'}}>
              {option.secondary}
            </Typography>
          )}
        </Fragment>
      )}
    </ListItem>
  );
});

export type SearchableSimpleListProps = SimpleListProps & {
  onSearchOption?: (option: SimpleListOption, searchText: string) => boolean;
};

export function SearchableSimpleList(props: SearchableSimpleListProps) {
  const {options, selectedOption, onChangeSelectedOption, disableSelectedStyle} = props;

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffectOnce(() => {
    containerRef.current?.focus();
  });

  const {refs, handleKeyDown} = useSearchableScroll<SimpleListOption, HTMLLIElement>({
    items: options,
    searchPredicate: (option, searchText) => {
      if (typeof option === 'string') {
        return option.toLowerCase().startsWith(searchText.toLowerCase());
      }
      return option.primary.toLowerCase().startsWith(searchText.toLowerCase());
    },
  });

  function handleChangeSelectedOption(option: SimpleListOption) {
    if (typeof option === 'string') {
      onChangeSelectedOption?.(option);
      return;
    }
    onChangeSelectedOption?.(option.id);
  }

  return (
    <Box
      ref={containerRef}
      sx={{'&:focus': {outline: 'none'}}}
      tabIndex={0}
      onKeyDown={handleKeyDown}>
      <List disablePadding>
        {options.map((option, index) => {
          const selected = (typeof option === 'string' ? option : option.id) === selectedOption;
          return (
            <SimpleListItem
              key={index}
              ref={refs[index]}
              option={option}
              selected={selected}
              disableSelectedStyle={disableSelectedStyle}
              onClick={() => handleChangeSelectedOption(option)}
            />
          );
        })}
      </List>
    </Box>
  );
}

export type SimpleListProps = {
  options: SimpleListOption[];
  selectedOption?: string;
  onChangeSelectedOption?: (selectedOption: string) => void;
  disableSelectedStyle?: boolean;
  listProps?: ListProps;
  listItemProps?: ListItemProps;
  typographyProps?: TypographyProps;
  entriesNotFoundMessage?: string;
};

function SimpleList(props: SimpleListProps) {
  const {
    options,
    selectedOption,
    onChangeSelectedOption,
    disableSelectedStyle,
    entriesNotFoundMessage,
  } = props;

  function handleChangeSelectedOption(option: SimpleListOption) {
    if (typeof option === 'string') {
      onChangeSelectedOption?.(option);
      return;
    }
    onChangeSelectedOption?.(option.id);
  }

  return (
    <List disablePadding>
      {options.length === 0 && (
        <ListItem sx={{margin: 0, padding: 0}}>
          <Typography variant="body2" sx={{color: 'text.primary'}}>
            {entriesNotFoundMessage}
          </Typography>
        </ListItem>
      )}
      {options.map((option, index) => {
        const selected = (typeof option === 'string' ? option : option.id) === selectedOption;
        return (
          <SimpleListItem
            key={index}
            option={option}
            selected={selected}
            disableSelectedStyle={disableSelectedStyle}
            onClick={() => handleChangeSelectedOption(option)}
          />
        );
      })}
    </List>
  );
}

export default SimpleList;
