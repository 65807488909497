import {useTranslation} from 'react-i18next';
import {ReactComponent as AcceptIcon} from '../../../assets/icons/accept.svg';
import {ReactComponent as NextIcon} from '../../../assets/icons/next.svg';
import {ReactComponent as NumberFiveIcon} from '../../../assets/icons/number-five.svg';
import {ReactComponent as NumberFourIcon} from '../../../assets/icons/number-four.svg';
import {ReactComponent as NumberOneIcon} from '../../../assets/icons/number-one.svg';
import {ReactComponent as NumberThreeIcon} from '../../../assets/icons/number-three.svg';
import {ReactComponent as NumberTwoIcon} from '../../../assets/icons/number-two.svg';
import Menu from '../../common/Menu';
import {AddOvenStep, AddOvenSteps} from './AddOven';

type AddOvenMenuProps = {
  steps: AddOvenSteps;
  setSteps: (setter: (steps: AddOvenSteps) => AddOvenSteps) => void;
  selectedStep: AddOvenStep;
  setSelectedStep: (step: AddOvenStep) => void;
  onAddOven: () => void;
};

function AddOvenMenu(props: AddOvenMenuProps) {
  const {steps, setSteps, selectedStep, setSelectedStep, onAddOven} = props;
  const {t} = useTranslation();

  return (
    <Menu
      items={[
        {
          text: '',
          icon: NumberOneIcon,
          selected: selectedStep === 'model',
          onSelectItem: () => setSelectedStep('model'),
        },
        {
          text: '',
          icon: NumberTwoIcon,
          selected: selectedStep === 'subModel',
          hidden: !steps.subModel.visible,
          onSelectItem: () => setSelectedStep('subModel'),
        },
        {
          text: '',
          icon: NumberTwoIcon,
          selected: selectedStep === 'option',
          hidden: !steps.option.visible,
          onSelectItem: () => setSelectedStep('option'),
        },
        {
          text: '',
          icon: NumberThreeIcon,
          selected: selectedStep === 'ovens',
          hidden: !steps.ovens.visible,
          onSelectItem: () => setSelectedStep('ovens'),
        },
        {
          text: '',
          icon: steps.option.visible ? NumberThreeIcon : NumberTwoIcon,
          selected: selectedStep === 'chambersNumber',
          hidden: !steps.chambersNumber.visible,
          onSelectItem: () => setSelectedStep('chambersNumber'),
        },
        {
          text: '',
          icon:
            steps.subModel.visible || steps.option.visible || steps.chambersNumber.visible
              ? steps.ovens.visible || (steps.option.visible && steps.chambersNumber.visible)
                ? NumberFourIcon
                : NumberThreeIcon
              : NumberTwoIcon,
          selected: selectedStep === 'serialNumber',
          hidden: !steps.serialNumber.visible,
          onSelectItem: () => setSelectedStep('serialNumber'),
        },
        {
          text: '',
          icon:
            steps.subModel.visible || steps.option.visible || steps.chambersNumber.visible
              ? steps.ovens.visible || (steps.option.visible && steps.chambersNumber.visible)
                ? NumberFiveIcon
                : NumberFourIcon
              : NumberThreeIcon,
          selected: selectedStep === 'description',
          hidden: !steps.description.visible,
          onSelectItem: () => setSelectedStep('description'),
        },
        {
          text: t('add_oven_next_step_label'),
          icon: NextIcon,
          selected: true,
          selectedIconColor: 'primary.main',
          hidden:
            steps.model.error ||
            steps.subModel.error ||
            steps.option.error ||
            steps.ovens.error ||
            steps.chambersNumber.error ||
            (selectedStep === 'serialNumber' && steps.serialNumber.error) ||
            steps.description.visible,
          onSelectItem: () => {
            setSteps((steps) => ({
              ...steps,
              serialNumber: {...steps.serialNumber, visible: true},
              description: {...steps.description, visible: selectedStep === 'serialNumber'},
            }));
            setSelectedStep(selectedStep === 'serialNumber' ? 'description' : 'serialNumber');
          },
        },
        {
          text: t('add_oven_finish_step_label'),
          icon: AcceptIcon,
          selected: true,
          selectedIconColor: 'primary.main',
          hidden:
            steps.model.error ||
            steps.subModel.error ||
            steps.option.error ||
            steps.ovens.error ||
            steps.chambersNumber.error ||
            steps.serialNumber.error ||
            steps.description.error ||
            !steps.description.visible,
          onSelectItem: onAddOven,
        },
      ]}
    />
  );
}

export default AddOvenMenu;
