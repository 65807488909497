import {Fragment, PropsWithChildren, useEffect} from 'react';
import {useQueryClient} from 'react-query';
import {subscribe} from './app';

type UnsubscribeCallback = (() => void) | null;

export function FirebaseSubscriber(props: PropsWithChildren) {
  const queryClient = useQueryClient();

  useEffect(() => {
    let unsubscribe: UnsubscribeCallback = null;
    try {
      unsubscribe = subscribe(() => {
        queryClient.invalidateQueries('notifications');
      });
      console.log('subscribed to firebase');
    } catch (error) {
      console.log('error subscribing to firebase');
      console.log(error);
    }
    return () => {
      unsubscribe?.();
      console.log('unsubscribed from firebase');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>{props.children}</Fragment>;
}
