import {
  GetOvenParams,
  GetOvenQuery,
  GetOvensQuery,
  UpdateOvenParams,
  UpdateOvenRequest,
} from '../../models/requests/oven';
import {GetOvenResponse, GetOvensResponse, UpdateOvenResponse} from '../../models/responses/oven';
import {ApplicationError} from '../../utils/errors';
import {OvenService} from '../oven';
import {data} from './data';

const mockOvenService: OvenService = {
  getOvens: function (args: {query?: GetOvensQuery}): Promise<GetOvensResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const ovens = data.ovens
          .filter(
            (oven) =>
              (query?.ovenModelId == null || oven.ovenModelId === query.ovenModelId) &&
              (query?.bakeryId == null || oven.bakeryId === query.bakeryId) &&
              (query?.bakeryIds == null ||
                (oven.bakeryId != null && query.bakeryIds.includes(oven.bakeryId))),
          )
          .map((oven) => ({
            ...oven,
            ovenChambers: data.ovenChambers
              .filter((ovenChamber) => ovenChamber.ovenId === oven.id)
              .map((ovenChamber) => ({
                ...ovenChamber,
                ovenPanels: data.ovenPanels
                  .filter((ovenPanel) => ovenPanel.ovenChamberId === ovenChamber.id)
                  .map((ovenPanel) => ({...ovenPanel})),
              })),
            ovenPanels: data.ovenPanels
              .filter((ovenPanel) => ovenPanel.ovenId === oven.id)
              .map((ovenPanel) => ({...ovenPanel})),
          }));

        resolve(ovens);
      }, 500),
    );
  },
  getOven: function (args: {
    params: GetOvenParams;
    query?: GetOvenQuery;
  }): Promise<GetOvenResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const oven = data.ovens.find((oven) => oven.id === params.ovenId);

        if (oven == null) {
          reject(ApplicationError.notFound('Oven'));
          return;
        }

        resolve({
          ...oven,
          ovenChambers: data.ovenChambers
            .filter((ovenChamber) => ovenChamber.ovenId === oven.id)
            .map((ovenChamber) => ({
              ...ovenChamber,
              ovenPanels: data.ovenPanels
                .filter((ovenPanel) => ovenPanel.ovenChamberId === ovenChamber.id)
                .map((ovenPanel) => ({...ovenPanel})),
            })),
          ovenPanels: data.ovenPanels
            .filter((ovenPanel) => ovenPanel.ovenId === oven.id)
            .map((ovenPanel) => ({...ovenPanel})),
        });
      }, 500),
    );
  },
  updateOven: function (args: {
    params: UpdateOvenParams;
    request: UpdateOvenRequest;
  }): Promise<UpdateOvenResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        const oven = data.ovens.find((oven) => oven.id === params.ovenId);

        if (oven == null) {
          reject(ApplicationError.notFound('Oven'));
          return;
        }

        oven.description = request.description;
        oven.ovenGroupId = request.ovenGroupId;
        oven.ovenGroupOrder = request.ovenGroupOrder;
        oven.bakeryId = request.bakeryId;
        oven.assignedAt = new Date().toISOString();

        for (const requestOvenChamber of request.ovenChambers ?? []) {
          const ovenChamber = data.ovenChambers.find(
            (ovenChamber) => ovenChamber.id === requestOvenChamber.id,
          );

          if (ovenChamber == null) {
            reject(ApplicationError.badRequest(['Oven chamber not found.']));
            return;
          }

          ovenChamber.ovenId = oven.id;
          ovenChamber.ovenOrder = requestOvenChamber.ovenOrder;
          ovenChamber.bakeryId = oven.bakeryId;
          ovenChamber.assignedAt = new Date().toISOString();
        }

        resolve(params.ovenId);
      }, 500),
    );
  },
};

export default mockOvenService;
