import {Badge, Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as BakeriesIcon} from '../../assets/icons/bakery.svg';
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import {ReactComponent as ProgrammingIcon} from '../../assets/icons/programming.svg';
import {ReactComponent as RecipesIcon} from '../../assets/icons/recipes.svg';
import {ReactComponent as StatisticsIcon} from '../../assets/icons/statistics.svg';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import DashboardMenuItem from './DashboardMenuItem';

function DashboardMenu() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const {data: hasUnseenNotifications = false} = useQuery({
    enabled: user != null,
    queryKey: ['notifications'],
    queryFn: () =>
      services.notification.getNotifications({
        query: {userId: user?.id, companyId: user?.companyId},
      }),
    select: (notifications) => notifications.some((notification) => !notification.isSeen),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}>
      <DashboardMenuItem
        icon={RecipesIcon}
        text={t('menu_recipes_label')}
        onClick={() => navigate(paths.recipes)}
      />
      <DashboardMenuItem
        icon={ProgrammingIcon}
        text={t('menu_programming_label')}
        onClick={() => navigate(paths.programming)}
      />
      <DashboardMenuItem
        icon={BakeriesIcon}
        text={t('menu_bakeries_label')}
        onClick={() => navigate(paths.bakeries)}
      />
      <DashboardMenuItem
        icon={StatisticsIcon}
        text={t('menu_statistics_label')}
        onClick={() => navigate(paths.statistics)}
      />
      <Badge
        sx={{
          '& .MuiBadge-badge': {
            minWidth: '12px',
            width: '12px',
            height: '12px',
            padding: 0,
          },
        }}
        color="primary"
        badgeContent=" "
        invisible={!hasUnseenNotifications}>
        <DashboardMenuItem
          icon={ProfileIcon}
          text={t('menu_profile_label')}
          onClick={() => navigate(paths.profile)}
        />
      </Badge>
    </Box>
  );
}

export default DashboardMenu;
