import {SvgIcon, SvgIconProps, Theme} from '@mui/material';
import {ElementType} from 'react';

export type IconProps = {
  IconComponent: ElementType<any>;
  size?: string;
  color?: string;
  svgIconProps?: SvgIconProps;
};

function getColor(color: string, theme: Theme) {
  switch (color) {
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    default:
      return color;
  }
}

function Icon(props: IconProps) {
  const {IconComponent, size = '25px', color = 'primary', svgIconProps} = props;
  return (
    <SvgIcon
      component={IconComponent}
      inheritViewBox
      sx={{
        width: size,
        height: size,
        color: (theme) => getColor(color, theme),
      }}
      {...svgIconProps}
    />
  );
}

export default Icon;
