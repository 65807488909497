import {CssBaseline, ThemeProvider} from '@mui/material';
import {QueryClient, QueryClientProvider} from 'react-query';
import {RouterProvider} from 'react-router-dom';
import router from './routes/router';
import useThemeStore from './state/theme';
import darkTheme from './themes/dark';
import lightTheme from './themes/light';
import errorUtils from './utils/errors';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: (error) => errorUtils.handleError(error),
    },
    mutations: {
      retry: false,
      onError: (error) => errorUtils.handleError(error),
    },
  },
});

function App() {
  const theme = useThemeStore((state) => state.theme);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
