const functionUtils = {
  debounce: function <T extends (...args: any[]) => any>(fn: T, delay: number) {
    let timeout: any;
    return function (...args: Parameters<T>) {
      clearTimeout(timeout);
      timeout = setTimeout(() => fn(...args), delay);
    };
  },
};

export default functionUtils;
