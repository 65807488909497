import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';
import {pageHeight} from '../navigation/Navbar';

function StatisticsLayout() {
  return (
    <Box sx={{display: 'flex', width: '100vw', height: pageHeight}}>
      <Outlet />
    </Box>
  );
}

export default StatisticsLayout;
