import {Box, List, ListItem, Typography} from '@mui/material';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Oven from '../../../../models/entities/oven';
import {OvenSubModelId} from '../../../../models/entities/oven-sub-model';
import useAuthStore from '../../../../state/auth';
import dateUtils from '../../../../utils/dates';
import {Language} from '../../../../utils/types';
import {AddOvenData, AddOvenSteps} from '../AddOven';
import {OvenModelId} from '../../../../models/entities/oven-model';

export type OvensStepProps = {
  ovens: Oven[];
  setSteps: (setter: (steps: AddOvenSteps) => AddOvenSteps) => void;
  addOvenData: AddOvenData;
  setAddOvenData: (setter: (data: AddOvenData) => AddOvenData) => void;
};

function OvensStep(props: OvensStepProps) {
  const {setSteps, addOvenData, setAddOvenData} = props;
  const {t, i18n} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const ovens = useMemo(
    () =>
      props.ovens.filter(
        (oven) =>
          oven.ovenModelId === addOvenData.ovenModelId &&
          oven.bakeryId !== user?.companyId &&
          (addOvenData.ovenModelId !== OvenModelId.Turboram || oven.ovenGroupId == null),
      ),
    [props.ovens, addOvenData.ovenModelId, user],
  );

  function handleSelectOven(oven: Oven) {
    setAddOvenData((addOvenData) => ({
      ...addOvenData,
      selectedOvenId: oven.id,
      bottomOvenSerialNumber:
        addOvenData.ovenSubModelId === OvenSubModelId.TurboramCombi ? oven.serialNumber : '',
    }));
    setSteps((steps) => ({
      ...steps,
      ovens: {visible: true, error: false},
      serialNumber: {...steps.serialNumber, error: true},
    }));
  }

  return (
    <List>
      {ovens.length === 0 && (
        <Box sx={{width: '65vw', padding: 2}}>
          <Typography variant="body2" color="secondary">
            {t('add_ovens_ovens_not_found_label')}
          </Typography>
        </Box>
      )}
      {ovens.map((oven) => (
        <ListItem key={oven.id} sx={{margin: 0, padding: 0}}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '65vw',
              color: addOvenData.selectedOvenId === oven.id ? 'primary.main' : 'text.primary',
              cursor: 'pointer',
              '&:hover': {
                fontWeight: 'bold',
                color: 'primary.main',
              },
            }}
            onClick={() => handleSelectOven(oven)}>
            <Box sx={{flex: 1, padding: 2}}>
              <Typography sx={{fontWeight: 'bold'}} variant="body2">
                {oven.description ?? oven.serialNumber}
              </Typography>
              <Typography variant="body2">{`${t('add_oven_serial_number_label')}: ${
                oven.serialNumber
              }`}</Typography>
            </Box>
            <Box sx={{textAlign: 'end', padding: 2}}>
              <Typography variant="body2">
                {oven.assignedAt != null
                  ? dateUtils.toLocaleDateString(
                      new Date(oven.assignedAt),
                      i18n.language as Language,
                    )
                  : ''}
              </Typography>
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );
}

export default OvensStep;
