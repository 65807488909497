import {ElementType} from 'react';
import {ReactComponent as BakeriesIcon} from '../assets/icons/bakery.svg';
import {ReactComponent as ProfileIcon} from '../assets/icons/profile.svg';
import {ReactComponent as ProgrammingIcon} from '../assets/icons/programming.svg';
import {ReactComponent as RecipesIcon} from '../assets/icons/recipes.svg';
import {ReactComponent as StatisticsIcon} from '../assets/icons/statistics.svg';

export enum Menu {
  Dashboard = 'dashboard',
  Recipes = 'recipes',
  Programming = 'programming',
  Bakeries = 'bakeries',
  Statistics = 'statistics',
  Profile = 'profile',
}

const menuMap = {
  [Menu.Dashboard]: {
    label: '',
    icon: null,
  },
  [Menu.Recipes]: {
    label: 'menu_recipes_label',
    icon: RecipesIcon,
  },
  [Menu.Programming]: {
    label: 'menu_programming_label',
    icon: ProgrammingIcon,
  },
  [Menu.Bakeries]: {
    label: 'menu_bakeries_label',
    icon: BakeriesIcon,
  },
  [Menu.Statistics]: {
    label: 'menu_statistics_label',
    icon: StatisticsIcon,
  },
  [Menu.Profile]: {
    label: 'menu_profile_label',
    icon: ProfileIcon,
  },
};

const menuUtils = {
  getMenuFromPath: function (path: string): Menu {
    const targetMenu = Object.values(Menu).find((menu) => path.startsWith(`/${menu}`));
    return targetMenu ?? Menu.Dashboard;
  },
  getMenuLabel: function (menu: Menu): string {
    return menuMap[menu].label;
  },
  getMenuIcon: function (menu: Menu): ElementType<any> | null {
    return menuMap[menu].icon;
  },
};

export default menuUtils;
