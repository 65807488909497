import {
  DeleteNotificationParams,
  DeleteNotificationsRequest,
  GetNotificationsQuery,
  UpdateNotificationParams,
  UpdateNotificationRequest,
} from '../../models/requests/notification';
import {
  DeleteNotificationsResponse,
  GetNotificationsResponse,
  UpdateNotificationResponse,
} from '../../models/responses/notification';
import {ApplicationError} from '../../utils/errors';
import {NotificationService} from '../notification';
import {data} from './data';

const mockNotificationService: NotificationService = {
  getNotifications: function (args: {
    query?: GetNotificationsQuery;
  }): Promise<GetNotificationsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const userNotificationTypes = data.userNotificationTypes.filter(
          (userNotificationType) => userNotificationType.userId === query?.userId,
        );

        const notificationTypeIds = new Set(
          data.notificationTypes
            .filter((notificationType) => {
              const userNotificationType = userNotificationTypes.find(
                (userNotificationType) =>
                  userNotificationType.notificationTypeId === notificationType.id,
              );

              return userNotificationType == null || userNotificationType.notify;
            })
            .map((notificationType) => notificationType.id),
        );

        const notifications = data.notifications
          .filter((notification) => notificationTypeIds.has(notification.notificationTypeId))
          .map((notification) => ({...notification}));

        resolve(notifications);
      }, 500),
    );
  },
  updateNotification: function (args: {
    params: UpdateNotificationParams;
    request: UpdateNotificationRequest;
  }): Promise<UpdateNotificationResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        const notification = data.notifications.find(
          (notification) => notification.id === params.notificationId,
        );

        if (notification == null) {
          reject(ApplicationError.notFound('Notification'));
          return;
        }

        notification.isSeen = request.isSeen;
        notification.isFlagged = request.isFlagged;

        resolve(notification.id);
      }, 500),
    );
  },
  deleteNotification: function (args: {params: DeleteNotificationParams}): Promise<string> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const notification = data.notifications.find(
          (notification) => notification.id === params.notificationId,
        );

        if (notification == null) {
          reject(ApplicationError.notFound('Notification'));
          return;
        }

        data.notifications = data.notifications.filter(
          (notification) => notification.id !== params.notificationId,
        );

        resolve(params.notificationId);
      }, 500),
    );
  },
  deleteNotifications: function (args: {
    request: DeleteNotificationsRequest;
  }): Promise<DeleteNotificationsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {request} = args;

        const notificationIds = new Set(request);

        data.notifications = data.notifications.filter(
          (notification) => !notificationIds.has(notification.id),
        );

        resolve(request);
      }, 500),
    );
  },
};

export default mockNotificationService;
