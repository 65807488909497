import {GetCleaningsQuery} from '../models/requests/cleaning';
import {GetCleaningsResponse} from '../models/responses/cleaning';
import baseService from './base';
import endpoints from './endpoints';

export type CleaningService = {
  getCleanings: (args: {query?: GetCleaningsQuery}) => Promise<GetCleaningsResponse>;
};

const cleaningService: CleaningService = {
  getCleanings: async function (args: {query?: GetCleaningsQuery}): Promise<GetCleaningsResponse> {
    const {query} = args;

    const result = await baseService.get<GetCleaningsResponse>(endpoints.cleanings, query);

    return result;
  },
};

export default cleaningService;
