import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Switch from '../../../../common/Switch';

type TurbineSpeedRowProps = {
  turbineSpeed: number;
  onChange: (turbineSpeed: number) => void;
};

function TurbineSpeedRow(props: TurbineSpeedRowProps) {
  const {turbineSpeed, onChange} = props;
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingBlock: 1,
        paddingInline: 2,
      }}>
      <Box>
        <Typography variant="body2" sx={{fontWeight: 'bold', color: 'text.primary'}}>
          {t('recipe_settings_phases_turbine_speed_label')}
        </Typography>
        <Typography variant="caption" sx={{color: 'text.secondary'}}>
          {t('recipe_settings_phases_turbine_speed_message')}
        </Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box sx={{display: 'flex', marginBottom: 2}}>
          <Typography variant="body2" sx={{marginRight: 3, color: 'text.primary'}}>
            {t('recipe_settings_phases_turbine_speed_action_label') + ' 1'}
          </Typography>
          <Switch
            checked={turbineSpeed === 1}
            onChange={(_, checked) => onChange(checked ? 1 : 0)}
          />
        </Box>
        <Box sx={{display: 'flex'}}>
          <Typography variant="body2" sx={{marginRight: 3, color: 'text.primary'}}>
            {t('recipe_settings_phases_turbine_speed_action_label') + ' 2'}
          </Typography>
          <Switch
            checked={turbineSpeed === 2}
            onChange={(_, checked) => onChange(checked ? 2 : 0)}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default TurbineSpeedRow;
