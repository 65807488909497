import {Box, InputBase, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Recipe from '../../../../models/entities/recipe';

type ProcedureStepProps = {
  recipeBuild: Recipe;
  setRecipeBuild: (setter: (previousValue: Recipe) => Recipe) => void;
};

function ProcedureStep(props: ProcedureStepProps) {
  const {recipeBuild, setRecipeBuild} = props;
  const {t} = useTranslation();

  return (
    <Box sx={{width: '65vw', padding: 2, paddingBottom: 6}}>
      <Typography variant="body2" sx={{color: 'text.primary', fontWeight: 'bold', marginBottom: 2}}>
        {t('recipe_settings_procedures_step_procedures')}
      </Typography>
      <InputBase
        sx={{width: '100%', color: 'text.primary', fontSize: '0.875rem'}}
        autoFocus
        multiline
        value={recipeBuild.procedure ?? ''}
        onChange={(event) =>
          setRecipeBuild((recipeBuild) => ({
            ...recipeBuild,
            procedure: event.target.value,
          }))
        }
      />
    </Box>
  );
}

export default ProcedureStep;
