import {Box, List, ListItem, SvgIcon, Typography} from '@mui/material';
import {ElementType, useState} from 'react';
import {ReactComponent as AcceptIcon} from '../../assets/icons/accept.svg';
import {ReactComponent as NumberOneIcon} from '../../assets/icons/number-one.svg';
import {ReactComponent as NumberThreeIcon} from '../../assets/icons/number-three.svg';
import {ReactComponent as NumberTwoIcon} from '../../assets/icons/number-two.svg';
import {RecipeScheduleStep, RecipeScheduleSteps} from './RecipeSchedule';
import {useTranslation} from 'react-i18next';

type MenuItemProps = {
  IconComponent: ElementType<any>;
  iconColor?: string;
  title: string;
  selected?: boolean;
  onClick?: () => void;
};

function MenuItem(props: MenuItemProps) {
  const {IconComponent, iconColor, title, selected, onClick} = props;

  const [isHovered, setIsHovered] = useState(false);

  function getIconColor() {
    if (iconColor != null) return iconColor;
    return selected ? 'custom.grey' : 'custom.lightGrey';
  }

  const renderText = isHovered || selected;

  return (
    <ListItem sx={{width: '20vw'}}>
      <Box
        sx={{
          flex: 1,
          height: '25px',
          textAlign: 'right',
          paddingRight: 2,
        }}></Box>
      <Box
        sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClick}>
        <Box sx={{width: '25px', height: '25px', textAlign: 'right'}}>
          <SvgIcon
            component={IconComponent}
            inheritViewBox
            sx={{fontSize: '25px', color: getIconColor()}}
          />
        </Box>
        <Box sx={{width: '10vw'}}>
          {renderText && (
            <Typography
              variant="body2"
              sx={{
                color: selected ? 'custom.grey' : 'custom.lightGrey',
                fontWeight: 'bold',
                marginLeft: 2,
              }}>
              {title}
            </Typography>
          )}
        </Box>
      </Box>
    </ListItem>
  );
}

type CreateRecipeScheduleMenuProps = {
  steps: RecipeScheduleSteps;
  setSteps: (setter: (previousValue: RecipeScheduleSteps) => RecipeScheduleSteps) => void;
  selectedStep: RecipeScheduleStep;
  setSelectedStep: (step: RecipeScheduleStep) => void;
  onFinish: () => void;
};

function CreateRecipeScheduleMenu(props: CreateRecipeScheduleMenuProps) {
  const {steps, setSteps, selectedStep, setSelectedStep, onFinish} = props;
  const {t} = useTranslation();

  const renderFinishButton =
    !steps.ovens.error && !steps.recipe.error && steps.time.visited && !steps.time.error;

  return (
    <List>
      {steps.ovens.visible && (
        <MenuItem
          IconComponent={NumberOneIcon}
          title={t('programming_ovens_step_label')}
          selected={selectedStep === 'ovens'}
          onClick={() => {
            setSelectedStep('ovens');
            setSteps((steps) => ({
              ...steps,
              ovens: {...steps.ovens, visited: true},
            }));
          }}
        />
      )}
      {steps.recipe.visible && (
        <MenuItem
          IconComponent={NumberTwoIcon}
          title={t('programming_recipe_step_label')}
          selected={selectedStep === 'recipe'}
          onClick={() => {
            setSelectedStep('recipe');
            setSteps((steps) => ({
              ...steps,
              recipe: {...steps.recipe, visited: true},
            }));
          }}
        />
      )}
      {steps.time.visible && (
        <MenuItem
          IconComponent={NumberThreeIcon}
          title={t('programming_time_step_label')}
          selected={selectedStep === 'time'}
          onClick={() => {
            setSelectedStep('time');
            setSteps((steps) => ({
              ...steps,
              time: {...steps.time, visited: true},
            }));
          }}
        />
      )}
      {renderFinishButton && (
        <MenuItem
          IconComponent={AcceptIcon}
          iconColor="primary.main"
          title={t('programming_finish_button_label')}
          selected
          onClick={onFinish}
        />
      )}
    </List>
  );
}

export default CreateRecipeScheduleMenu;
