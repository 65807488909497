import {List} from '@mui/material';
import {ElementType} from 'react';
import MenuItem from './MenuItem';

type MenuItemProps = {
  text: string;
  icon?: ElementType<any>;
  selected?: boolean;
  textColor?: string;
  iconColor?: string;
  selectedTextColor?: string;
  selectedIconColor?: string;
  onSelectItem?: () => void;
  disabled?: boolean;
  hidden?: boolean;
};

type MenuProps = {
  items: MenuItemProps[];
  direction?: 'row' | 'column';
  textColor?: string;
  iconColor?: string;
  selectedTextColor?: string;
  selectedIconColor?: string;
};

function Menu(props: MenuProps) {
  const {
    items,
    direction = 'column',
    textColor,
    iconColor,
    selectedTextColor,
    selectedIconColor,
  } = props;
  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: direction,
        overflow: 'auto',
      }}>
      {items.map((item, index) => (
        <MenuItem
          key={index}
          text={item.text}
          IconComponent={item.icon}
          textColor={textColor ?? item.textColor}
          iconColor={iconColor ?? item.iconColor}
          selectedTextColor={selectedTextColor ?? item.selectedTextColor}
          selectedIconColor={selectedIconColor ?? item.selectedIconColor}
          selected={item.selected}
          onSelectItem={item.onSelectItem}
          disabled={item.disabled}
          hidden={item.hidden}
        />
      ))}
    </List>
  );
}

export default Menu;
