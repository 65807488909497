import {Box, InputBase, styled} from '@mui/material';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import {ReactComponent as SubtractIcon} from '../../assets/icons/subtract.svg';
import IconButton from './IconButton';

const InputBaseStyled = styled(InputBase)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input': {
    textAlign: 'center',
  },
}));

export type QuantitySpinnerProps = {
  value: number;
  maxValue?: number;
  minValue?: number;
  onIncrease?: () => void;
  onDecrease?: () => void;
};

function QuantitySpinner(props: QuantitySpinnerProps) {
  const {value, minValue = -Infinity, maxValue = Infinity, onIncrease, onDecrease} = props;

  return (
    <InputBaseStyled
      sx={{width: '80px', fontSize: '0.875rem', color: 'text.primary'}}
      value={value}
      startAdornment={
        value === minValue ? (
          <Box sx={{minWidth: '30px'}} />
        ) : (
          <IconButton IconComponent={SubtractIcon} iconSize={'16px'} onClick={onDecrease} />
        )
      }
      endAdornment={
        value === maxValue ? (
          <Box sx={{minWidth: '30px'}} />
        ) : (
          <IconButton IconComponent={AddIcon} iconSize={'16px'} onClick={onIncrease} />
        )
      }
    />
  );
}

export default QuantitySpinner;
