import {Paper, Typography} from '@mui/material';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import Icon from './Icon';
import SwitchInverted from './SwitchInverted';

type ActionBannerProps = {
  text: string;
  onClick?: () => void;
  textColor?: string;
  color?: string;
  actionType?: 'add' | 'switch';
  checked?: boolean;
  onSwitch?: (checked: boolean) => void;
};

function ActionBanner(props: ActionBannerProps) {
  const {
    text,
    onClick,
    textColor = 'custom.white',
    color = 'secondary.main',
    actionType = 'add',
    checked,
    onSwitch,
  } = props;
  return (
    <Paper
      elevation={4}
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        height: '36px',
        padding: 2,
        borderRadius: '10px',
        backgroundColor: color,
        cursor: 'pointer',
      }}
      onClick={onClick}>
      <Typography variant="body2" sx={{flex: 1, fontWeight: 'bold', color: textColor}}>
        {text}
      </Typography>
      {actionType === 'add' ? (
        <Icon IconComponent={AddIcon} color={textColor} size="20px" />
      ) : (
        <SwitchInverted checked={checked} onChange={(_, checked) => onSwitch?.(checked)} />
      )}
    </Paper>
  );
}

export default ActionBanner;
