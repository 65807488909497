import {InputBase, Paper, SvgIcon, Typography} from '@mui/material';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import Bakery from '../../models/entities/bakery';
import City from '../../models/entities/city';

export type SearchFieldProps = {
  searchText: string;
  setSearchText: (searchText: string) => void;
  selectedCity?: City;
  selectedBakery?: Bakery;
  onReturnToBakeries: () => void;
  hideSearchIcon?: boolean;
};

function SearchField(props: SearchFieldProps) {
  const {
    searchText,
    setSearchText,
    selectedCity,
    selectedBakery,
    onReturnToBakeries,
    hideSearchIcon,
  } = props;
  const {t} = useTranslation();

  return (
    <Paper
      elevation={4}
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '36px',
        marginBlock: 2,
        paddingInline: 2,
        borderRadius: '10px',
        backgroundColor: '#F4F4F4',
      }}>
      {selectedCity != null && (
        <Fragment>
          <Typography
            variant="body2"
            sx={{fontWeight: 'bold', cursor: 'pointer'}}
            onClick={onReturnToBakeries}>
            {selectedCity.name}
          </Typography>
          <Typography variant="body2" sx={{marginInline: '5px'}}>
            |
          </Typography>
        </Fragment>
      )}
      {selectedBakery != null ? (
        <Typography variant="body2" sx={{flex: 1, fontWeight: 300}}>
          {selectedBakery.name}
        </Typography>
      ) : (
        <InputBase
          sx={{flex: 1, height: '36px', fontSize: '0.875rem'}}
          placeholder={t('bakeries_search_field_placeholder')}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
        />
      )}
      {!hideSearchIcon && (
        <SvgIcon
          component={SearchIcon}
          inheritViewBox
          sx={{fontSize: '20px', color: 'custom.grey', cursor: 'pointer'}}
          onClick={onReturnToBakeries}
        />
      )}
    </Paper>
  );
}

export default SearchField;
