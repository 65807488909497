import RecipeType from '../../models/entities/recipe-type';
import {
  CreateRecipeTypeRequest,
  DeleteRecipeTypeParams,
  DeleteRecipeTypeRecipeParams,
  GetRecipeTypesQuery,
  UpdateRecipeTypeParams,
  UpdateRecipeTypeRequest,
} from '../../models/requests/recipe-type';
import {
  CreateRecipeTypeResponse,
  DeleteRecipeTypeRecipeResponse,
  DeleteRecipeTypeResponse,
  GetRecipeTypesResponse,
  UpdateRecipeTypeResponse,
} from '../../models/responses/recipe-type';
import {ApplicationError} from '../../utils/errors';
import {RecipeTypeService} from '../recipe-type';
import {data} from './data';

const mockRecipeTypeService: RecipeTypeService = {
  getRecipeTypes: function (args: {query?: GetRecipeTypesQuery}): Promise<GetRecipeTypesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const recipeTypes = data.recipeTypes
          .filter(
            (recipeType) =>
              query?.ovenModelId == null || recipeType.ovenModelId === query.ovenModelId,
          )
          .map((recipeType) => ({
            ...recipeType,
            recipes: data.recipes
              .filter((recipe) =>
                data.recipeRecipeTypes.some(
                  (recipeRecipeType) =>
                    recipeRecipeType.recipeId === recipe.id &&
                    recipeRecipeType.recipeTypeId === recipeType.id,
                ),
              )
              .map((recipe) => ({
                ...recipe,
                recipePhases: data.recipePhases
                  .filter((recipePhase) => recipePhase.recipeId === recipe.id)
                  .map((recipePhase) => ({...recipePhase})),
              })),
          }));

        resolve(recipeTypes);
      }, 500),
    );
  },
  createRecipeType: function (args: {
    request: CreateRecipeTypeRequest;
  }): Promise<CreateRecipeTypeResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {request} = args;

        const recipeType: RecipeType = {
          id: request.id,
          description: request.description,
          descriptionEn: request.descriptionEn,
          descriptionEs: request.descriptionEs,
          descriptionPt: request.descriptionPt,
          descriptionFr: request.descriptionFr,
          descriptionPl: request.descriptionPl,
          ovenModelId: request.ovenModelId,
          ovenSubModelId: request.ovenSubModelId,
          companyId: request.companyId,
          modifiedAt: new Date().toISOString(),
        };

        data.recipeTypes.push(recipeType);

        resolve(recipeType.id);
      }, 500),
    );
  },
  updateRecipeType: function (args: {
    params: UpdateRecipeTypeParams;
    request: UpdateRecipeTypeRequest;
  }): Promise<UpdateRecipeTypeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        const recipeType = data.recipeTypes.find(
          (recipeType) => recipeType.id === params.recipeTypeId,
        );

        if (recipeType == null) {
          reject(ApplicationError.notFound('Recipe type'));
          return;
        }

        recipeType.description = request.description;
        recipeType.descriptionEn = request.descriptionEn;
        recipeType.descriptionEs = request.descriptionEs;
        recipeType.descriptionPt = request.descriptionPt;
        recipeType.descriptionFr = request.descriptionFr;
        recipeType.descriptionPl = request.descriptionPl;

        resolve(params.recipeTypeId);
      }, 500),
    );
  },
  deleteRecipeType: function (args: {
    params: DeleteRecipeTypeParams;
  }): Promise<DeleteRecipeTypeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const recipeType = data.recipeTypes.find(
          (recipeType) => recipeType.id === params.recipeTypeId,
        );

        if (recipeType == null) {
          reject(ApplicationError.notFound('Recipe type'));
          return;
        }

        data.ovenPanelRecipeTypes = data.ovenPanelRecipeTypes.filter(
          (ovenPanelRecipeType) => ovenPanelRecipeType.recipeTypeId !== params.recipeTypeId,
        );

        data.recipeTypes = data.recipeTypes.filter(
          (recipeType) => recipeType.id !== params.recipeTypeId,
        );

        resolve(params.recipeTypeId);
      }, 500),
    );
  },
  deleteRecipeTypeRecipe: function (args: {
    params: DeleteRecipeTypeRecipeParams;
  }): Promise<DeleteRecipeTypeRecipeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const recipeRecipeType = data.recipeRecipeTypes.find(
          (recipeRecipeType) =>
            recipeRecipeType.recipeId === params.recipeId &&
            recipeRecipeType.recipeTypeId === params.recipeTypeId,
        );

        if (recipeRecipeType == null) {
          reject(ApplicationError.notFound("Recipe type's recipe"));
          return;
        }

        data.recipeRecipeTypes = data.recipeRecipeTypes.filter(
          (recipeRecipeType) =>
            recipeRecipeType.recipeId !== params.recipeId ||
            recipeRecipeType.recipeTypeId !== params.recipeTypeId,
        );

        resolve(params.recipeId);
      }, 500),
    );
  },
};

export default mockRecipeTypeService;
