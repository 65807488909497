import {Box, Typography} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import paths from '../../routes/paths';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import Tabs from '../common/Tabs';
import About from './About';
import LanguageSettings from './LanguageSettings';
import NotificationSettings from './NotificationSettings';

function getTabIndex(tab?: string) {
  switch (tab) {
    case 'notifications':
      return 1;
    case 'about':
      return 2;
    case 'language':
    default:
      return 0;
  }
}

function Settings() {
  const navigate = useNavigate();
  const {tab} = useParams();
  const {t} = useTranslation();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const tabs = [
    {value: 'language', label: t('profile_settings_tab_language_label')},
    {value: 'notifications', label: t('profile_settings_tab_notifications_label')},
    {value: 'about', label: t('profile_settings_tab_about_label')},
  ];
  const selectedTabIndex = getTabIndex(tab);
  const selectedTab = tabs[selectedTabIndex];

  useEffect(() => {
    setBreadcrumbs([
      {
        title: t('profile_breadcrumb'),
        onClick: () => navigate(paths.profile),
      },
      {
        title: t('profile_settings_breadcrumb'),
        onClick: () => navigate(`${paths.profileSettings}/language`),
      },
      {title: selectedTab.label},
    ]);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <Box sx={{width: '65vw'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '112px',
        }}>
        <Box>
          <Tabs
            hideIndicator
            values={tabs.map((tab) => tab.label)}
            selectedTabIndex={selectedTabIndex}
            onTabClick={(tabIndex) => navigate(`${paths.profileSettings}/${tabs[tabIndex].value}`)}
          />
        </Box>
        {selectedTabIndex === 1 && (
          <Typography variant="body2" sx={{fontWeight: 'bold', color: 'text.primary', padding: 2}}>
            {t('notification_settings_title')}
          </Typography>
        )}
      </Box>
      {selectedTabIndex === 0 && <LanguageSettings />}
      {selectedTabIndex === 1 && <NotificationSettings />}
      {selectedTabIndex === 2 && <About />}
    </Box>
  );
}

export default Settings;
