import {
  GetOvenChambersQuery,
  UpdateOvenChamberParams,
  UpdateOvenChamberRequest,
} from '../models/requests/oven-chamber';
import {GetOvenChambersResponse, UpdateOvenChamberResponse} from '../models/responses/oven-chamber';
import baseService from './base';
import endpoints from './endpoints';

export type OvenChamberService = {
  getOvenChambers: (args: {query: GetOvenChambersQuery}) => Promise<GetOvenChambersResponse>;
  updateOvenChamber: (args: {
    params: UpdateOvenChamberParams;
    request: UpdateOvenChamberRequest;
  }) => Promise<UpdateOvenChamberResponse>;
};

const ovenChamberService: OvenChamberService = {
  getOvenChambers: async function (args: {
    query: GetOvenChambersQuery;
  }): Promise<GetOvenChambersResponse> {
    const {query} = args;

    const result = await baseService.get<GetOvenChambersResponse>(endpoints.ovenChambers, query);

    return result;
  },
  updateOvenChamber: async function (args: {
    params: UpdateOvenChamberParams;
    request: UpdateOvenChamberRequest;
  }): Promise<UpdateOvenChamberResponse> {
    const {params, request} = args;

    const result = await baseService.patch<UpdateOvenChamberResponse, UpdateOvenChamberRequest>(
      `${endpoints.ovenChambers}/${params.ovenChamberId}`,
      request,
    );

    return result;
  },
};

export default ovenChamberService;
