import {create} from 'zustand';
import localStorageUtils from '../utils/local-storage';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      [key: string]: string;
    };
  }

  interface PaletteOptions {
    custom?: {
      [key: string]: string;
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7?: true;
    h8?: true;
    body1?: true;
    body2?: true;
    body3?: true;
    body4?: true;
  }
}

type Theme = 'light' | 'dark';

type ThemeStore = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const useThemeStore = create<ThemeStore>((set) => ({
  theme: localStorageUtils.getValue<Theme>('theme', 'light'),
  setTheme: (theme: Theme) => {
    localStorageUtils.setValue('theme', theme);
    set({theme});
  },
}));

export default useThemeStore;
