import {GetCountryParams} from '../models/requests/country';
import {GetCountriesResponse, GetCountryResponse} from '../models/responses/country';
import baseService from './base';
import endpoints from './endpoints';

export type CountryService = {
  getCountries: () => Promise<GetCountriesResponse>;
  getCountry: (args: {params: GetCountryParams}) => Promise<GetCountryResponse>;
};

const countryService: CountryService = {
  getCountries: async function (): Promise<GetCountriesResponse> {
    const result = await baseService.get<GetCountriesResponse>(endpoints.countries);

    return result;
  },
  getCountry: async function (args: {params: GetCountryParams}): Promise<GetCountryResponse> {
    const {params} = args;

    const result = await baseService.get<GetCountryResponse>(
      `${endpoints.countries}/${params.countryId}`,
    );

    return result;
  },
};

export default countryService;
