import {Box} from '@mui/material';
import Bakery from '../../models/entities/bakery';
import stringUtils from '../../utils/strings';
import GradientOverflow from '../common/GradientOverflow';
import SimpleList, {SimpleListOption} from '../common/SimpleList';
import {pageHeight} from '../navigation/Navbar';

export type BakeryListProps = {
  searchText: string;
  bakeries: Bakery[];
  onSelectBakery: (bakeryId: string) => void;
  bakeriesNotFoundMessage?: string;
};

function BakeryList(props: BakeryListProps) {
  const {searchText, bakeries, onSelectBakery, bakeriesNotFoundMessage} = props;

  function getListOptions(): SimpleListOption[] {
    return bakeries
      .filter(
        (bakery) =>
          stringUtils.unicodeStartsWith(bakery.name, searchText) ||
          stringUtils.unicodeIncludes(bakery.name, ` ${searchText}`),
      )
      .map((bakery) => ({id: bakery.id, primary: bakery.name}));
  }

  return (
    <Box sx={{height: `calc(${pageHeight} - 112px)`}}>
      <GradientOverflow hideScrollbar containerProps={{sx: {padding: 2}}}>
        <SimpleList
          disableSelectedStyle
          options={getListOptions()}
          onChangeSelectedOption={onSelectBakery}
          entriesNotFoundMessage={bakeriesNotFoundMessage}
        />
      </GradientOverflow>
    </Box>
  );
}

export default BakeryList;
