import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';

function AuthLayout() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}>
      <Outlet />
    </Box>
  );
}

export default AuthLayout;
