import Bakery from '../models/entities/bakery';
import User from '../models/entities/user';

const bakeryUtils = {
  filterStockBakeries: function (bakeries: Bakery[], user?: User | null) {
    return bakeries.filter((bakery) => bakery.id !== user?.companyId);
  },
};

export default bakeryUtils;
