type NullableArray<TValue> = TValue[] | null | undefined;

const arrayUtils = {
  count: function <TValue>(arr: TValue[], predicate: (item: TValue, index: number) => boolean) {
    let count = 0;
    arr.forEach((item, index) => {
      if (predicate(item, index)) {
        count++;
      }
    });
    return count;
  },
  isNullOrEmpty: function <TValue>(arr: NullableArray<TValue>) {
    return arr == null || arr.length === 0;
  },
  hasMultipleItems: function <TValue>(arr: NullableArray<TValue>) {
    return arr != null && arr.length > 1;
  },
};

export default arrayUtils;
