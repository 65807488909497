import {Box, InputBase, Paper, SvgIcon, Typography, TypographyProps} from '@mui/material';
import {Fragment} from 'react';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import Bakery from '../../models/entities/bakery';
import City from '../../models/entities/city';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/oven-chamber';
import OvenGroup from '../../models/entities/oven-group';
import OvenPanel from '../../models/entities/oven-panel';
import arrayUtils from '../../utils/arrays';
import {useTranslation} from 'react-i18next';

function Indicator(props: TypographyProps & {withSeparator?: boolean}) {
  const {withSeparator, ...rest} = props;
  return (
    <Fragment>
      <Typography {...rest} variant="body2">
        {props.children}
      </Typography>
      {withSeparator && (
        <Typography variant="body2" sx={{marginInline: '5px'}}>
          |
        </Typography>
      )}
    </Fragment>
  );
}

export type SearchFieldProps = {
  searchText: string;
  setSearchText: (searchText: string) => void;
  selectedCity?: City;
  selectedBakery?: Bakery;
  selectedOvenGroup?: OvenGroup;
  selectedOven?: Oven;
  selectedOvenChamber?: OvenChamber;
  selectedOvenPanel?: OvenPanel;
  onReturnToBakeries: () => void;
  onReturnToBakery: () => void;
  onReturnToOvenModel: () => void;
};

function SearchField(props: SearchFieldProps) {
  const {
    searchText,
    setSearchText,
    selectedCity,
    selectedBakery,
    selectedOvenGroup,
    selectedOven,
    selectedOvenChamber,
    selectedOvenPanel,
    onReturnToBakeries,
    onReturnToBakery,
    onReturnToOvenModel,
  } = props;

  const {t} = useTranslation();

  const renderSelectedCityIndicator = selectedCity != null;
  const renderSelectedBakeryIndicator = selectedBakery != null;
  const renderSelectedOvenGroupIndicator = selectedOvenGroup != null;
  const renderSelectedOvenIndexIndicator = selectedOvenGroup != null && selectedOven != null;
  const renderSelectedOvenIndicator = selectedOvenGroup == null && selectedOven != null;
  const renderSelectedOvenPanelIndexIndicator =
    selectedOvenGroup == null &&
    selectedOven != null &&
    (arrayUtils.hasMultipleItems(selectedOven.ovenChambers) ||
      arrayUtils.hasMultipleItems(selectedOven.ovenPanels)) &&
    selectedOvenPanel != null;

  return (
    <Paper
      elevation={4}
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '36px',
        marginBlock: 2,
        paddingInline: 2,
        borderRadius: '10px',
        backgroundColor: '#F4F4F4',
      }}>
      <Box sx={{flex: 1, display: 'flex', alignItems: 'center'}}>
        {renderSelectedCityIndicator && (
          <Indicator
            withSeparator
            sx={{fontWeight: 700, cursor: 'pointer'}}
            onClick={onReturnToBakeries}>
            {selectedCity.name}
          </Indicator>
        )}
        {renderSelectedBakeryIndicator && (
          <Indicator
            withSeparator={selectedOvenGroup != null || selectedOven != null}
            sx={{
              fontWeight: selectedOvenGroup != null || selectedOven != null ? 500 : 300,
              cursor: 'pointer',
            }}
            onClick={onReturnToBakery}>
            {selectedBakery.name}
          </Indicator>
        )}
        {renderSelectedOvenGroupIndicator && (
          <Fragment>
            <Indicator
              withSeparator={renderSelectedOvenIndexIndicator}
              sx={{fontWeight: 400, cursor: 'pointer'}}
              onClick={onReturnToOvenModel}>
              {selectedOvenGroup.description}
            </Indicator>
            {renderSelectedOvenIndexIndicator && (
              <Indicator sx={{fontWeight: 300}}>
                {(selectedOven.ovenGroupOrder ?? 1) === 1
                  ? t('statistics_base_oven_label')
                  : t('statistics_top_oven_label')}
              </Indicator>
            )}
          </Fragment>
        )}
        {renderSelectedOvenIndicator && (
          <Fragment>
            <Indicator
              withSeparator={renderSelectedOvenPanelIndexIndicator}
              sx={{fontWeight: 400, cursor: 'pointer'}}
              onClick={onReturnToOvenModel}>
              {selectedOven.description}
            </Indicator>
            {renderSelectedOvenPanelIndexIndicator && (
              <Indicator sx={{fontWeight: 300}}>
                {`${t('statistics_chamber_label')} ${
                  selectedOvenChamber?.ovenOrder ?? selectedOvenPanel.ovenOrder ?? 1
                }`}
              </Indicator>
            )}
          </Fragment>
        )}
        {selectedBakery == null && (
          <InputBase
            sx={{height: '36px', fontSize: '0.875rem'}}
            placeholder={t('bakeries_search_field_placeholder')}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
        )}
      </Box>
      {selectedBakery == null && (
        <SvgIcon
          component={SearchIcon}
          inheritViewBox
          sx={{fontSize: '20px', color: 'custom.grey', cursor: 'pointer'}}
          onClick={onReturnToBakeries}
        />
      )}
    </Paper>
  );
}

export default SearchField;
