import UserNotificationType from '../../models/entities/user-notification-type';
import {
  ChangeUserPasswordParams,
  ChangeUserPasswordRequest,
  GetUserNotificationTypesParams,
  GetUserParams,
  UpdateUserNotificationTypeParams,
  UpdateUserNotificationTypeRequest,
  UpdateUserParams,
  UpdateUserRequest,
} from '../../models/requests/user';
import {
  ChangeUserPasswordResponse,
  GetUserNotificationTypesResponse,
  GetUserResponse,
  UpdateUserNotificationTypeResponse,
  UpdateUserResponse,
} from '../../models/responses/user';
import cryptoUtils from '../../utils/crypto';
import {ApplicationError} from '../../utils/errors';
import {UserService} from '../user';
import {data} from './data';

const mockUserService: UserService = {
  getUser: function (args: {params: GetUserParams}): Promise<GetUserResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const user = data.users.find((user) => user.id === params.userId);

        if (user == null) {
          reject(ApplicationError.notFound('User'));
          return;
        }

        resolve({...user});
      }, 500),
    );
  },
  updateUser: function (args: {
    params: UpdateUserParams;
    request: UpdateUserRequest;
  }): Promise<UpdateUserResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        const user = data.users.find((user) => user.id === params.userId);

        if (user == null) {
          reject(ApplicationError.notFound('User'));
          return;
        }

        user.name = request.name;

        resolve(user.id);
      }, 500),
    );
  },
  changeUserPassword: function (args: {
    params: ChangeUserPasswordParams;
    request: ChangeUserPasswordRequest;
  }): Promise<ChangeUserPasswordResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const user = data.users.find((user) => user.id === params.userId);

        if (user == null) {
          reject(ApplicationError.notFound('User'));
          return;
        }

        resolve(user.id);
      }, 500),
    );
  },
  getUserNotificationTypes: function (args: {
    params: GetUserNotificationTypesParams;
  }): Promise<GetUserNotificationTypesResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const user = data.users.find((user) => user.id === params.userId);

        if (user == null) {
          reject(ApplicationError.notFound('User'));
          return;
        }

        const userNotificationTypes: UserNotificationType[] = data.notificationTypes.map(
          (notificationType) => {
            const userNotificationType = data.userNotificationTypes.find(
              (userNotificationType) =>
                userNotificationType.userId === params.userId &&
                userNotificationType.notificationTypeId === notificationType.id,
            );

            return {
              id: userNotificationType?.id ?? '',
              notify: userNotificationType?.notify ?? true,
              userId: user.id,
              notificationTypeId: notificationType.id,
              companyId: user.companyId,
            };
          },
        );

        resolve(userNotificationTypes);
      }, 500),
    );
  },
  updateUserNotificationType: function (args: {
    params: UpdateUserNotificationTypeParams;
    request: UpdateUserNotificationTypeRequest;
  }): Promise<UpdateUserNotificationTypeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        const user = data.users.find((user) => user.id === params.userId);

        if (user == null) {
          reject(ApplicationError.notFound('User'));
          return;
        }

        let userNotificationType = data.userNotificationTypes.find(
          (userNotificationType) =>
            userNotificationType.userId === params.userId &&
            userNotificationType.notificationTypeId === params.notificationTypeId,
        );

        if (userNotificationType != null) {
          userNotificationType.notify = request.notify;
        } else {
          userNotificationType = {
            id: cryptoUtils.uuid(),
            notify: request.notify,
            userId: user.id,
            notificationTypeId: params.notificationTypeId,
            companyId: user.companyId,
          };

          data.userNotificationTypes.push(userNotificationType);
        }

        resolve(params.notificationTypeId);
      }, 500),
    );
  },
};

export default mockUserService;
