import {Box} from '@mui/material';
import {ElementType, Fragment, MouseEvent} from 'react';
import {ReactComponent as NumberFiveIcon} from '../../assets/icons/number-five.svg';
import {ReactComponent as NumberFourIcon} from '../../assets/icons/number-four.svg';
import {ReactComponent as NumberOneIcon} from '../../assets/icons/number-one.svg';
import {ReactComponent as NumberThreeIcon} from '../../assets/icons/number-three.svg';
import {ReactComponent as NumberTwoIcon} from '../../assets/icons/number-two.svg';
import {ReactComponent as ProgrammingIcon} from '../../assets/icons/programming.svg';
import {ReactComponent as RecipesIcon} from '../../assets/icons/recipes.svg';
import {ReactComponent as StatisticsIcon} from '../../assets/icons/statistics.svg';
import {ReactComponent as StatusIcon} from '../../assets/icons/status.svg';
import IconButton from '../common/IconButton';

function getNumberIcon(index: number): ElementType<any> {
  switch (index) {
    case 2:
      return NumberTwoIcon;
    case 3:
      return NumberThreeIcon;
    case 4:
      return NumberFourIcon;
    case 5:
      return NumberFiveIcon;
    default:
      return NumberOneIcon;
  }
}

export enum OvenPanelMenuAction {
  Status,
  Recipes,
  Programming,
  Statistics,
}

export type OvenPanelMenuProps = {
  onClick?: (action: OvenPanelMenuAction) => void;
  hasMultiple?: boolean;
  ovenOrder?: number;
};

function OvenPanelMenu(props: OvenPanelMenuProps) {
  const {onClick, hasMultiple, ovenOrder} = props;

  function handleClick(event: MouseEvent<HTMLButtonElement>, action: OvenPanelMenuAction) {
    event.stopPropagation();
    onClick?.(action);
  }

  const renderNumberIcon = hasMultiple && ovenOrder != null;

  return (
    <Box sx={{display: 'flex', marginTop: 2}}>
      {renderNumberIcon && (
        <Fragment>
          <IconButton
            IconComponent={getNumberIcon(ovenOrder)}
            disabledColor="custom.lightGrey"
            disabled
          />
          <Box width="25px" />
        </Fragment>
      )}
      <IconButton
        IconComponent={StatusIcon}
        onClick={(event) => handleClick(event, OvenPanelMenuAction.Status)}
      />
      <Box width="25px" />
      <IconButton
        IconComponent={RecipesIcon}
        onClick={(event) => handleClick(event, OvenPanelMenuAction.Recipes)}
      />
      <Box width="25px" />
      <IconButton
        IconComponent={ProgrammingIcon}
        onClick={(event) => handleClick(event, OvenPanelMenuAction.Programming)}
      />
      <Box width="25px" />
      <IconButton
        IconComponent={StatisticsIcon}
        onClick={(event) => handleClick(event, OvenPanelMenuAction.Statistics)}
      />
    </Box>
  );
}

export default OvenPanelMenu;
