import {Box, ClickAwayListener, Collapse, List, ListItem, Typography} from '@mui/material';
import {Fragment, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import Oven from '../../models/entities/oven';
import OvenGroup from '../../models/entities/oven-group';
import {OvenModelId} from '../../models/entities/oven-model';
import dateUtils from '../../utils/dates';
import {Language} from '../../utils/types';
import GradientOverflow from '../common/GradientOverflow';
import IconButton from '../common/IconButton';
import OvenPanelMenu, {OvenPanelMenuAction} from './OvenPanelMenu';
import OvenChamber from '../../models/entities/oven-chamber';

type OvenGroupListItemProps = {
  ovenGroup: OvenGroup;
  onSelectOvenGroup: (ovenGroupId: string) => void;
  onActionClick: (
    ovenGroupId: string,
    ovenId: string,
    ovenPanelId: string,
    action: OvenPanelMenuAction,
  ) => void;
  selectedOvenGroupId: string;
  selectedOvenId: string;
  onDeleteOvenGroup: (ovenGroup: OvenGroup) => void;
  onDeleteOven: (oven: Oven) => void;
};

function OvenGroupListItem(props: OvenGroupListItemProps) {
  const {
    ovenGroup,
    onSelectOvenGroup,
    onActionClick,
    selectedOvenGroupId,
    selectedOvenId,
    onDeleteOvenGroup,
    onDeleteOven,
  } = props;
  const {t, i18n} = useTranslation();

  const selected = ovenGroup.id === selectedOvenGroupId;
  const selectedAnother = (selectedOvenGroupId || selectedOvenId) && !selected;

  const ovens = useMemo(
    () =>
      Array.from(ovenGroup.ovens ?? []).sort(
        (ovenA, ovenB) => (ovenA.ovenGroupOrder ?? 1) - (ovenB.ovenGroupOrder ?? 1),
      ),
    [ovenGroup],
  );

  const color = selected ? 'primary.main' : 'text.primary';

  return (
    <ClickAwayListener onClickAway={() => onSelectOvenGroup('')}>
      <ListItem sx={{margin: 0, padding: 0, opacity: selectedAnother ? 0.5 : 1}}>
        <Box sx={{paddingBlock: 2}}>
          {ovens.map((oven, index) => (
            <Fragment key={oven.id}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onSelectOvenGroup(ovenGroup.id);
                  }}>
                  <Box sx={{width: 'calc(40vw - 75px)', paddingInline: 2}}>
                    {index === 0 && (
                      <Typography variant="body2" sx={{fontWeight: 'bold', color}}>
                        {ovenGroup.description}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{color}}>
                      {`${t('bakery_ovens_oven_serial_number_label')} ${
                        oven.ovenGroupOrder ?? 1
                      }: ${oven.serialNumber}`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: 'calc(25vw - 75px)',
                      textAlign: 'end',
                      paddingInline: 2,
                    }}>
                    {!selected && index === 0 && (
                      <Typography variant="body2" sx={{color}}>
                        {ovenGroup.assignedAt != null
                          ? dateUtils.toLocaleDateString(
                              new Date(ovenGroup.assignedAt),
                              i18n.language as Language,
                            )
                          : ''}
                      </Typography>
                    )}
                    {selected && (
                      <Typography variant="body2" sx={{color}}>
                        {oven.assignedAt != null
                          ? dateUtils.toLocaleDateString(
                              new Date(oven.assignedAt),
                              i18n.language as Language,
                            )
                          : ''}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={{width: '15vw', textAlign: 'center', paddingInline: 2}}>
                  {!selected && index === 0 && (
                    <IconButton
                      IconComponent={DeleteIcon}
                      onClick={(event) => {
                        event.stopPropagation();
                        onDeleteOvenGroup(ovenGroup);
                      }}
                    />
                  )}
                  {selected && (
                    <IconButton
                      IconComponent={DeleteIcon}
                      onClick={(event) => {
                        event.stopPropagation();
                        onDeleteOven(oven);
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Collapse in={selected} unmountOnExit>
                <Box
                  sx={{
                    marginTop: 4,
                    marginBottom: index === ovens.length - 1 ? 2 : 4,
                    marginInline: 2,
                  }}>
                  {oven.ovenPanels?.map((ovenPanel) => (
                    <OvenPanelMenu
                      key={ovenPanel.id}
                      onClick={(action) =>
                        onActionClick?.(ovenGroup.id, oven.id, ovenPanel.id, action)
                      }
                    />
                  ))}
                </Box>
              </Collapse>
            </Fragment>
          ))}
        </Box>
      </ListItem>
    </ClickAwayListener>
  );
}

type ChamberOvenListItemProps = {
  oven: Oven;
  onSelectOven: (ovenId: string) => void;
  onActionClick: (
    ovenId: string,
    ovenChamberId: string,
    ovenPanelId: string,
    action: OvenPanelMenuAction,
  ) => void;
  selectedOvenGroupId: string;
  selectedOvenId: string;
  onDeleteOven: (oven: Oven) => void;
  onDeleteOvenChamber: (ovenChamber: OvenChamber) => void;
};

function ChamberOvenListItem(props: ChamberOvenListItemProps) {
  const {
    oven,
    onSelectOven,
    onActionClick,
    selectedOvenGroupId,
    selectedOvenId,
    onDeleteOven,
    onDeleteOvenChamber,
  } = props;
  const {t, i18n} = useTranslation();

  const selected = oven.id === selectedOvenId;
  const selectedAnother = (selectedOvenGroupId || selectedOvenId) && !selected;

  const ovenChambers = useMemo(
    () =>
      Array.from(oven.ovenChambers ?? []).sort(
        (ovenChamberA, ovenChamberB) =>
          (ovenChamberA.ovenOrder ?? 1) - (ovenChamberB.ovenOrder ?? 1),
      ),
    [oven],
  );

  const color = selected ? 'primary.main' : 'text.primary';

  return (
    <ClickAwayListener onClickAway={() => onSelectOven('')}>
      <ListItem sx={{margin: 0, padding: 0, opacity: selectedAnother ? 0.5 : 1}}>
        <Box sx={{paddingBlock: 2}}>
          {ovenChambers.map((ovenChamber, index) => (
            <Fragment key={ovenChamber.id}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onSelectOven(oven.id);
                  }}>
                  <Box
                    sx={{
                      width: 'calc(40vw - 75px)',
                      paddingInline: 2,
                    }}>
                    {index === 0 && (
                      <Typography variant="body2" sx={{fontWeight: 'bold', color}}>
                        {oven.description ?? oven.serialNumber}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{color}}>
                      {`${t('bakery_ovens_chamber_serial_number_label')} ${
                        ovenChamber.ovenOrder ?? 1
                      }: ${ovenChamber.serialNumber}`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: 'calc(25vw - 75px)',
                      textAlign: 'end',
                      paddingInline: 2,
                    }}>
                    {!selected && index === 0 && (
                      <Typography variant="body2" sx={{color}}>
                        {ovenChamber.assignedAt != null
                          ? dateUtils.toLocaleDateString(
                              new Date(ovenChamber.assignedAt),
                              i18n.language as Language,
                            )
                          : ''}
                      </Typography>
                    )}
                    {selected && (
                      <Typography variant="body2" sx={{color}}>
                        {ovenChamber.assignedAt != null
                          ? dateUtils.toLocaleDateString(
                              new Date(ovenChamber.assignedAt),
                              i18n.language as Language,
                            )
                          : ''}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '15vw',
                    textAlign: 'center',
                    paddingInline: 2,
                  }}>
                  {!selected && index === 0 && (
                    <IconButton
                      IconComponent={DeleteIcon}
                      onClick={(event) => {
                        event.stopPropagation();
                        onDeleteOven(oven);
                      }}
                    />
                  )}
                  {selected && (
                    <IconButton
                      IconComponent={DeleteIcon}
                      onClick={(event) => {
                        event.stopPropagation();
                        onDeleteOvenChamber(ovenChamber);
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Collapse in={selected} unmountOnExit>
                <Box
                  sx={{
                    marginTop: 4,
                    marginBottom: index === ovenChambers.length - 1 ? 2 : 4,
                    marginInline: 2,
                  }}>
                  {ovenChamber.ovenPanels?.map((ovenPanel) => (
                    <OvenPanelMenu
                      key={ovenPanel.id}
                      onClick={(action) =>
                        onActionClick?.(oven.id, ovenChamber.id, ovenPanel.id, action)
                      }
                    />
                  ))}
                </Box>
              </Collapse>
            </Fragment>
          ))}
        </Box>
      </ListItem>
    </ClickAwayListener>
  );
}

type OvenListItemProps = {
  oven: Oven;
  onSelectOven: (ovenId: string) => void;
  onActionClick: (ovenId: string, ovenPanelId: string, action: OvenPanelMenuAction) => void;
  selectedOvenGroupId: string;
  selectedOvenId: string;
  onDeleteOven: (oven: Oven) => void;
};

function OvenListItem(props: OvenListItemProps) {
  const {oven, onSelectOven, onActionClick, selectedOvenGroupId, selectedOvenId, onDeleteOven} =
    props;
  const {t, i18n} = useTranslation();

  const selected = oven.id === selectedOvenId;
  const selectedAnother = (selectedOvenGroupId || selectedOvenId) && !selected;

  const ovenPanels = useMemo(
    () =>
      Array.from(oven.ovenPanels ?? []).sort(
        (ovenPanelA, ovenPanelB) => (ovenPanelA.ovenOrder ?? 1) - (ovenPanelB.ovenOrder ?? 1),
      ),
    [oven],
  );

  const color = selected ? 'primary.main' : 'text.primary';

  return (
    <ClickAwayListener onClickAway={() => onSelectOven('')}>
      <ListItem sx={{margin: 0, padding: 0, opacity: selectedAnother ? 0.5 : 1}}>
        <Box>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={(event) => {
                event.stopPropagation();
                onSelectOven(oven.id);
              }}>
              <Box sx={{width: 'calc(40vw - 75px)', padding: 2}}>
                <Typography variant="body2" sx={{fontWeight: 'bold', color}}>
                  {oven.description ?? oven.serialNumber}
                </Typography>
                <Typography variant="body2" sx={{color}}>
                  {`${t('bakery_ovens_serial_number_label')}: ${oven.serialNumber}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 'calc(25vw - 75px)',
                  textAlign: 'end',
                  padding: 2,
                }}>
                <Typography variant="body2" sx={{color}}>
                  {oven.assignedAt != null
                    ? dateUtils.toLocaleDateString(
                        new Date(oven.assignedAt),
                        i18n.language as Language,
                      )
                    : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: '15vw',
                textAlign: 'center',
                padding: 1,
              }}>
              <IconButton
                IconComponent={DeleteIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  onDeleteOven(oven);
                }}
              />
            </Box>
          </Box>
          <Collapse in={selected} unmountOnExit>
            <Box sx={{margin: 2}}>
              {ovenPanels.map((ovenPanel) => (
                <OvenPanelMenu
                  key={ovenPanel.id}
                  hasMultiple={ovenPanels.length > 1}
                  ovenOrder={ovenPanel.ovenOrder ?? 1}
                  onClick={(action) => onActionClick?.(oven.id, ovenPanel.id, action)}
                />
              ))}
            </Box>
          </Collapse>
        </Box>
      </ListItem>
    </ClickAwayListener>
  );
}

export type OvenTableProps = {
  ovenGroups: OvenGroup[];
  ovens: Oven[];
  selectedOvenGroupId: string;
  selectedOvenId: string;
  onSelectOvenGroup: (ovenGroupId: string) => void;
  onSelectOven: (ovenId: string) => void;
  onActionClick: (
    ovenId: string,
    ovenPanelId: string,
    action: OvenPanelMenuAction,
    ovenChamberId?: string,
    ovenGroupId?: string,
  ) => void;
  onDeleteOvenGroup: (ovenGroup: OvenGroup) => void;
  onDeleteOven: (oven: Oven) => void;
  onDeleteOvenChamber: (ovenChamber: OvenChamber) => void;
};

function OvenList(props: OvenTableProps) {
  const {
    ovenGroups,
    ovens,
    selectedOvenGroupId,
    selectedOvenId,
    onSelectOvenGroup,
    onSelectOven,
    onActionClick,
    onDeleteOvenGroup,
    onDeleteOven,
    onDeleteOvenChamber,
  } = props;

  return (
    <GradientOverflow hideScrollbar containerProps={{sx: {marginLeft: 2}}}>
      <List sx={{margin: 0, padding: 0}}>
        {ovens.map((oven) =>
          oven.ovenModelId === OvenModelId.Modulram ? (
            <ChamberOvenListItem
              key={oven.id}
              oven={oven}
              onSelectOven={onSelectOven}
              onActionClick={(ovenId, ovenChamberId, ovenPanelId, action) =>
                onActionClick(ovenId, ovenPanelId, action, ovenChamberId)
              }
              selectedOvenGroupId={selectedOvenGroupId}
              selectedOvenId={selectedOvenId}
              onDeleteOven={onDeleteOven}
              onDeleteOvenChamber={onDeleteOvenChamber}
            />
          ) : (
            <OvenListItem
              key={oven.id}
              oven={oven}
              onSelectOven={onSelectOven}
              onActionClick={(ovenId, ovenPanelId, action) =>
                onActionClick?.(ovenId, ovenPanelId, action)
              }
              selectedOvenGroupId={selectedOvenGroupId}
              selectedOvenId={selectedOvenId}
              onDeleteOven={onDeleteOven}
            />
          ),
        )}
        {ovenGroups.map((ovenGroup) => (
          <OvenGroupListItem
            key={ovenGroup.id}
            ovenGroup={ovenGroup}
            onSelectOvenGroup={onSelectOvenGroup}
            onActionClick={(ovenGroupId, ovenId, ovenPanelId, action) =>
              onActionClick?.(ovenId, ovenPanelId, action, undefined, ovenGroupId)
            }
            selectedOvenGroupId={selectedOvenGroupId}
            selectedOvenId={selectedOvenId}
            onDeleteOvenGroup={onDeleteOvenGroup}
            onDeleteOven={onDeleteOven}
          />
        ))}
      </List>
    </GradientOverflow>
  );
}

export default OvenList;
