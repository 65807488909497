import {alpha, Box, CircularProgress} from '@mui/material';

function LoadingBox() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}>
      <CircularProgress
        sx={{color: (theme) => alpha(theme.palette.primary.main, 0.2)}}
      />
    </Box>
  );
}

export default LoadingBox;
