import {Box, List, ListItem, Typography} from '@mui/material';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {OvenModelId} from '../../models/entities/oven-model';
import UserNotificationType from '../../models/entities/user-notification-type';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import notificationUtils from '../../utils/notifications';
import OvenModelList from '../bakeries/OvenModelList';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Switch from '../common/Switch';
import {pageHeight} from '../navigation/Navbar';

function NotificationSettings() {
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const [selectedOvenModelId, setSelectedOvenModelId] = useState(OvenModelId.Turboram);

  const {data: ovenModels = [], isLoading: isLoadingOvenModels} = useQuery({
    enabled: user != null,
    queryKey: ['ovenModels'],
    queryFn: () => services.ovenModel.getOvenModels(),
  });

  const {data: notificationTypes = [], isLoading: isLoadingNotificationTypes} = useQuery({
    enabled: user != null,
    queryKey: ['notificationTypes', {ovenModelId: selectedOvenModelId}],
    queryFn: () =>
      services.notificationType.getNotificationTypes({
        query: {ovenModelId: selectedOvenModelId},
      }),
  });

  const {data: userNotificationTypes = [], isLoading: isLoadingUserNotificationTypes} = useQuery({
    enabled: user != null,
    queryKey: ['userNotificationTypes'],
    queryFn: () =>
      services.user.getUserNotificationTypes({
        params: {userId: user?.id ?? ''},
      }),
  });

  const filteredUserNotificationTypes = useMemo(() => {
    const notificationTypeIds = new Set(
      notificationTypes.map((notificationType) => notificationType.id),
    );

    return userNotificationTypes.filter((userNotificationType) =>
      notificationTypeIds.has(userNotificationType.notificationTypeId),
    );
  }, [notificationTypes, userNotificationTypes]);

  const {mutate: updateUserNotificationType} = useMutation({
    mutationFn: services.user.updateUserNotificationType,
    onMutate: async ({request: updatedUserNotificationType}) => {
      await queryClient.cancelQueries('userNotificationTypes');
      const previousUserNotificationTypes = queryClient.getQueryData<UserNotificationType[]>([
        'userNotificationTypes',
      ]);
      queryClient.setQueryData<UserNotificationType[] | undefined>(
        ['userNotificationTypes'],
        (userNotificationTypes) =>
          userNotificationTypes?.map((userNotificationType) =>
            userNotificationType.notificationTypeId ===
            updatedUserNotificationType.notificationTypeId
              ? updatedUserNotificationType
              : userNotificationType,
          ),
      );
      return {previousUserNotificationTypes};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(['userNotificationTypes'], context?.previousUserNotificationTypes);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
    },
    onSettled: () => {
      queryClient.invalidateQueries('userNotificationTypes');
    },
  });

  function handleCheckUserNotificationType(
    userNotificationType: UserNotificationType,
    checked: boolean,
  ) {
    updateUserNotificationType({
      params: {
        userId: userNotificationType.userId,
        notificationTypeId: userNotificationType.notificationTypeId,
      },
      request: {
        ...userNotificationType,
        notify: checked,
      },
    });
  }

  const isLoading =
    isLoadingOvenModels || isLoadingNotificationTypes || isLoadingUserNotificationTypes;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '65vw',
        height: `calc(${pageHeight} - 112px)`,
      }}>
      <Box
        sx={{
          width: '150px',
          height: `calc(${pageHeight} - 136px)`,
          paddingBlock: 2,
        }}>
        <GradientOverflow>
          <OvenModelList
            ovenModels={
              ovenModels?.filter((ovenModel) => ovenModel.id === OvenModelId.Turboram) ?? []
            }
            selectedOvenModelId={selectedOvenModelId}
            onSelectOvenModel={setSelectedOvenModelId}
          />
        </GradientOverflow>
      </Box>
      <Box sx={{height: `calc(${pageHeight} - 180px)`, paddingLeft: 2}}>
        <GradientOverflow hideScrollbar>
          <List sx={{alignItems: 'center', margin: 0, padding: 0}}>
            {filteredUserNotificationTypes.map((userNotificationType) => (
              <ListItem key={userNotificationType.notificationTypeId} sx={{margin: 0, padding: 0}}>
                <Box sx={{width: 'calc(40vw - 75px)', padding: 2}}>
                  <Typography variant="body2" sx={{color: 'text.primary'}}>
                    {t(notificationUtils.getSettingsLabel(userNotificationType))}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: 'calc(25vw - 75px)',
                    textAlign: 'right',
                    padding: 2,
                  }}>
                  <Switch
                    checked={userNotificationType.notify}
                    onChange={(_, checked) =>
                      handleCheckUserNotificationType(userNotificationType, checked)
                    }
                  />
                </Box>
              </ListItem>
            ))}
          </List>
        </GradientOverflow>
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default NotificationSettings;
