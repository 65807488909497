import {Box, List, ListItem, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import GradientOverflow from '../common/GradientOverflow';
import IconButton from '../common/IconButton';
import Span from '../common/Span';

export type ScheduleListItemProps = {
  scheduleId: string;
  startTime: string;
  endTime: string;
  recipeId?: string | null;
  recipeName?: string | null;
  cleaningId?: number | null;
  cleaningDescription?: string | null;
  ovenPanelId: string;
  ovenPanelIndex?: number | null;
  ovenChamberId?: string | null;
  ovenChamberIndex?: number | null;
  ovenId?: string | null;
  ovenDescription?: string | null;
  ovenIndex?: number | null;
  ovenGroupId?: string | null;
  ovenGroupDescription?: string | null;
};

export function ScheduleListItem(
  props: ScheduleListItemProps & {
    onSelectItem: () => void;
    onDeleteItem: () => void;
  },
) {
  const {t} = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  function getDescriptionText() {
    if (props.recipeId != null) {
      return props.recipeName ?? '';
    }
    return `${t('programming_cleaning_label')} ${props.cleaningDescription}`;
  }

  function getLocationText() {
    let location = props.ovenGroupDescription ?? props.ovenDescription ?? '';
    if (props.ovenIndex != null) {
      location += ` ${t(
        props.ovenIndex === 1 ? 'programming_bottom_oven_label' : 'programming_top_oven_label',
      )}`;
    } else if (props.ovenChamberIndex != null) {
      location += ` ${t('programming_chamber_label')} ${props.ovenChamberIndex}`;
    }
    return location;
  }

  function getColor() {
    if (isHovered) {
      return 'primary.main';
    }
    if (props.recipeId != null) {
      return 'text.primary';
    }
    return 'custom.blue';
  }

  return (
    <ListItem sx={{margin: 0, padding: 0}}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box
          sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={props.onSelectItem}>
          <Box sx={{width: '50vw', padding: 2}}>
            <Typography variant="body2">
              <Span sx={{fontWeight: 'bold', color: getColor()}}>{getDescriptionText()}</Span>
              <Span
                sx={{
                  fontWeight: 'bold',
                  color: getColor(),
                  marginInline: '5px',
                }}>
                |
              </Span>
              <Span sx={{color: isHovered ? 'primary.main' : 'text.primary'}}>
                {getLocationText()}
              </Span>
            </Typography>
          </Box>
          <Box sx={{width: '15vw', textAlign: 'right', padding: 2}}>
            <Typography sx={{color: isHovered ? 'primary.main' : 'text.primary'}} variant="body2">
              {`${props.startTime} - ${props.endTime}`}
            </Typography>
          </Box>
        </Box>
        <Box sx={{width: '15vw', textAlign: 'center', padding: 1}}>
          <IconButton IconComponent={DeclineIcon} onClick={props.onDeleteItem} />
        </Box>
      </Box>
    </ListItem>
  );
}

export type ScheduleListProps = {
  items: ScheduleListItemProps[];
  onSelectItem: (scheduleId: string) => void;
  onDeleteItem: (scheduleId: string, ovenPanelId: string) => void;
  itemsNotFoundLabel?: string;
};

function ScheduleList(props: ScheduleListProps) {
  return (
    <GradientOverflow hideScrollbar>
      <List sx={{margin: 0, padding: 0}}>
        {props.items.length > 0 ? (
          props.items.map((item) => (
            <ScheduleListItem
              key={`${item.scheduleId}-${item.ovenPanelId}`}
              {...item}
              onSelectItem={() => props.onSelectItem(item.scheduleId)}
              onDeleteItem={() => props.onDeleteItem(item.scheduleId, item.ovenPanelId)}
            />
          ))
        ) : (
          <ListItem sx={{margin: 0, padding: 2}}>
            <Typography variant="body2" sx={{color: 'text.primary'}}>
              {props.itemsNotFoundLabel}
            </Typography>
          </ListItem>
        )}
      </List>
    </GradientOverflow>
  );
}

export default ScheduleList;
