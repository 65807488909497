import {AppBar, Box, IconButton, Toolbar, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as LogoIcon} from '../../assets/icons/logo.svg';
import {ReactComponent as DotsMenuIcon} from '../../assets/icons/menu.svg';
import paths from '../../routes/paths';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import menuUtils from '../../utils/menus';
import stringUtils from '../../utils/strings';
import Icon from '../common/Icon';
import DrawerMenu from './DrawerMenu';

export const navbarHeight = '80px';
export const pageHeight = `calc(100vh - ${navbarHeight})`;

function HomeButton() {
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <IconButton
      disableRipple
      size="large"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => navigate(paths.home)}>
      <Icon IconComponent={LogoIcon} color={isHovered ? 'secondary' : 'primary'} size="35px" />
    </IconButton>
  );
}

export function AuthNavbar() {
  return (
    <Box sx={{height: navbarHeight}}>
      <AppBar
        position="fixed"
        sx={{
          height: navbarHeight,
          boxShadow: 'none',
          backgroundColor: 'background.default',
        }}>
        <Toolbar sx={{height: navbarHeight}}>
          <HomeButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbsStore((state) => state.breadcrumbs);

  const [hoveredIndex, setHoveredIndex] = useState(-1);

  return (
    <Box sx={{display: 'flex', height: '20px'}}>
      {breadcrumbs.map(({title, onClick}, index) => (
        <Typography
          key={index}
          variant="body2"
          sx={{
            color:
              hoveredIndex < breadcrumbs.length - 1
                ? index <= hoveredIndex
                  ? 'primary.main'
                  : 'text.secondary'
                : 'text.secondary',
            cursor: hoveredIndex < breadcrumbs.length - 1 ? 'pointer' : undefined,
          }}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(-1)}
          onClick={index < breadcrumbs.length - 1 ? onClick : undefined}>
          {`${stringUtils.removeWhiteSpaces(title).toLowerCase()}${
            index < breadcrumbs.length - 1 ? '/' : ''
          }`}
        </Typography>
      ))}
    </Box>
  );
}

export function DashboardNavbar() {
  const location = useLocation();
  const {t} = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const menu = menuUtils.getMenuFromPath(location.pathname);
  const menuLabel = menuUtils.getMenuLabel(menu);
  const MenuIcon = menuUtils.getMenuIcon(menu);

  useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname]);

  return (
    <Box sx={{height: navbarHeight}}>
      <AppBar
        position="fixed"
        sx={{
          height: navbarHeight,
          boxShadow: 'none',
          backgroundColor: 'background.default',
        }}>
        <Toolbar sx={{height: navbarHeight}}>
          <IconButton
            sx={{marginRight: 2}}
            disableRipple
            size="large"
            onClick={() => setDrawerOpen(true)}>
            <Icon IconComponent={DotsMenuIcon} size="30px" color="custom.lightGrey" />
          </IconButton>
          {MenuIcon && <Icon IconComponent={MenuIcon} size="30px" />}
          {menuLabel.length && (
            <Box
              sx={{
                marginLeft: 2,
                marginBottom: '5px',
                alignSelf: 'flex-end',
              }}>
              <Typography variant="h6" color="primary" sx={{fontWeight: 'bold'}}>
                {t(menuLabel)}
              </Typography>
              <Breadcrumbs />
            </Box>
          )}
          <DrawerMenu open={drawerOpen} onClose={() => setDrawerOpen(false)} />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
