import {
  ChangeOvenPanelStatusParams,
  ChangeOvenPanelStatusRequest,
  DeleteOvenPanelScheduleParams,
  GetOvenPanelStatisticsParams,
  GetOvenPanelStatisticsQuery,
} from '../models/requests/oven-panel';
import {
  ChangeOvenPanelStatusResponse,
  DeleteOvenPanelScheduleResponse,
  GetOvenPanelStatisticsResponse,
} from '../models/responses/oven-panel';
import baseService from './base';
import endpoints from './endpoints';

export type OvenPanelService = {
  changeOvenPanelStatus: (args: {
    params: ChangeOvenPanelStatusParams;
    request: ChangeOvenPanelStatusRequest;
  }) => Promise<ChangeOvenPanelStatusResponse>;
  deleteOvenPanelSchedule: (args: {
    params: DeleteOvenPanelScheduleParams;
  }) => Promise<DeleteOvenPanelScheduleResponse>;
  getOvenPanelStatistics: (args: {
    params: GetOvenPanelStatisticsParams;
    query: GetOvenPanelStatisticsQuery;
  }) => Promise<GetOvenPanelStatisticsResponse>;
};

const ovenPanelService: OvenPanelService = {
  changeOvenPanelStatus: async function (args: {
    params: ChangeOvenPanelStatusParams;
    request: ChangeOvenPanelStatusRequest;
  }): Promise<ChangeOvenPanelStatusResponse> {
    const {params, request} = args;

    const result = await baseService.patch<
      ChangeOvenPanelStatusResponse,
      ChangeOvenPanelStatusRequest
    >(`${endpoints.ovenPanels}/${params.ovenPanelId}/status`, request);

    return result;
  },
  deleteOvenPanelSchedule: async function (args: {
    params: DeleteOvenPanelScheduleParams;
  }): Promise<DeleteOvenPanelScheduleResponse> {
    const {params} = args;

    const result = await baseService.delete<DeleteOvenPanelScheduleResponse>(
      `${endpoints.ovenPanels}/${params.ovenPanelId}/schedules/${params.scheduleId}`,
    );

    return result;
  },
  getOvenPanelStatistics: async function (args: {
    params: GetOvenPanelStatisticsParams;
    query: GetOvenPanelStatisticsQuery;
  }): Promise<GetOvenPanelStatisticsResponse> {
    const {params, query} = args;

    const result = await baseService.get<
      GetOvenPanelStatisticsResponse,
      GetOvenPanelStatisticsQuery
    >(`${endpoints.ovenPanels}/${params.ovenPanelId}/statistics`, query);

    return result;
  },
};

export default ovenPanelService;
