import {addDays} from 'date-fns';
import {
  ChangeOvenPanelStatusParams,
  ChangeOvenPanelStatusRequest,
  DeleteOvenPanelScheduleParams,
  GetOvenPanelStatisticsParams,
  GetOvenPanelStatisticsQuery,
} from '../../models/requests/oven-panel';
import {
  ChangeOvenPanelStatusResponse,
  DeleteOvenPanelScheduleResponse,
  GetOvenPanelStatisticsResponse,
} from '../../models/responses/oven-panel';
import {ApplicationError} from '../../utils/errors';
import {OvenPanelService} from '../oven-panel';
import {data} from './data';

const mockOvenPanelService: OvenPanelService = {
  changeOvenPanelStatus: function (args: {
    params: ChangeOvenPanelStatusParams;
    request: ChangeOvenPanelStatusRequest;
  }): Promise<ChangeOvenPanelStatusResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const ovenPanel = data.ovenPanels.find((ovenPanel) => ovenPanel.id === params.ovenPanelId);

        if (ovenPanel == null) {
          reject(ApplicationError.notFound('Oven panel'));
        }

        resolve(params.ovenPanelId);
      }, 500),
    );
  },
  deleteOvenPanelSchedule: function (args: {
    params: DeleteOvenPanelScheduleParams;
  }): Promise<DeleteOvenPanelScheduleResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const ovenPanelSchedule = data.ovenPanelSchedules.find(
          (ovenPanelSchedule) =>
            ovenPanelSchedule.ovenPanelId === params.ovenPanelId &&
            ovenPanelSchedule.scheduleId === params.scheduleId,
        );

        if (ovenPanelSchedule == null) {
          reject(ApplicationError.notFound("Oven panel's schedule"));
        }

        data.ovenPanelSchedules = data.ovenPanelSchedules.filter(
          (ovenPanelSchedule) =>
            ovenPanelSchedule.ovenPanelId !== params.ovenPanelId ||
            ovenPanelSchedule.scheduleId !== params.scheduleId,
        );

        resolve(params.ovenPanelId);
      }, 500),
    );
  },
  getOvenPanelStatistics: function (args: {
    params: GetOvenPanelStatisticsParams;
    query: GetOvenPanelStatisticsQuery;
  }): Promise<GetOvenPanelStatisticsResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const ovenPanel = data.ovenPanels.find((ovenPanel) => ovenPanel.id === params.ovenPanelId);

        if (ovenPanel == null) {
          reject(ApplicationError.notFound('Oven panel'));
        }

        const statistics: GetOvenPanelStatisticsResponse = [
          {
            date: addDays(new Date(), -6).toISOString(),
            onTimeInMinutes: 480,
            offTimeInMinutes: 960,
            cookingTimeInMinutes: 300,
          },
          {
            date: addDays(new Date(), -5).toISOString(),
            onTimeInMinutes: 520,
            offTimeInMinutes: 920,
            cookingTimeInMinutes: 350,
          },
          {
            date: addDays(new Date(), -4).toISOString(),
            onTimeInMinutes: 410,
            offTimeInMinutes: 1030,
            cookingTimeInMinutes: 200,
          },
          {
            date: addDays(new Date(), -3).toISOString(),
            onTimeInMinutes: 485,
            offTimeInMinutes: 955,
            cookingTimeInMinutes: 300,
          },
          {
            date: addDays(new Date(), -2).toISOString(),
            onTimeInMinutes: 510,
            offTimeInMinutes: 930,
            cookingTimeInMinutes: 345,
          },
          {
            date: addDays(new Date(), -1).toISOString(),
            onTimeInMinutes: 500,
            offTimeInMinutes: 940,
            cookingTimeInMinutes: 380,
          },
          {
            date: new Date().toISOString(),
            onTimeInMinutes: 260,
            offTimeInMinutes: 540,
            cookingTimeInMinutes: 180,
          },
        ];

        resolve(statistics);
      }, 500),
    );
  },
};

export default mockOvenPanelService;
