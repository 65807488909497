import {
  GetOvenParams,
  GetOvenQuery,
  GetOvensQuery,
  UpdateOvenParams,
  UpdateOvenRequest,
} from '../models/requests/oven';
import {GetOvenResponse, GetOvensResponse, UpdateOvenResponse} from '../models/responses/oven';
import baseService from './base';
import endpoints from './endpoints';

export type OvenService = {
  getOvens: (args: {query?: GetOvensQuery}) => Promise<GetOvensResponse>;
  getOven: (args: {params: GetOvenParams; query?: GetOvenQuery}) => Promise<GetOvenResponse>;
  updateOven: (args: {
    params: UpdateOvenParams;
    request: UpdateOvenRequest;
  }) => Promise<UpdateOvenResponse>;
};

const ovenService: OvenService = {
  getOvens: function (args: {query?: GetOvensQuery}): Promise<GetOvensResponse> {
    const {query} = args;

    const result = baseService.get<GetOvensResponse>(endpoints.ovens, query);

    return result;
  },
  getOven: function (args: {
    params: GetOvenParams;
    query?: GetOvenQuery;
  }): Promise<GetOvenResponse> {
    const {params, query} = args;

    const result = baseService.get<GetOvenResponse>(`${endpoints.ovens}/${params.ovenId}`, query);

    return result;
  },
  updateOven: function (args: {
    params: UpdateOvenParams;
    request: UpdateOvenRequest;
  }): Promise<UpdateOvenResponse> {
    const {params, request} = args;

    const result = baseService.patch<UpdateOvenResponse>(
      `${endpoints.ovens}/${params.ovenId}`,
      request,
    );

    return result;
  },
};

export default ovenService;
