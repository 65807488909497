import {
  CreateRecipeRequest,
  DeleteRecipeParams,
  GetRecipeParams,
  GetRecipeQuery,
  GetRecipesQuery,
  UpdateRecipeParams,
  UpdateRecipeRequest,
} from '../models/requests/recipe';
import {
  CreateRecipeResponse,
  DeleteRecipeResponse,
  GetRecipeResponse,
  GetRecipesResponse,
  UpdateRecipeResponse,
} from '../models/responses/recipe';
import baseService from './base';
import endpoints from './endpoints';

export type RecipeService = {
  getRecipes: (args: {query?: GetRecipesQuery}) => Promise<GetRecipesResponse>;
  getRecipe: (args: {params: GetRecipeParams; query: GetRecipeQuery}) => Promise<GetRecipeResponse>;
  createRecipe: (args: {request: CreateRecipeRequest}) => Promise<CreateRecipeResponse>;
  updateRecipe: (args: {
    params: UpdateRecipeParams;
    request: UpdateRecipeRequest;
  }) => Promise<UpdateRecipeResponse>;
  deleteRecipe: (args: {params: DeleteRecipeParams}) => Promise<DeleteRecipeResponse>;
};

const recipeService: RecipeService = {
  getRecipes: async function (args: {query?: GetRecipesQuery}): Promise<GetRecipesResponse> {
    const {query} = args;

    const result = await baseService.get<GetRecipesResponse, GetRecipesQuery>(
      endpoints.recipes,
      query,
    );

    return result;
  },
  getRecipe: async function (args: {
    params: GetRecipeParams;
    query?: GetRecipeQuery;
  }): Promise<GetRecipeResponse> {
    const {params, query} = args;

    const result = await baseService.get<GetRecipeResponse, GetRecipeQuery>(
      `${endpoints.recipes}/${params.recipeId}`,
      query,
    );

    return result;
  },
  createRecipe: async function (args: {
    request: CreateRecipeRequest;
  }): Promise<CreateRecipeResponse> {
    const {request} = args;

    const result = await baseService.post<CreateRecipeResponse, CreateRecipeRequest>(
      endpoints.recipes,
      request,
    );

    return result;
  },
  updateRecipe: async function (args: {
    params: UpdateRecipeParams;
    request: UpdateRecipeRequest;
  }): Promise<UpdateRecipeResponse> {
    const {params, request} = args;

    const result = await baseService.patch<UpdateRecipeResponse, UpdateRecipeRequest>(
      `${endpoints.recipes}/${params.recipeId}`,
      request,
    );

    return result;
  },
  deleteRecipe: async function (args: {params: DeleteRecipeParams}): Promise<DeleteRecipeResponse> {
    const {params} = args;

    const result = await baseService.delete<DeleteRecipeResponse>(
      `${endpoints.recipes}/${params.recipeId}`,
    );

    return result;
  },
};

export default recipeService;
