import {addSeconds, format, parse} from 'date-fns';
import Schedule from '../models/entities/schedule';
import arrayUtils from './arrays';

const scheduleUtils = {
  getStartTime: function (schedule: Schedule) {
    if (!arrayUtils.isNullOrEmpty(schedule.recurrences)) {
      return schedule.recurrences![0].startTime.slice(0, 5);
    }
    return '00:00';
  },
  getEndTime: function (schedule: Schedule) {
    const startTime = this.getStartTime(schedule);
    let duration = 0;
    if (schedule.recipe != null) {
      duration =
        schedule.recipe.recipePhases?.reduce(
          (duration, recipePhase) => duration + recipePhase.duration,
          0,
        ) ?? 0;
    } else if (schedule.cleaning != null) {
      duration = schedule.cleaning.duration;
    }
    return format(addSeconds(parse(startTime, 'HH:mm', new Date()), duration), 'HH:mm');
  },
};

export default scheduleUtils;
