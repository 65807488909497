import User, {UserRole} from '../../models/entities/user';
import {
  LoginRequest,
  RecoverPasswordRequest,
  ResetPasswordRequest,
  SignupRequest,
} from '../../models/requests/auth';
import {
  LoginResponse,
  RecoverPasswordResponse,
  ResetPasswordResponse,
  SignupResponse,
} from '../../models/responses/auth';
import cryptoUtils from '../../utils/crypto';
import {ApplicationError} from '../../utils/errors';
import {AuthService} from '../auth';
import {COMPANY_ID, data} from './data';

const mockAuthService: AuthService = {
  signup: function (args: {request: SignupRequest}): Promise<SignupResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {request} = args;

        const user: User = {
          id: cryptoUtils.uuid(),
          name: request.name,
          email: request.email,
          role: UserRole.Admin,
          companyId: COMPANY_ID,
        };

        data.users.push(user);

        resolve({token: user.id, user: {...user}});
      }, 500),
    );
  },
  login: function (args: {request: LoginRequest}): Promise<LoginResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {request} = args;

        const user = data.users.find((user) => user.email === request.email);

        if (user == null) {
          reject(ApplicationError.invalidEmailOrPassword());
          return;
        }

        resolve({token: user.id, user: {...user}});
      }, 500),
    );
  },
  recoverPassword: function (args: {
    request: RecoverPasswordRequest;
  }): Promise<RecoverPasswordResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {request} = args;

        const user = data.users.find((user) => user.email === request.email);

        if (user == null) {
          reject(ApplicationError.invalidEmailOrPassword());
          return;
        }

        resolve(request.email);
      }, 500),
    );
  },
  resetPassword: function (args: {request: ResetPasswordRequest}): Promise<ResetPasswordResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {request} = args;

        const user = data.users.find((user) => user.email === request.email);

        if (user == null) {
          reject(ApplicationError.invalidEmailOrRecoveryCode());
          return;
        }

        resolve({token: user.id, user: {...user}});
      }, 500),
    );
  },
};

export default mockAuthService;
