import {List, ListItem, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import Recipe from '../../models/entities/recipe';
import paths from '../../routes/paths';
import stringUtils from '../../utils/strings';
import GradientOverflow from '../common/GradientOverflow';
import RecipesListItem, {RecipeListItemAction} from './RecipesListItem';

export type RecipeListProps = {
  searchText: string;
  selectedOvenModelId?: number;
  selectedRecipeTypeId?: string;
  recipes: Recipe[];
  selectedRecipeId: string;
  setSelectedRecipeId: (recipeId: string) => void;
  onDeleteRecipe: (recipeId: string) => void;
  onDeleteRecipeTypeRecipe(recipeTypeId: string, recipeId: string): void;
};

function RecipeList(props: RecipeListProps) {
  const {
    searchText,
    selectedOvenModelId,
    selectedRecipeTypeId,
    recipes,
    selectedRecipeId,
    setSelectedRecipeId,
    onDeleteRecipe,
    onDeleteRecipeTypeRecipe,
  } = props;

  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  const {t} = useTranslation();

  function filterRecipes() {
    return recipes.filter(
      (recipe) =>
        (stringUtils.unicodeStartsWith(recipe.name, searchText) ||
          stringUtils.unicodeIncludes(recipe.name, ` ${searchText}`)) &&
        (stringUtils.isNullOrWhiteSpace(selectedRecipeTypeId) ||
          selectedRecipeTypeId === 'all' ||
          recipe.recipeTypes?.some((recipeType) => recipeType.id === selectedRecipeTypeId)),
    );
  }

  function handleRecipeAction(recipeId: string, action: RecipeListItemAction) {
    switch (action) {
      case 'update':
        navigate(
          `${paths.recipes}/${selectedOvenModelId}/${selectedRecipeTypeId}/update/${recipeId}`,
        );
        break;
      case 'locate':
        navigate(
          `${paths.recipes}/${selectedOvenModelId}/${selectedRecipeTypeId}/locate/${recipeId}`,
        );
        break;
      case 'delete':
        splash({
          title: t('splash_screen_delete_recipe_title'),
          caption: t('splash_screen_delete_recipe_caption'),
          subCaption: t('splash_screen_delete_recipe_sub_caption'),
          acceptAction: () => onDeleteRecipe(recipeId),
        });
        break;
      case 'deleteRecipeTypeRecipe':
        if (
          !stringUtils.isNullOrWhiteSpace(selectedRecipeTypeId) &&
          selectedRecipeTypeId !== 'all'
        ) {
          onDeleteRecipeTypeRecipe(selectedRecipeTypeId!, recipeId);
        }
        break;
    }
  }

  const filteredRecipes = filterRecipes();

  return (
    <GradientOverflow hideScrollbar>
      <List sx={{margin: 0, padding: 0}}>
        {filteredRecipes.length === 0 && (
          <ListItem sx={{margin: 0, padding: 0}}>
            <Typography variant="body2" sx={{color: 'text.primary', padding: 2}}>
              {t('recipes_recipes_not_found_label')}
            </Typography>
          </ListItem>
        )}
        {filteredRecipes.map((recipe) => (
          <RecipesListItem
            key={recipe.id}
            recipe={recipe}
            selected={recipe.id === selectedRecipeId}
            onClick={() => setSelectedRecipeId(recipe.id === selectedRecipeId ? '' : recipe.id)}
            onActionClick={(action) => handleRecipeAction(recipe.id, action)}
            onClickAway={() => setSelectedRecipeId('')}
            canRemoveFromRecipeType={
              !stringUtils.isNullOrWhiteSpace(selectedRecipeTypeId) &&
              selectedRecipeTypeId !== 'all'
            }
          />
        ))}
      </List>
    </GradientOverflow>
  );
}

export default RecipeList;
