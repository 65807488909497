export enum CleaningId {
  Soft = 1,
  Medium = 2,
  High = 3,
}

type Cleaning = {
  id: number;
  description: string;
  duration: number;
  ovenModelId: number;
  ovenSubModelId?: number | null;
};

export default Cleaning;
