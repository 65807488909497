import {Box, List, ListItem, Typography} from '@mui/material';
import {Fragment, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore, {logout} from '../../state/auth';
import stringUtils from '../../utils/strings';
import ConfirmPopover from '../common/ConfirmPopover';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Switch from '../common/Switch';
import useThemeStore from '../../state/theme';

function UpdateNameContainer({
  defaultName,
  onUpdateName,
}: {
  defaultName: string;
  onUpdateName: (name: string) => void;
}) {
  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [name, setName] = useState(defaultName);

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleUpdateName() {
    if (!stringUtils.isNullOrWhiteSpace(name) && name !== defaultName) {
      onUpdateName(name);
    }
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box ref={containerRef} sx={{display: 'flex', justifyContent: 'center', width: '15vw'}}>
      <IconButton
        sx={{zIndex: open ? 1500 : undefined}}
        IconComponent={EditIcon}
        isActive={open}
        onClick={() => {
          setName(defaultName);
          setAnchorEl(open ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        anchorEl={anchorEl}
        placeHolder={t('recipe_add_recipe_type_label')}
        value={name}
        onChange={setName}
        onAccept={handleUpdateName}
        onDecline={() => {
          setName(defaultName);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

function InformationList() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {user, setUser} = useAuthStore();
  const {theme, setTheme} = useThemeStore();

  const {mutate: updateUser, isLoading} = useMutation({
    mutationFn: services.user.updateUser,
    onSuccess: (_, {request: user}) => {
      setUser(user);
    },
  });

  function handleUpdateName(name: string) {
    if (user != null) {
      updateUser({
        params: {
          userId: user.id,
        },
        request: {
          ...user,
          name,
        },
      });
    }
  }

  return (
    <Fragment>
      <List sx={{margin: 0, padding: 0}}>
        <ListItem
          sx={{
            alignItems: 'center',
            margin: 0,
            padding: 0,
            color: 'text.primary',
          }}>
          <Box sx={{width: '35vw', padding: 2}}>
            <Typography variant="body2" sx={{fontWeight: 'bold'}}>
              {t('profile_information_name_label')}
            </Typography>
          </Box>
          <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
            <Typography variant="body2">{user?.name}</Typography>
          </Box>
          <UpdateNameContainer defaultName={user?.name ?? ''} onUpdateName={handleUpdateName} />
        </ListItem>
        <ListItem
          sx={{
            alignItems: 'center',
            margin: 0,
            padding: 0,
            color: 'text.primary',
          }}>
          <Box sx={{width: '35vw', padding: 2}}>
            <Typography variant="body2" sx={{fontWeight: 'bold'}}>
              {t('profile_information_email_label')}
            </Typography>
          </Box>
          <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
            <Typography variant="body2">{user?.email}</Typography>
          </Box>
        </ListItem>
        <ListItem
          sx={{
            alignItems: 'center',
            margin: 0,
            padding: 0,
            color: 'text.primary',
          }}>
          <Box sx={{width: '35vw', padding: 2}}>
            <Typography variant="body2" sx={{fontWeight: 'bold'}}>
              {t('profile_information_password_label')}
            </Typography>
          </Box>
          <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
            <Typography variant="body2">*********</Typography>
          </Box>
          <Box sx={{width: '15vw', textAlign: 'center', padding: 1}}>
            <IconButton
              IconComponent={EditIcon}
              onClick={() => navigate(paths.profileChangePassword)}
            />
          </Box>
        </ListItem>
        <ListItem
          sx={{
            alignItems: 'center',
            margin: 0,
            padding: 0,
            color: 'text.primary',
          }}>
          <Box sx={{width: '35vw', padding: 2}}>
            <Typography variant="body2" sx={{fontWeight: 'bold'}}>
              {t('profile_information_dark_mode_label')}
            </Typography>
          </Box>
          <Box sx={{width: '30vw', textAlign: 'right', padding: 2}}>
            <Box sx={{opacity: 0.5}}>
              <Switch
                checked={theme === 'dark'}
                onChange={(checked) => setTheme(checked ? 'dark' : 'light')}
                disabled
              />
            </Box>
          </Box>
        </ListItem>
        <ListItem
          sx={{
            alignItems: 'center',
            margin: 0,
            padding: 0,
            color: 'text.primary',
          }}>
          <Box sx={{width: '35vw', padding: 2}}>
            <Typography
              className="action"
              variant="body2"
              sx={{fontWeight: 'bold', cursor: 'pointer'}}
              onClick={logout}>
              {t('profile_information_logout_label')}
            </Typography>
          </Box>
        </ListItem>
      </List>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default InformationList;
