export enum OvenSubModelId {
  Turboram5T = 1,
  Turboram9T = 2,
  TurboramXL = 3,
  TurboramCombi = 4,
  TurboramHomeModule = 5,
  TurboramPlusHomeModule = 6,
}

type OvenSubModel = {
  id: number;
  description: string;
  ovenModelId: number;
};

export default OvenSubModel;
