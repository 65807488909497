import {Box, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useLanguage from '../../hooks/common/use-language';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import paths from '../../routes/paths';
import services from '../../services/provider';
import {setAuthData} from '../../state/auth';
import cryptoUtils from '../../utils/crypto';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import LoadingBackdrop from '../common/LoadingBackdrop';

function PasswordReset() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();

  const [, setLanguage] = useLanguage();

  const [recoveryCode, setRecoveryCode] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [invalidCredentials, setInvalidCredentials] = useState(false);

  const {mutate: resetPassword, isLoading} = useMutation({
    mutationFn: services.auth.resetPassword,
    onSuccess: (result) => {
      setAuthData(result.token, result.user);
      setLanguage(result.user.language ?? 'pt');
      splash({
        title: t('splash_screen_password_recovered_title'),
        caption: t('splash_screen_password_recovered_caption'),
        nextAction: () => navigate(paths.dashboard),
      });
    },
    onError: (error) =>
      errorUtils.handleMatch(error, [ApplicationErrorCode.InvalidEmailOrRecoveryCode], () =>
        setInvalidCredentials(true),
      ),
  });

  function handleResetPassword() {
    const email = searchParams.get('email') ?? '';
    resetPassword({
      request: {
        email,
        recoveryCode,
        newPassword: cryptoUtils.sha256(newPassword),
      },
    });
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '30vw'}}>
      <Typography
        variant="h4"
        color="secondary"
        sx={{
          marginBottom: invalidCredentials ? '2vh' : '8vh',
          fontWeight: '500',
        }}>
        {t('password_recovery_login_title')}
      </Typography>
      {invalidCredentials && (
        <Typography variant="caption" color="primary" sx={{marginBottom: '6vh', fontWeight: 500}}>
          {t('password_recovery_login_invalid_email_or_code_label')}
        </Typography>
      )}
      <TextField
        variant="standard"
        sx={{marginBottom: '4vh'}}
        label={t('password_recovery_login_code_input_label')}
        value={recoveryCode}
        onChange={(event) => setRecoveryCode(event.target.value)}
      />
      <TextField
        type="password"
        variant="standard"
        sx={{marginBottom: '20vh'}}
        label={t('password_recovery_login_new_password_input_label')}
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
        onKeyUp={(event) => (event.key === 'Enter' ? handleResetPassword() : null)}
      />
      <Button
        onClick={handleResetPassword}
        disabled={stringUtils.anyIsNullOrWhiteSpace(recoveryCode, newPassword)}>
        {t('password_recovery_login_login_button_label')}
      </Button>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default PasswordReset;
