import useSplashScreenStore, {SplashScreen} from '../../state/splash-screens';

function useSplashScreen() {
  const {splashScreen, setSplashScreen} = useSplashScreenStore();
  return {
    splashScreen,
    splash: (splashScreen: SplashScreen) => setSplashScreen(splashScreen),
    clear: () => setSplashScreen(null),
  };
}

export default useSplashScreen;
