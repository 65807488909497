import {Box, Paper, Typography} from '@mui/material';
import {Validator} from 'jsonschema';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import Recipe from '../../../models/entities/recipe';
import RecipePhase from '../../../models/entities/recipe-phase';
import RecipeType from '../../../models/entities/recipe-type';
import paths from '../../../routes/paths';
import services from '../../../services/provider';
import useAuthStore from '../../../state/auth';
import useBreadcrumbsStore, {Breadcrumb} from '../../../state/breadcrumbs';
import arrayUtils from '../../../utils/arrays';
import cryptoUtils from '../../../utils/crypto';
import numberUtils from '../../../utils/numbers';
import ovenModelUtils from '../../../utils/oven-models';
import LoadingBackdrop from '../../common/LoadingBackdrop';
import Span from '../../common/Span';
import {pageHeight} from '../../navigation/Navbar';
import RecipeSettingsMenu from './RecipeSettingsMenu';
import GeneralStep from './steps/GeneralStep';
import LocationStep from './steps/LocationStep';
import PhaseStep from './steps/PhaseStep';
import ProceduresStep from './steps/ProcedureStep';

export function getEmptyRecipePhase(
  index: number,
  recipeId: string,
  companyId: string,
): RecipePhase {
  return {
    id: cryptoUtils.uuid(),
    index,
    duration: 0,
    temperature: 0,
    topTemperature: 0,
    floorTemperature: 0,
    topPower: 0,
    floorPower: 0,
    steamInjectionNumber: 0,
    steamInjectionDuration: 0,
    steamExitValveOpened: false,
    turbineSpeed: 1,
    turbineRestSeconds: 0,
    recipeId,
    companyId,
  };
}

function getEmptyRecipe(
  ovenModelId: number,
  ovenSubModelId: number | null,
  companyId: string,
  recipeTypeId?: string | null,
): Recipe {
  const recipeId = cryptoUtils.uuid();
  return {
    id: recipeId,
    name: '',
    description: '',
    procedure: '',
    ovenModelId,
    ovenSubModelId,
    recipeSchemaId: '',
    companyId,
    recipePhases: [getEmptyRecipePhase(0, recipeId, companyId)],
    recipeTypes:
      recipeTypeId != null && recipeTypeId !== 'all' ? [{id: recipeTypeId} as RecipeType] : [],
    bakeries: [],
  };
}

function getStepBreadcrumb(
  step: RecipeSettingsStep,
  phaseIndex: number,
  t: (key: string) => string,
) {
  switch (step) {
    case 'general':
      return t('recipe_settings_phase_general');
    case 'procedure':
      return t('recipe_settings_phase_procedure');
    case 'phases':
      return `${t('recipe_settings_phase_phase')}${phaseIndex + 1}`;
    case 'location':
      return t('recipe_settings_phase_location');
  }
}

function getStepTitle(step: RecipeSettingsStep, phaseIndex: number, t: (key: string) => string) {
  switch (step) {
    case 'general':
      return t('recipe_settings_general_step_title');
    case 'procedure':
      return t('recipe_settings_procedures_step_title');
    case 'phases':
      return `${t('recipe_settings_phases_step_title')} ${phaseIndex + 1}`;
    case 'location':
      return '';
  }
}

function parseRecipeSettingsAction(value?: string): RecipeSettingsAction {
  switch (value) {
    case 'create':
    case 'update':
    case 'locate':
      return value;
    default:
      return 'create';
  }
}

export type RecipeSettingsAction = 'create' | 'update' | 'locate';

export type RecipeSettingsSteps = {
  general: {visible: boolean; error: boolean};
  procedure: {visible: boolean; error: boolean};
  phases: {visible: boolean; errors: boolean[]};
  location: {visible: boolean; error: boolean};
};

export type RecipeSettingsStep = keyof RecipeSettingsSteps;

function RecipeSettings() {
  const navigate = useNavigate();
  const {ovenModelId, recipeTypeId, action, recipeId} = useParams();
  const selectedOvenModelId = numberUtils.parseInt(ovenModelId, 1);
  const selectedAction = parseRecipeSettingsAction(action);
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [steps, setSteps] = useState<RecipeSettingsSteps>({
    general: {visible: selectedAction !== 'locate', error: true},
    procedure: {visible: false, error: false},
    phases: {visible: false, errors: [true]},
    location: {visible: selectedAction === 'locate', error: false},
  });
  const [selectedStep, setSelectedStep] = useState<RecipeSettingsStep>(
    selectedAction === 'locate' ? 'location' : 'general',
  );
  const [recipeBuild, setRecipeBuild] = useState<Recipe>(
    getEmptyRecipe(selectedOvenModelId, null, user?.companyId ?? '', recipeTypeId),
  );

  const [selectedRecipePhaseIndex, setSelectedRecipePhaseIndex] = useState(0);

  const {data: recipe, isLoading: isLoadingRecipe} = useQuery({
    enabled: user != null && recipeId != null,
    queryFn: () =>
      services.recipe.getRecipe({
        params: {recipeId: recipeId ?? ''},
        query: {expand: ['recipePhases', 'recipeTypes', 'bakeries']},
      }),
    onSuccess: (recipe) => {
      setRecipeBuild({...recipe, modifiedAt: undefined});
      if (selectedAction === 'update') {
        setSteps({
          general: {visible: true, error: false},
          procedure: {visible: true, error: false},
          phases: {visible: true, errors: recipe.recipePhases?.map(() => false) ?? []},
          location: {visible: false, error: false},
        });
      }
      if (selectedAction === 'locate') {
        setSteps({
          general: {visible: false, error: false},
          procedure: {visible: false, error: false},
          phases: {visible: false, errors: recipe.recipePhases?.map(() => false) ?? []},
          location: {visible: true, error: false},
        });
      }
    },
    onError: () => navigate(`${paths.recipes}/${ovenModelId}/${recipeTypeId}`),
  });

  const {data: recipeSchemas = [], isLoading: isLoadingRecipeSchemas} = useQuery({
    enabled: user != null,
    queryKey: ['recipeSchema', recipeBuild.ovenModelId],
    queryFn: () =>
      services.recipeSchema.getRecipeSchemas({
        query: {
          ovenModelId: recipeBuild.ovenModelId,
          version: 'v1',
        },
      }),
    onSuccess: (recipeSchemas) => {
      if (!arrayUtils.isNullOrEmpty(recipeSchemas)) {
        setRecipeBuild((recipeBuild) => ({
          ...recipeBuild,
          recipeSchemaId: recipeSchemas[0].id,
        }));
      }
    },
  });

  const {mutate: createRecipe, isLoading: isLoadingCreateRecipe} = useMutation({
    mutationFn: services.recipe.createRecipe,
    onSuccess: (_, {request: recipe}) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: recipeBuild.ovenModelId}],
        (recipes) => (recipes != null ? [...recipes, recipe] : [recipe]),
      );
      queryClient.invalidateQueries('recipes');
      navigate(
        `${paths.recipes}/${recipeBuild.ovenModelId}/${recipe.recipeTypes?.[0]?.id ?? 'all'}`,
      );
    },
  });

  const {mutate: updateRecipe, isLoading: isLoadingUpdateRecipe} = useMutation({
    mutationFn: services.recipe.updateRecipe,
    onSuccess: (_, {request: updatedRecipe}) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: recipeBuild.ovenModelId}],
        (recipes) =>
          recipes?.map((recipe) => (recipe.id === updatedRecipe.id ? updatedRecipe : recipe)),
      );
      queryClient.invalidateQueries('recipes');
      navigate(
        `${paths.recipes}/${recipeBuild.ovenModelId}/${
          updatedRecipe.recipeTypes?.[0]?.id ?? 'all'
        }`,
      );
    },
  });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('recipes_breadcrumb'),
      onClick: () => navigate(paths.recipes),
    });
    if (selectedAction === 'create') {
      breadcrumbs.push({
        title: t('recipe_settings_create_recipe_breadcrumb'),
        onClick: () => {
          setSteps({
            general: {visible: true, error: true},
            procedure: {visible: false, error: false},
            phases: {visible: false, errors: [true]},
            location: {visible: false, error: false},
          });
          setSelectedStep('general');
          setRecipeBuild(getEmptyRecipe(selectedOvenModelId, null, user?.companyId ?? ''));
        },
      });
    }
    if (selectedAction === 'update') {
      breadcrumbs.push({
        title: t('recipe_settings_update_recipe_breadcrumb'),
        onClick: () => {
          setSteps((steps) => ({
            ...steps,
            general: {visible: true, error: false},
            procedure: {visible: true, error: false},
            phases: {
              visible: true,
              errors: recipe?.recipePhases?.map(() => false) ?? [],
            },
          }));
          setSelectedStep('general');
          setRecipeBuild(
            recipe ?? getEmptyRecipe(selectedOvenModelId, null, user?.companyId ?? ''),
          );
        },
      });
    }
    if (selectedAction !== 'locate') {
      breadcrumbs.push({
        title: getStepBreadcrumb(selectedStep, selectedRecipePhaseIndex, t),
      });
    } else {
      breadcrumbs.push({
        title: t('recipe_settings_locate_breadcrumb'),
      });
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep, selectedRecipePhaseIndex]);

  useEffect(() => {
    const selectedRecipePhase = recipeBuild.recipePhases?.[selectedRecipePhaseIndex];
    const recipePhaseSchema = recipeSchemas[0]?.recipePhaseSchema;
    if (selectedRecipePhase != null && recipePhaseSchema != null) {
      const error =
        new Validator().validate(selectedRecipePhase, recipePhaseSchema).errors.length > 0;
      setSteps((steps) => ({
        ...steps,
        phases: {
          ...steps.phases,
          errors: steps.phases.errors.map((phaseError, index) =>
            index === selectedRecipePhaseIndex ? error : phaseError,
          ),
        },
      }));
    }
  }, [recipeBuild, recipeSchemas, selectedRecipePhaseIndex]);

  function handleCreateOrUpdateRecipe() {
    if (user == null) return;
    recipeBuild.companyId = user.companyId;
    if (selectedAction === 'create') {
      createRecipe({request: recipeBuild});
      return;
    }
    updateRecipe({
      params: {recipeId: recipeBuild.id},
      request: recipeBuild,
    });
  }

  function renderStep() {
    switch (selectedStep) {
      case 'general':
        return (
          <GeneralStep
            recipeBuild={recipeBuild}
            setRecipeBuild={setRecipeBuild}
            setError={(error) =>
              setSteps((steps) => ({
                ...steps,
                general: {...steps.general, error},
              }))
            }
            isUpdate={selectedAction === 'update'}
          />
        );
      case 'procedure':
        return <ProceduresStep recipeBuild={recipeBuild} setRecipeBuild={setRecipeBuild} />;
      case 'phases':
        return (
          <PhaseStep
            selectedRecipePhaseIndex={selectedRecipePhaseIndex}
            recipePhaseSchema={recipeSchemas[0].recipePhaseSchema ?? {}}
            recipeBuild={recipeBuild}
            setRecipeBuild={setRecipeBuild}
          />
        );
      case 'location':
        return <LocationStep recipeBuild={recipeBuild} setRecipeBuild={setRecipeBuild} />;
    }
  }

  const isLoading =
    isLoadingRecipe || isLoadingRecipeSchemas || isLoadingCreateRecipe || isLoadingUpdateRecipe;

  const renderBanner = selectedStep !== 'location';

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
          marginBlock: '112px',
        }}>
        <RecipeSettingsMenu
          action={selectedAction}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          steps={steps}
          setSteps={setSteps}
          selectedPhaseIndex={selectedRecipePhaseIndex}
          setSelectedPhaseIndex={setSelectedRecipePhaseIndex}
          recipeBuild={recipeBuild}
          setRecipeBuild={setRecipeBuild}
          onFinish={handleCreateOrUpdateRecipe}
        />
      </Box>
      <Box sx={{width: '65vw'}}>
        {renderBanner && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '112px',
            }}>
            <Paper
              elevation={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBlock: 2,
                paddingBlock: 1,
                paddingInline: 2,
                borderRadius: '10px',
                backgroundColor: '#F4F4F4',
              }}>
              <Typography variant="body2">
                <Span sx={{fontWeight: 'bold'}}>
                  {action === 'create'
                    ? `${t('recipe_settings_new_recipe_label')} | `
                    : `${recipe?.name} | `}
                </Span>
                {ovenModelUtils.getDescription(recipeBuild.ovenModelId)}
              </Typography>
            </Paper>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color: 'text.primary',
                marginBlock: 2,
                paddingInline: 2,
              }}>
              {getStepTitle(selectedStep, selectedRecipePhaseIndex, t)}
            </Typography>
          </Box>
        )}
        <Box sx={{height: `calc(${pageHeight} - 112px)`}}>{renderStep()}</Box>
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default RecipeSettings;
