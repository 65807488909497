import {SubscribeRequest, UnsubscribeRequest} from '../../models/requests/subscription';
import {SubscribeResponse, UnsubscribeResponse} from '../../models/responses/subscription';
import {SubscriptionService} from '../subscription';

const mockSubscriptionService: SubscriptionService = {
  subscribe: function (args: {request: SubscribeRequest}): Promise<SubscribeResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {request} = args;

        resolve(request.token);
      }, 500),
    );
  },
  unsubscribe: function (args: {request: UnsubscribeRequest}): Promise<UnsubscribeResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {request} = args;

        resolve(request.token);
      }, 500),
    );
  },
};

export default mockSubscriptionService;
