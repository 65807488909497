import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import useLocalStorageState from './use-local-storage-state';

function useLanguage() {
  const {i18n} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const [, setLanguage] = useLocalStorageState('language', 'en');

  const {mutate: updateUser} = useMutation({
    mutationFn: services.user.updateUser,
  });

  function changeLanguage(language: string) {
    i18n.changeLanguage(language);
    setLanguage(language);
    if (user != null) {
      user.language = language;
      updateUser({
        params: {userId: user.id},
        request: user,
      });
    }
  }

  return [i18n.language, changeLanguage] as const;
}

export default useLanguage;
