import {GetOvenModelsResponse} from '../models/responses/oven-model';
import baseService from './base';
import endpoints from './endpoints';

export type OvenModelService = {
  getOvenModels: () => Promise<GetOvenModelsResponse>;
};

const ovenModelService: OvenModelService = {
  getOvenModels: async function (): Promise<GetOvenModelsResponse> {
    const result = await baseService.get<GetOvenModelsResponse>(endpoints.ovenModels);

    return result;
  },
};

export default ovenModelService;
