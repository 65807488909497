import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';
import {pageHeight} from '../navigation/Navbar';
import ProfileMenu from './ProfileMenu';

function ProfileLayout() {
  return (
    <Box sx={{display: 'flex', width: '100vw', height: pageHeight}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
          marginBlock: '112px',
        }}>
        <ProfileMenu />
      </Box>
      <Outlet />
    </Box>
  );
}

export default ProfileLayout;
