import {
  CreateBakeryRequest,
  DeleteBakeryParams,
  GetBakeryOvenModelParams,
  GetBakeryOvenModelQuery,
  GetBakeryOvenModelsParams,
  GetBakeryOvenModelsQuery,
  GetBakeryParams,
  SyncBakeryParams,
  UpdateBakeryParams,
  UpdateBakeryRequest,
} from '../models/requests/bakery';
import {
  CreateBakeryResponse,
  DeleteBakeryResponse,
  GetBakeryOvenModelResponse,
  GetBakeryOvenModelsResponse,
  GetBakeryResponse,
  SyncBakeryResponse,
  UpdateBakeryResponse,
} from '../models/responses/bakery';
import baseService from './base';
import endpoints from './endpoints';

export type BakeryService = {
  getBakery: (args: {params: GetBakeryParams}) => Promise<GetBakeryResponse>;
  createBakery: (args: {request: CreateBakeryRequest}) => Promise<CreateBakeryResponse>;
  updateBakery: (args: {
    params: UpdateBakeryParams;
    request: UpdateBakeryRequest;
  }) => Promise<UpdateBakeryResponse>;
  deleteBakery: (args: {params: DeleteBakeryParams}) => Promise<DeleteBakeryResponse>;
  getBakeryOvenModels: (args: {
    params: GetBakeryOvenModelsParams;
    query?: GetBakeryOvenModelsQuery;
  }) => Promise<GetBakeryOvenModelsResponse>;
  getBakeryOvenModel: (args: {
    params: GetBakeryOvenModelParams;
    query?: GetBakeryOvenModelQuery;
  }) => Promise<GetBakeryOvenModelResponse>;
  syncBakery: (args: {params: SyncBakeryParams}) => Promise<SyncBakeryResponse>;
};

const bakeryService: BakeryService = {
  getBakery: async function (args: {params: GetBakeryParams}): Promise<GetBakeryResponse> {
    const {params} = args;

    const result = await baseService.get<GetBakeryResponse>(
      `${endpoints.bakeries}/${params.bakeryId}`,
    );

    return result;
  },
  createBakery: async function (args: {
    request: CreateBakeryRequest;
  }): Promise<CreateBakeryResponse> {
    const {request} = args;

    const result = await baseService.post<CreateBakeryResponse, CreateBakeryRequest>(
      endpoints.bakeries,
      request,
    );

    return result;
  },
  updateBakery: async function (args: {
    params: UpdateBakeryParams;
    request: UpdateBakeryRequest;
  }): Promise<UpdateBakeryResponse> {
    const {params, request} = args;

    const result = await baseService.patch<UpdateBakeryResponse, UpdateBakeryRequest>(
      `${endpoints.bakeries}/${params.bakeryId}`,
      request,
    );

    return result;
  },
  deleteBakery: async function (args: {params: DeleteBakeryParams}): Promise<DeleteBakeryResponse> {
    const {params} = args;

    const result = await baseService.delete<DeleteBakeryResponse>(
      `${endpoints.bakeries}/${params.bakeryId}`,
    );

    return result;
  },
  getBakeryOvenModels: async function (args: {
    params: GetBakeryOvenModelsParams;
    query?: GetBakeryOvenModelsQuery;
  }): Promise<GetBakeryOvenModelsResponse> {
    const {params, query} = args;

    const result = await baseService.get<GetBakeryOvenModelsResponse, GetBakeryOvenModelsQuery>(
      `${endpoints.bakeries}/${params.bakeryId}/oven-models`,
      query,
    );

    return result;
  },
  getBakeryOvenModel: async function (args: {
    params: GetBakeryOvenModelParams;
    query?: GetBakeryOvenModelQuery;
  }): Promise<GetBakeryOvenModelResponse> {
    const {params, query} = args;

    const result = await baseService.get<GetBakeryOvenModelResponse>(
      `${endpoints.bakeries}/${params.bakeryId}/oven-models/${params.ovenModelId}`,
      query,
    );

    return result;
  },
  syncBakery: async function (args: {params: SyncBakeryParams}): Promise<SyncBakeryResponse> {
    const {params} = args;

    const result = await baseService.post<SyncBakeryResponse>(
      `${endpoints.bakeries}/${params.bakeryId}/sync`,
      {},
    );

    return result;
  },
};

export default bakeryService;
