import {GetCitiesQuery, GetCityParams} from '../../models/requests/city';
import {GetCitiesResponse, GetCityResponse} from '../../models/responses/city';
import {ApplicationError} from '../../utils/errors';
import {CityService} from '../city';
import {data} from './data';

const mockCityService: CityService = {
  getCities: function (args: {query?: GetCitiesQuery}): Promise<GetCitiesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const cities = data.cities
          .filter((city) => query?.districtId == null || city.districtId === query.districtId)
          .map((city) => ({...city}));

        resolve(cities);
      }, 500),
    );
  },
  getCity: function (args: {params: GetCityParams}): Promise<GetCityResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const city = data.cities.find((city) => city.id === params.cityId);

        if (city == null) {
          reject(ApplicationError.notFound('City'));
          return;
        }

        resolve({...city});
      }, 500),
    );
  },
};

export default mockCityService;
