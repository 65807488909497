import {Box, InputBase, styled, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const InputBaseStyled = styled(InputBase)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input': {
    textAlign: 'right',
  },
}));

type DurationRowProps = {
  duration: string;
  onChange: (duration: string) => void;
  error?: boolean;
};

function DurationRow(props: DurationRowProps) {
  const {duration, onChange, error} = props;
  const {t} = useTranslation();

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const length = event.target.value.length;
    if (length <= 2) {
      onChange(event.target.value);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingBlock: 1,
        paddingInline: 2,
      }}>
      <Box>
        <Typography variant="body2" sx={{fontWeight: 'bold', color: 'text.primary'}}>
          {t('recipe_settings_phases_duration_label')}
        </Typography>
        <Typography variant="caption" sx={{color: error ? 'primary.main' : 'text.secondary'}}>
          {t('recipe_settings_phases_duration_message')}
        </Typography>
      </Box>
      <InputBaseStyled
        sx={{
          fontSize: '0.875rem',
          color: error ? 'primary.main' : 'text.primary',
        }}
        type="number"
        placeholder={showPlaceholder ? '0' : ''}
        value={duration === '0' ? '' : duration}
        onChange={handleChange}
        onFocus={() => setShowPlaceholder(false)}
        onBlur={() => setShowPlaceholder(true)}
        endAdornment={
          <Typography
            variant="body2"
            sx={{
              marginLeft: 1,
              color: error ? 'primary.main' : 'text.primary',
            }}>
            {t('recipe_settings_phases_minutes_label')}
          </Typography>
        }
      />
    </Box>
  );
}

export default DurationRow;
