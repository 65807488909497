import {createTheme} from '@mui/material';

export default createTheme({
  palette: {
    primary: {
      main: '#E30613',
    },
    secondary: {
      main: '#989696',
    },
    text: {
      primary: '#989696',
      secondary: '#CBC9C9',
      disabled: '#EFEFEF',
    },
    background: {
      default: '#FFFFFF',
    },
    custom: {
      black: '#000000',
      white: '#FFFFFF',
      grey: '#989696',
      lightGrey: '#CBC9C9',
      lighterGrey: '#EFEFEF',
      red: '#E30613',
      blue: '#6395C2',
      green: '#8AC381',
      orange: '#ED8843',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          padding: '6px',
          fontSize: '18px',
          textTransform: 'none',
          borderRadius: '10px',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        spellCheck: false,
      },
    },
    MuiTableCell: {
      defaultProps: {
        style: {
          border: 'none',
        },
      },
    },
  },
});
