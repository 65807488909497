import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import paths from '../../routes/paths';
import {Button} from '../common/Button';

function Welcome() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '30vw',
      }}>
      <Typography variant="h4" color="secondary" sx={{marginBottom: '5vh', fontWeight: '500'}}>
        {t('welcome_title')}
      </Typography>
      <Typography variant="body2" color="secondary" sx={{width: '12vw', marginBottom: '25vh'}}>
        {t('welcome_caption')}
      </Typography>
      <Button sx={{marginBottom: '2vh'}} onClick={() => navigate(paths.login)}>
        {t('welcome_login_button_label')}
      </Button>
      <Button sx={{marginBottom: '2vh'}} onClick={() => navigate(paths.signup)}>
        {t('welcome_signup_button_label')}
      </Button>
      <Typography variant="caption" color="secondary" sx={{width: '100%', textAlign: 'right'}}>
        <Link to={paths.home}>{t('welcome_terms_and_conditions_label')}</Link>
      </Typography>
    </Box>
  );
}

export default Welcome;
