import {GetCountryParams} from '../../models/requests/country';
import {GetCountriesResponse, GetCountryResponse} from '../../models/responses/country';
import {ApplicationError} from '../../utils/errors';
import {CountryService} from '../country';
import {data} from './data';

const mockCountryService: CountryService = {
  getCountries: function (): Promise<GetCountriesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const countries = data.countries.map((country) => ({...country}));

        resolve(countries);
      }, 500),
    );
  },
  getCountry: function (args: {params: GetCountryParams}): Promise<GetCountryResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const country = data.countries.find((country) => country.id === params.countryId);

        if (country == null) {
          reject(ApplicationError.notFound('Country'));
          return;
        }

        resolve({...country});
      }, 500),
    );
  },
};

export default mockCountryService;
