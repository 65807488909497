import OvenModel, {OvenModelId} from '../models/entities/oven-model';
import User from '../models/entities/user';

const ovenModelUtils = {
  getDescription: function (ovenModelId: OvenModelId) {
    switch (ovenModelId) {
      case OvenModelId.Compactram:
        return 'Compactram';
      case OvenModelId.Electram:
        return 'Electram';
      case OvenModelId.Modulram:
        return 'Modulram';
      case OvenModelId.Rotoram:
        return 'Rotoram';
      case OvenModelId.Turboram:
        return 'Turboram';
    }
  },
  filterStockOvenModels: function (ovenModels: OvenModel[], user?: User | null) {
    return ovenModels.filter(
      (ovenModel) =>
        ovenModel.ovenGroups?.some((ovenGroup) => ovenGroup.bakeryId !== user?.companyId) ||
        ovenModel.ovens?.some((oven) => oven.bakeryId !== user?.companyId),
    );
  },
};

export default ovenModelUtils;
