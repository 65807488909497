import {SubscribeRequest, UnsubscribeRequest} from '../models/requests/subscription';
import {SubscribeResponse, UnsubscribeResponse} from '../models/responses/subscription';
import baseService from './base';
import endpoints from './endpoints';

export type SubscriptionService = {
  subscribe: (args: {request: SubscribeRequest}) => Promise<SubscribeResponse>;
  unsubscribe: (args: {request: UnsubscribeRequest}) => Promise<UnsubscribeResponse>;
};

const subscriptionService: SubscriptionService = {
  subscribe: async function (args: {request: SubscribeRequest}): Promise<SubscribeResponse> {
    const {request} = args;

    const result = await baseService.post<SubscribeResponse, SubscribeRequest>(
      `${endpoints.subscriptions}/subscribe`,
      request,
    );

    return result;
  },
  unsubscribe: async function (args: {request: UnsubscribeRequest}): Promise<UnsubscribeResponse> {
    const {request} = args;

    const result = await baseService.post<UnsubscribeResponse, UnsubscribeRequest>(
      `${endpoints.subscriptions}/unsubscribe`,
      request,
    );

    return result;
  },
};

export default subscriptionService;
