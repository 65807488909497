import {GetNotificationTypesQuery} from '../models/requests/notification-type';
import {GetNotificationTypesResponse} from '../models/responses/notification-type';
import baseService from './base';
import endpoints from './endpoints';

export type NotificationTypeService = {
  getNotificationTypes: (args: {
    query: GetNotificationTypesQuery;
  }) => Promise<GetNotificationTypesResponse>;
};

const notificationTypeService: NotificationTypeService = {
  getNotificationTypes: async function (args: {
    query: GetNotificationTypesQuery;
  }): Promise<GetNotificationTypesResponse> {
    const {query} = args;

    const result = await baseService.get<GetNotificationTypesResponse>(
      endpoints.notificationTypes,
      query,
    );

    return result;
  },
};

export default notificationTypeService;
