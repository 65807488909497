const numberUtils = {
  parseInt: function (value: string | null | undefined, fallbackValue = 0) {
    const parsedValue = parseInt(value ?? '');
    return isNaN(parsedValue) ? fallbackValue : parsedValue;
  },
  randomInteger: function (maxValue: number) {
    return Math.floor(Math.random() * maxValue) + 1;
  },
};

export default numberUtils;
