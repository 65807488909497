import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {weekDaysArray} from '../../utils/dates';
import Tabs from '../common/Tabs';

export type DayTabsProps = {
  selectedWeekDay: Day;
  onChangeSelectedWeekDay: (day: Day) => void;
};

function WeekDayTabs(props: DayTabsProps) {
  const {selectedWeekDay, onChangeSelectedWeekDay} = props;
  const {t} = useTranslation();

  const selectedTabIndex = weekDaysArray.findIndex((weekDay) => weekDay.value === selectedWeekDay);

  function handleSelectWeekDay(index: number) {
    onChangeSelectedWeekDay(weekDaysArray[index].value);
  }

  return (
    <Box>
      <Tabs
        values={weekDaysArray.map((weekDay) => t(weekDay.key))}
        selectedTabIndex={selectedTabIndex >= 0 ? selectedTabIndex : 0}
        onTabClick={handleSelectWeekDay}
        tabSx={{fontWeight: 300}}
      />
    </Box>
  );
}

export default WeekDayTabs;
