import {
  ChangeUserPasswordParams,
  ChangeUserPasswordRequest,
  GetUserNotificationTypesParams,
  GetUserParams,
  UpdateUserNotificationTypeParams,
  UpdateUserNotificationTypeRequest,
  UpdateUserParams,
  UpdateUserRequest,
} from '../models/requests/user';
import {
  ChangeUserPasswordResponse,
  GetUserNotificationTypesResponse,
  GetUserResponse,
  UpdateUserNotificationTypeResponse,
  UpdateUserResponse,
} from '../models/responses/user';
import baseService from './base';
import endpoints from './endpoints';

export type UserService = {
  getUser: (args: {params: GetUserParams}) => Promise<GetUserResponse>;
  updateUser: (args: {
    params: UpdateUserParams;
    request: UpdateUserRequest;
  }) => Promise<UpdateUserResponse>;
  changeUserPassword: (args: {
    params: ChangeUserPasswordParams;
    request: ChangeUserPasswordRequest;
  }) => Promise<ChangeUserPasswordResponse>;
  getUserNotificationTypes: (args: {
    params: GetUserNotificationTypesParams;
  }) => Promise<GetUserNotificationTypesResponse>;
  updateUserNotificationType: (args: {
    params: UpdateUserNotificationTypeParams;
    request: UpdateUserNotificationTypeRequest;
  }) => Promise<UpdateUserNotificationTypeResponse>;
};

const userService: UserService = {
  getUser: async function (args: {params: GetUserParams}): Promise<GetUserResponse> {
    const {params} = args;

    const result = await baseService.get<GetUserResponse>(`${endpoints.users}/${params.userId}`);

    return result;
  },
  updateUser: async function (args: {
    params: UpdateUserParams;
    request: UpdateUserRequest;
  }): Promise<UpdateUserResponse> {
    const {params, request} = args;

    const result = await baseService.patch<UpdateUserResponse, UpdateUserRequest>(
      `${endpoints.users}/${params.userId}`,
      request,
    );

    return result;
  },
  changeUserPassword: async function (args: {
    params: ChangeUserPasswordParams;
    request: ChangeUserPasswordRequest;
  }): Promise<ChangeUserPasswordResponse> {
    const {params, request} = args;

    const result = await baseService.patch<ChangeUserPasswordResponse, ChangeUserPasswordRequest>(
      `${endpoints.users}/${params.userId}/password`,
      request,
    );

    return result;
  },
  getUserNotificationTypes: async function (args: {
    params: GetUserNotificationTypesParams;
  }): Promise<GetUserNotificationTypesResponse> {
    const {params} = args;

    const result = await baseService.get<GetUserNotificationTypesResponse>(
      `${endpoints.users}/${params.userId}/notification-types`,
    );

    return result;
  },
  updateUserNotificationType: async function (args: {
    params: UpdateUserNotificationTypeParams;
    request: UpdateUserNotificationTypeRequest;
  }): Promise<UpdateUserNotificationTypeResponse> {
    const {params, request} = args;

    const result = await baseService.patch<
      UpdateUserNotificationTypeResponse,
      UpdateUserNotificationTypeRequest
    >(
      `${endpoints.users}/${params.userId}/notification-types/${params.notificationTypeId}`,
      request,
    );

    return result;
  },
};

export default userService;
