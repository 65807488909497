import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import numberUtils from '../utils/numbers';

const firebaseConfig = {
  apiKey: 'AIzaSyDDfcR4kp7ehZpJ1QuseFHGnRofIBMs838',
  authDomain: 'iram-543d9.firebaseapp.com',
  projectId: 'iram-543d9',
  storageBucket: 'iram-543d9.appspot.com',
  messagingSenderId: '1013038210144',
  appId: '1:1013038210144:web:cad20a670bd9de2907b705',
};

const app = initializeApp(firebaseConfig);

let subscriptionToken = '';

type FirebaseNotification = {
  id: string;
  parameter: string;
  value: string;
  notificationId: number;
  ovenPanelId: string;
};

async function getSubscriptionToken() {
  return getToken(getMessaging(app), {
    vapidKey:
      'BFU2ZKUyxhNNpa_Wk_ZLeICzlxaYq-pt3oXe63ctYWdFQ7GUuz-fVF36dEEKwpJdR3Q5FtWm1PdLy2TXS7Y755Q',
  })
    .then((token) => {
      console.log('successfully got token for subscribing to firebase');
      subscriptionToken = token;
    })
    .catch((error) => {
      console.log('error getting token for subscribing to firebase');
      console.log(error);
    });
}

function subscribe(callback: (notification: FirebaseNotification) => void) {
  return onMessage(getMessaging(app), (payload) => {
    callback({
      id: payload.data?.id ?? '',
      parameter: payload.data?.parameter ?? '',
      value: payload.data?.value ?? '',
      notificationId: numberUtils.parseInt(payload.data?.notificationId, 0),
      ovenPanelId: payload.data?.ovenPanelId ?? '',
    });
  });
}

export {subscriptionToken, getSubscriptionToken, subscribe};
