import {GetRecipeSchemasQuery} from '../../models/requests/recipe-schema';
import {GetRecipeSchemasResponse} from '../../models/responses/recipe-schema';
import {RecipeSchemaService} from '../recipe-schema';
import {data} from './data';

const mockRecipeSchemaService: RecipeSchemaService = {
  getRecipeSchemas: function (args: {
    query: GetRecipeSchemasQuery;
  }): Promise<GetRecipeSchemasResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const recipeSchemas = data.recipeSchemas
          .filter(
            (recipeSchema) =>
              (query.ovenModelId == null || recipeSchema.ovenModelId === query.ovenModelId) &&
              (query.version == null || recipeSchema.version === query.version),
          )
          .map((recipeSchema) => ({...recipeSchema}));

        resolve(recipeSchemas);
      }, 500),
    );
  },
};

export default mockRecipeSchemaService;
