import {Box, Typography} from '@mui/material';
import Oven from '../../../../models/entities/oven';
import {OvenModelId} from '../../../../models/entities/oven-model';
import useAuthStore from '../../../../state/auth';
import {AddOvenData, AddOvenStep, AddOvenSteps} from '../AddOven';

export type ModelStepProps = {
  ovens: Oven[];
  setSteps: (setter: (steps: AddOvenSteps) => AddOvenSteps) => void;
  setSelectedStep: (step: AddOvenStep) => void;
  addOvenData: AddOvenData;
  setAddOvenData: (setter: (data: AddOvenData) => AddOvenData) => void;
};

function ModelStep(props: ModelStepProps) {
  const {ovens, setSteps, setSelectedStep, addOvenData, setAddOvenData} = props;

  const user = useAuthStore((state) => state.user);

  function handleSelectOvenModel(ovenModelId: number) {
    const isTurboram = ovenModelId === OvenModelId.Turboram;
    const isModulram = ovenModelId === OvenModelId.Modulram;
    const canAddOvenChamberToExistingModulram =
      isModulram &&
      ovens.some(
        (oven) =>
          oven.ovenModelId === OvenModelId.Modulram &&
          oven.bakeryId !== user?.companyId &&
          (oven.ovenChambers?.length ?? 0) < 5,
      );
    setAddOvenData(() => ({
      ovenModelId,
      ovenSubModelId: null,
      selectedOvenId: null,
      serialNumber: '',
      topOvenSerialNumber: '',
      bottomOvenSerialNumber: '',
      ovenChamberSerialNumbers: [],
      description: '',
    }));
    setSteps(() => ({
      model: {visible: true, error: false},
      subModel: {visible: isTurboram, error: isTurboram},
      option: {
        visible: canAddOvenChamberToExistingModulram,
        error: canAddOvenChamberToExistingModulram,
      },
      ovens: {visible: false, error: false},
      chambersNumber: {
        visible: isModulram && !canAddOvenChamberToExistingModulram,
        error: isModulram && !canAddOvenChamberToExistingModulram,
      },
      serialNumber: {visible: !isTurboram && !isModulram, error: !isTurboram && !isModulram},
      description: {visible: false, error: false},
    }));
    setSelectedStep(
      isTurboram
        ? 'subModel'
        : isModulram
        ? canAddOvenChamberToExistingModulram
          ? 'option'
          : 'chambersNumber'
        : 'serialNumber',
    );
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, padding: 2}}>
      {[
        {id: OvenModelId.Compactram, description: 'Compactram'},
        {id: OvenModelId.Electram, description: 'Electram'},
        {id: OvenModelId.Modulram, description: 'Modulram'},
        {id: OvenModelId.Rotoram, description: 'Rotoram'},
        {id: OvenModelId.Turboram, description: 'Turboram'},
      ].map((ovenModel) => (
        <Box
          key={ovenModel.id}
          sx={{
            width: '100%',
            fontWeight: addOvenData.ovenModelId === ovenModel.id ? 'bold' : 'normal',
            color: addOvenData.ovenModelId === ovenModel.id ? 'primary.main' : 'secondary.main',
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 'bold',
              color: 'primary.main',
            },
          }}
          onClick={() => handleSelectOvenModel(ovenModel.id)}>
          <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
            {ovenModel.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

export default ModelStep;
