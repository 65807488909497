import {Box} from '@mui/material';
import {ReactNode} from 'react';

type HiddenProps = {
  hidden?: boolean;
  children?: ReactNode;
};

function Hidden(props: HiddenProps) {
  const {hidden = true, children} = props;
  return (
    <Box
      sx={{display: hidden ? 'none' : 'block', width: '100%', height: '100%'}}>
      {children}
    </Box>
  );
}

export default Hidden;
