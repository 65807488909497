import {Button as MuiButton, ButtonProps} from '@mui/material';

export function Button(props: ButtonProps) {
  const {sx, variant, disabled, ...rest} = props;
  return (
    <MuiButton
      sx={{
        color: 'custom.white',
        backgroundColor: 'primary.main',
        '&:hover': {
          color: 'custom.white',
          backgroundColor: 'primary.main',
        },
        '&.Mui-disabled': {
          color: 'custom.white',
          backgroundColor: 'secondary.main',
        },
        ...sx,
      }}
      variant="contained"
      disableRipple
      disabled={disabled}
      {...rest}>
      {props.children}
    </MuiButton>
  );
}
