import {
  GetCompanyCountriesParams,
  GetCompanyCountriesQuery,
  GetCompanyOvenModelsParams,
  GetCompanyOvenModelsQuery,
} from '../../models/requests/company';
import {
  GetCompanyCountriesResponse,
  GetCompanyOvenModelsResponse,
} from '../../models/responses/company';
import {ApplicationError} from '../../utils/errors';
import {CompanyService} from '../company';
import {data} from './data';

const mockCompanyService: CompanyService = {
  getCompanyCountries: function (args: {
    params: GetCompanyCountriesParams;
    query?: GetCompanyCountriesQuery;
  }): Promise<GetCompanyCountriesResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const company = data.companies.find((company) => company.id === params.companyId);

        if (company == null) {
          reject(ApplicationError.notFound('Company'));
          return;
        }

        const bakeries = data.bakeries.filter((bakery) => bakery.companyId === params.companyId);

        const cityIds = new Set(bakeries.map((bakery) => bakery.cityId));

        const cities = data.cities
          .filter((city) => cityIds.has(city.id))
          .map((city) => ({
            ...city,
            bakeries: bakeries.filter((bakery) => bakery.cityId === city.id),
          }));

        const districtIds = new Set(cities.map((city) => city.districtId));
        const countryIds = new Set(cities.map((city) => city.countryId));

        const districts = data.districts
          .filter((district) => districtIds.has(district.id))
          .map((district) => ({
            ...district,
            cities: cities.filter((city) => city.districtId === district.id),
          }));

        const countries = data.countries
          .filter((country) => countryIds.has(country.id))
          .map((country) => ({
            ...country,
            districts: districts.filter((district) => district.countryId === country.id),
          }));

        resolve(countries);
      }, 500),
    );
  },
  getCompanyOvenModels: function (args: {
    params: GetCompanyOvenModelsParams;
    query?: GetCompanyOvenModelsQuery;
  }): Promise<GetCompanyOvenModelsResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const ovenModelIds = new Set(
          data.ovenPanels
            .filter((ovenPanel) => ovenPanel.companyId === params.companyId)
            .map((ovenPanel) => ovenPanel.ovenModelId),
        );

        const ovenModels = data.ovenModels
          .filter((ovenModel) => ovenModelIds.has(ovenModel.id))
          .map((ovenModel) => ({
            ...ovenModel,
            ovenGroups: data.ovenGroups
              .filter(
                (ovenGroup) =>
                  ovenGroup.ovenModelId === ovenModel.id &&
                  ovenGroup.companyId === params.companyId,
              )
              .map((ovenGroup) => ({
                ...ovenGroup,
                ovens: data.ovens
                  .filter((oven) => oven.ovenGroupId === ovenGroup.id)
                  .map((oven) => ({
                    ...oven,
                    ovenChambers: data.ovenChambers
                      .filter((ovenChamber) => ovenChamber.ovenId === oven.id)
                      .map((ovenChamber) => ({
                        ...ovenChamber,
                        ovenPanels: data.ovenPanels
                          .filter((ovenPanel) => ovenPanel.ovenChamberId === ovenChamber.id)
                          .map((ovenPanel) => ({...ovenPanel})),
                      })),
                    ovenPanels: data.ovenPanels
                      .filter((ovenPanel) => ovenPanel.ovenId === oven.id)
                      .map((ovenPanel) => ({...ovenPanel})),
                  })),
              })),
            ovens: data.ovens
              .filter(
                (oven) => oven.ovenModelId === ovenModel.id && oven.companyId === params.companyId,
              )
              .map((oven) => ({
                ...oven,
                ovenChambers: data.ovenChambers
                  .filter((ovenChamber) => ovenChamber.ovenId === oven.id)
                  .map((ovenChamber) => ({
                    ...ovenChamber,
                    ovenPanels: data.ovenPanels
                      .filter((ovenPanel) => ovenPanel.ovenChamberId === ovenChamber.id)
                      .map((ovenPanel) => ({...ovenPanel})),
                  })),
                ovenPanels: data.ovenPanels
                  .filter((ovenPanel) => ovenPanel.ovenId === oven.id)
                  .map((ovenPanel) => ({...ovenPanel})),
              })),
          }));

        resolve(ovenModels);
      }, 500),
    );
  },
};

export default mockCompanyService;
