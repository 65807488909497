import {TFunction} from 'i18next';
import Cleaning, {CleaningId} from '../models/entities/cleaning';

const cleaningUtils = {
  getDescription: function (cleaning: Cleaning, t: TFunction) {
    switch (cleaning.id) {
      case CleaningId.Soft:
        return t('turboram_slight_cleaning_label');
      case CleaningId.Medium:
        return t('turboram_medium_cleaning_label');
      case CleaningId.High:
        return t('turboram_intense_cleaning_label');
      default:
        return cleaning.description;
    }
  },
};

export default cleaningUtils;
