import {GetOvenModelsResponse} from '../../models/responses/oven-model';
import {OvenModelService} from '../oven-model';
import {data} from './data';

const mockOvenModelService: OvenModelService = {
  getOvenModels: function (): Promise<GetOvenModelsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const ovenModels = data.ovenModels.map((ovenModel) => ({...ovenModel}));

        resolve(ovenModels);
      }, 500),
    );
  },
};

export default mockOvenModelService;
