import {Schema} from 'jsonschema';

export const compactramV1RecipeSchema: Schema = {
  id: '/CompactramV1Recipe',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    nameEn: {type: 'string'},
    nameEs: {type: 'string'},
    namePt: {type: 'string'},
    nameFr: {type: 'string'},
    namePl: {type: 'string'},
    description: {type: 'string'},
    procedure: {type: 'string'},
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    recipeSchemaId: {type: 'string', required: true},
    recipePhases: {
      type: 'array',
      items: {$ref: '/CompactramV1RecipePhase'},
      minItems: 1,
      maxItems: 4,
    },
  },
};

export const compactramV1RecipePhaseSchema: Schema = {
  id: '/CompactramV1RecipePhase',
  type: 'object',
  properties: {
    duration: {type: 'number', minimum: 60, maximum: 5940, required: true},
    topTemperature: {type: 'number', minimum: 1, maximum: 350, required: true},
    floorTemperature: {type: 'number', minimum: 0, maximum: 350, required: true},
    topPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    floorPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    steamInjectionDuration: {type: 'number', minimum: 0, maximum: 240, required: true},
    steamExitValveOpened: {type: 'boolean', required: true},
  },
};

export const electramV1RecipeSchema: Schema = {
  id: '/ElectramV1Recipe',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    nameEn: {type: 'string'},
    nameEs: {type: 'string'},
    namePt: {type: 'string'},
    nameFr: {type: 'string'},
    namePl: {type: 'string'},
    description: {type: 'string'},
    procedure: {type: 'string'},
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    recipeSchemaId: {type: 'string', required: true},
    recipePhases: {
      type: 'array',
      items: {$ref: '/ElectramV1RecipePhase'},
      minItems: 1,
      maxItems: 4,
    },
  },
};

export const electramV1RecipePhaseSchema: Schema = {
  id: '/ElectramV1RecipePhase',
  type: 'object',
  properties: {
    duration: {type: 'number', minimum: 60, maximum: 5940, required: true},
    topTemperature: {type: 'number', minimum: 1, maximum: 350, required: true},
    floorTemperature: {type: 'number', minimum: 0, maximum: 350, required: true},
    topPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    floorPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    steamInjectionDuration: {type: 'number', minimum: 0, maximum: 240, required: true},
    steamExitValveOpened: {type: 'boolean', required: true},
  },
};

export const modulramV1RecipeSchema: Schema = {
  id: '/ModulramV1Recipe',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    nameEn: {type: 'string'},
    nameEs: {type: 'string'},
    namePt: {type: 'string'},
    nameFr: {type: 'string'},
    namePl: {type: 'string'},
    description: {type: 'string'},
    procedure: {type: 'string'},
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    recipeSchemaId: {type: 'string', required: true},
    recipePhases: {
      type: 'array',
      items: {$ref: '/ModulramV1RecipePhase'},
      minItems: 1,
      maxItems: 4,
    },
  },
};

export const modulramV1RecipePhaseSchema: Schema = {
  id: '/ModulramV1RecipePhase',
  type: 'object',
  properties: {
    duration: {type: 'number', minimum: 60, maximum: 5940, required: true},
    topTemperature: {type: 'number', minimum: 1, maximum: 350, required: true},
    floorTemperature: {type: 'number', minimum: 0, maximum: 350, required: true},
    topPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    floorPower: {type: 'number', minimum: -30, maximum: 30, required: true},
    steamInjectionDuration: {type: 'number', minimum: 0, maximum: 240, required: true},
    steamExitValveOpened: {type: 'boolean', required: true},
  },
};

export const rotoramV1RecipeSchema: Schema = {
  id: '/RotoramV1Recipe',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    nameEn: {type: 'string'},
    nameEs: {type: 'string'},
    namePt: {type: 'string'},
    nameFr: {type: 'string'},
    namePl: {type: 'string'},
    description: {type: 'string'},
    procedure: {type: 'string'},
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    recipeSchemaId: {type: 'string', required: true},
    recipePhases: {type: 'array', items: {$ref: '/RotoramV1RecipePhase'}, minItems: 1, maxItems: 4},
  },
};

export const rotoramV1RecipePhaseSchema: Schema = {
  id: '/RotoramV1RecipePhase',
  type: 'object',
  properties: {
    duration: {type: 'number', minimum: 60, maximum: 5940, required: true},
    temperature: {type: 'number', minimum: 1, maximum: 300, required: true},
    steamInjectionNumber: {type: 'number', minimum: 0, maximum: 5, required: true},
    steamExitValveOpened: {type: 'boolean', required: true},
    turbineRestSeconds: {type: 'number', minimum: 0, maximum: 60, required: true},
  },
};

export const turboramV1RecipeSchema: Schema = {
  id: '/TurboramV1Recipe',
  type: 'object',
  properties: {
    name: {type: 'string', required: true},
    nameEn: {type: 'string'},
    nameEs: {type: 'string'},
    namePt: {type: 'string'},
    nameFr: {type: 'string'},
    namePl: {type: 'string'},
    description: {type: 'string'},
    procedure: {type: 'string'},
    ovenModelId: {type: 'number', required: true},
    ovenSubModelId: {type: 'number'},
    recipeSchemaId: {type: 'string', required: true},
    recipePhases: {
      type: 'array',
      items: {$ref: '/TurboramV1RecipePhase'},
      minItems: 1,
      maxItems: 4,
    },
  },
};

export const turboramV1RecipePhaseSchema: Schema = {
  id: '/TurboramV1RecipePhase',
  type: 'object',
  properties: {
    duration: {type: 'number', minimum: 60, maximum: 5940, required: true},
    temperature: {type: 'number', minimum: 1, maximum: 300, required: true},
    steamInjectionNumber: {type: 'number', minimum: 0, maximum: 5, required: true},
    steamExitValveOpened: {type: 'boolean', required: true},
    turbineSpeed: {type: 'number', minimum: 0, maximum: 2, required: true},
  },
};
