const routerUtils = {
  createSearchParams: function (
    params: Record<string, string | number | boolean | null | undefined>,
  ) {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value != null) {
        searchParams.set(key, value.toString());
      }
    });
    return searchParams;
  },
};

export default routerUtils;
