import {GetNotificationTypesQuery} from '../../models/requests/notification-type';
import {GetNotificationTypesResponse} from '../../models/responses/notification-type';
import {NotificationTypeService} from '../notification-type';
import {data} from './data';

const mockNotificationTypeService: NotificationTypeService = {
  getNotificationTypes: function (args: {
    query: GetNotificationTypesQuery;
  }): Promise<GetNotificationTypesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const notificationTypes = data.notificationTypes
          .filter(
            (notificationType) =>
              query?.ovenModelId == null ||
              notificationType.ovenModelId == null ||
              notificationType.ovenModelId === query.ovenModelId,
          )
          .map((notificationType) => ({...notificationType}));

        resolve(notificationTypes);
      }, 500),
    );
  },
};

export default mockNotificationTypeService;
