import {create} from 'zustand';

export type SplashScreen = {
  title?: string;
  caption?: string;
  subCaption?: string;
  nextAction?: () => void;
  acceptAction?: () => void;
  declineAction?: () => void;
};

type SplashScreenStore = {
  splashScreen: SplashScreen | null;
  setSplashScreen: (splashScreen: SplashScreen | null) => void;
};

const useSplashScreenStore = create<SplashScreenStore>((set) => ({
  splashScreen: null,
  setSplashScreen: (splashScreen: SplashScreen | null) => set({splashScreen}),
}));

export default useSplashScreenStore;
