import {Box, InputBase, Typography} from '@mui/material';
import {ChangeEvent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as AcceptSimpleIcon} from '../../../../../assets/icons/accept-simple.svg';
import {ReactComponent as DeclineSimpleIcon} from '../../../../../assets/icons/decline-simple.svg';
import Compactram3cImage from '../../../../../assets/images/compactram-3c-front.png';
import Compactram4cImage from '../../../../../assets/images/compactram-4c-front.png';
import {OvenModelId} from '../../../../../models/entities/oven-model';
import useAuthStore from '../../../../../state/auth';
import stringUtils from '../../../../../utils/strings';
import Icon from '../../../../common/Icon';
import Image from '../../../../common/Image';
import {SerialNumberStepProps} from './SerialNumberStep';

function CompactramSerialNumberStep(props: SerialNumberStepProps) {
  const {steps, setSteps, selectedStep, setSelectedStep, addOvenData, setAddOvenData} = props;
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const ovens = useMemo(
    () =>
      props.ovens.filter(
        (oven) => oven.ovenModelId === OvenModelId.Compactram && oven.bakeryId === user?.companyId,
      ),
    [props.ovens, user],
  );

  const oven = useMemo(
    () => ovens.find((oven) => oven.serialNumber === addOvenData.serialNumber),
    [ovens, addOvenData.serialNumber],
  );

  function validateSerialNumber(serialNumber: string) {
    const oven = ovens.find((oven) => oven.serialNumber === serialNumber);
    setSteps((steps) => ({
      ...steps,
      serialNumber: {visible: true, error: oven == null},
      description: {
        ...steps.description,
        error: stringUtils.isNullOrWhiteSpace(addOvenData.description),
      },
    }));
  }

  function handleChangeSerialNumber(event: ChangeEvent<HTMLInputElement>) {
    setAddOvenData((addOvenData) => ({...addOvenData, serialNumber: event.target.value}));
    setSteps((steps) => ({
      ...steps,
      serialNumber: {visible: true, error: true},
      description: {
        ...steps.description,
        error: stringUtils.isNullOrWhiteSpace(addOvenData.description),
      },
    }));
    validateSerialNumber(event.target.value);
  }

  function handleChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    setAddOvenData((addOvenData) => ({...addOvenData, description: event.target.value}));
    setSteps((steps) => ({
      ...steps,
      description: {visible: true, error: stringUtils.isNullOrWhiteSpace(event.target.value)},
    }));
  }

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: 2}}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
        <Box
          sx={{opacity: selectedStep === 'serialNumber' ? 1 : 0.5}}
          onClick={() => setSelectedStep('serialNumber')}>
          <Typography sx={{color: 'text.primary'}} variant="body2">
            {t('add_oven_oven_serial_number_label')}
          </Typography>
          <InputBase
            sx={{
              width: '25vw',
              marginTop: 2,
              fontSize: '0.875rem',
              color: 'text.primary',
              borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
            }}
            value={addOvenData.serialNumber}
            onChange={handleChangeSerialNumber}
            endAdornment={
              stringUtils.isNullOrWhiteSpace(addOvenData.serialNumber) ? undefined : (
                <Icon
                  IconComponent={steps.serialNumber.error ? DeclineSimpleIcon : AcceptSimpleIcon}
                  color="custom.grey"
                  size="16px"
                />
              )
            }
          />
          <Box sx={{minHeight: '22px', textAlign: 'end'}}>
            {!stringUtils.isNullOrWhiteSpace(addOvenData.serialNumber) && steps.serialNumber.error && (
              <Typography sx={{color: 'primary.main'}} variant="caption">
                {t('add_oven_invalid_code_label')}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: steps.description.visible ? 'block' : 'none',
            opacity: selectedStep === 'description' ? 1 : 0.5,
          }}
          onClick={() => setSelectedStep('description')}>
          <Typography sx={{color: 'text.primary'}} variant="body2">
            {t('add_oven_oven_description_label')}
          </Typography>
          <InputBase
            sx={{
              width: '25vw',
              marginTop: 2,
              fontSize: '0.875rem',
              color: 'text.primary',
              borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
            }}
            value={addOvenData.description}
            onChange={handleChangeDescription}
          />
        </Box>
      </Box>
      {oven != null && (
        <Image
          sx={{width: '200px', margin: 2}}
          src={oven.ovenPanels?.length === 4 ? Compactram4cImage : Compactram3cImage}
          alt="Compactram"
        />
      )}
    </Box>
  );
}

export default CompactramSerialNumberStep;
