import Oven from '../../../../../models/entities/oven';
import OvenChamber from '../../../../../models/entities/oven-chamber';
import {OvenModelId} from '../../../../../models/entities/oven-model';
import {OvenSubModelId} from '../../../../../models/entities/oven-sub-model';
import {AddOvenData, AddOvenStep, AddOvenSteps} from '../../AddOven';
import CompactramSerialNumberStep from './Compactram';
import ElectramSerialNumberStep from './Electram';
import ModulramSerialNumberStep from './Modulram';
import RotoramSerialNumberStep from './Rotoram';
import TurboramCombiSerialNumberStep from './TurboramCombi';
import TurboramSingleSerialNumberStep from './TurboramSingle';

export type SerialNumberStepProps = {
  ovens: Oven[];
  ovenChambers: OvenChamber[];
  steps: AddOvenSteps;
  setSteps: (setter: (steps: AddOvenSteps) => AddOvenSteps) => void;
  selectedStep: AddOvenStep;
  setSelectedStep: (step: AddOvenStep) => void;
  addOvenData: AddOvenData;
  setAddOvenData: (setter: (data: AddOvenData) => AddOvenData) => void;
};

function SerialNumberStep(props: SerialNumberStepProps) {
  const {addOvenData} = props;

  if (
    addOvenData.ovenModelId === OvenModelId.Turboram &&
    (addOvenData.ovenSubModelId === OvenSubModelId.TurboramCombi ||
      addOvenData.ovenSubModelId === OvenSubModelId.TurboramPlusHomeModule)
  ) {
    return <TurboramCombiSerialNumberStep {...props} />;
  }

  if (addOvenData.ovenModelId === OvenModelId.Turboram && addOvenData.ovenSubModelId == null) {
    return <TurboramSingleSerialNumberStep {...props} />;
  }

  if (addOvenData.ovenModelId === OvenModelId.Rotoram) {
    return <RotoramSerialNumberStep {...props} />;
  }

  if (addOvenData.ovenModelId === OvenModelId.Modulram) {
    return <ModulramSerialNumberStep {...props} />;
  }

  if (addOvenData.ovenModelId === OvenModelId.Electram) {
    return <ElectramSerialNumberStep {...props} />;
  }

  return <CompactramSerialNumberStep {...props} />;
}

export default SerialNumberStep;
