import Notification from '../models/entities/notification';
import UserNotificationType from '../models/entities/user-notification-type';

const maintenanceNotificationIds: number[] = [];

const notificationUtils = {
  isWarning: function (notification: Notification) {
    return !maintenanceNotificationIds.includes(notification.notificationTypeId);
  },
  isMaintenance: function (notification: Notification) {
    return maintenanceNotificationIds.includes(notification.notificationTypeId);
  },
  getSettingsLabel: function (userNotificationType: UserNotificationType) {
    switch (userNotificationType.notificationTypeId) {
      case 1:
        return 'notification_settings_oven_panel_turned_on';
      case 2:
        return 'notification_settings_oven_panel_turned_off';
      case 3:
        return 'notification_settings_oven_panel_started_recipe';
      case 4:
        return 'notification_settings_oven_panel_finished_recipe';
      case 5:
        return 'notification_settings_oven_panel_started_washing';
      case 6:
        return 'notification_settings_oven_panel_finished_washing';
      case 7:
        return 'notification_settings_oven_panel_started_sync';
      case 8:
        return 'notification_settings_oven_panel_finished_sync';
      case 9:
        return 'notification_settings_oven_panel_failed_sync';
      case 10:
        return 'notification_settings_oven_panel_started_app_update';
      case 11:
        return 'notification_settings_oven_panel_finished_app_update';
      case 12:
        return 'notification_settings_oven_panel_failed_app_update';
      case 13:
        return 'notification_settings_oven_panel_started_overheating';
      case 14:
        return 'notification_settings_oven_panel_stopped_overheating';
      case 15:
        return 'notification_settings_oven_panel_started_turbine_overheating';
      case 16:
        return 'notification_settings_oven_panel_stopped_turbine_overheating';
      case 17:
        return 'notification_settings_oven_panel_started_power_failure';
      case 18:
        return 'notification_settings_oven_panel_stopped_power_failure';
      case 19:
        return 'notification_settings_oven_panel_settings_changed';
      default:
        return '';
    }
  },
  getLabel: function (notification: Notification) {
    switch (notification.notificationTypeId) {
      case 1:
        return 'notification_oven_panel_turned_on';
      case 2:
        return 'notification_oven_panel_turned_off';
      case 3:
        return 'notification_oven_panel_started_recipe';
      case 4:
        return 'notification_oven_panel_finished_recipe';
      case 5:
        return 'notification_oven_panel_started_washing';
      case 6:
        return 'notification_oven_panel_finished_washing';
      case 7:
        return 'notification_oven_panel_started_sync';
      case 8:
        return 'notification_oven_panel_finished_sync';
      case 9:
        return 'notification_oven_panel_failed_sync';
      case 10:
        return 'notification_oven_panel_started_app_update';
      case 11:
        return 'notification_oven_panel_finished_app_update';
      case 12:
        return 'notification_oven_panel_failed_app_update';
      case 13:
        return 'notification_oven_panel_started_overheating';
      case 14:
        return 'notification_oven_panel_stopped_overheating';
      case 15:
        return 'notification_oven_panel_started_turbine_overheating';
      case 16:
        return 'notification_oven_panel_stopped_turbine_overheating';
      case 17:
        return 'notification_oven_panel_started_power_failure';
      case 18:
        return 'notification_oven_panel_stopped_power_failure';
      case 19:
        return 'notification_oven_panel_settings_changed';
      default:
        return notification.notificationTypeDescription;
    }
  },
};

export default notificationUtils;
