import {Box, Typography} from '@mui/material';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/oven-chamber';
import OvenGroup from '../../models/entities/oven-group';
import OvenModel, {OvenModelId} from '../../models/entities/oven-model';
import paths from '../../routes/paths';
import services from '../../services/provider';
import routerUtils from '../../utils/router';
import GradientOverflow from '../common/GradientOverflow';
import {pageHeight} from '../navigation/Navbar';
import OvenList from './OvenList';
import OvenModelList from './OvenModelList';
import {OvenPanelMenuAction} from './OvenPanelMenu';

type BakeryOvensProps = {
  ovenModels: OvenModel[];
  selectedCountryId?: number;
  selectedCityId?: number;
  selectedBakeryId?: string;
  selectedOvenModel?: OvenModel;
  selectedOvenGroupId?: string;
  selectedOvenId?: string;
  onSelectOvenModel: (ovenModelId: OvenModelId) => void;
  onSelectOvenGroup: (ovenGroupId: string) => void;
  onSelectOven: (ovenId: string) => void;
  onDeleteOvenGroup: (ovenGroup: OvenGroup) => void;
  onDeleteOven: (oven: Oven) => void;
  onDeleteOvenChamber: (ovenChamber: OvenChamber) => void;
};

function BakeryOvens(props: BakeryOvensProps) {
  const {
    ovenModels,
    selectedCountryId,
    selectedCityId,
    selectedBakeryId,
    selectedOvenModel,
    selectedOvenGroupId,
    selectedOvenId,
    onSelectOvenModel,
    onSelectOvenGroup,
    onSelectOven,
    onDeleteOvenGroup,
    onDeleteOven,
    onDeleteOvenChamber,
  } = props;

  const navigate = useNavigate();
  const {t} = useTranslation();

  const {mutate: changeOvenPanelStatus} = useMutation({
    mutationFn: services.ovenPanel.changeOvenPanelStatus,
  });

  function handleActionClick(
    ovenId: string,
    ovenPanelId: string,
    action: OvenPanelMenuAction,
    ovenChamberId?: string,
    ovenGroupId?: string,
  ) {
    if (action === OvenPanelMenuAction.Status) {
      changeOvenPanelStatus({
        params: {ovenPanelId},
        request: {action: 'TOGGLE'},
      });
      return;
    }
    if (action === OvenPanelMenuAction.Recipes) {
      let path = paths.recipes;
      if (selectedOvenModel != null) {
        path += `/${selectedOvenModel.id}`;
      }
      navigate(path);
      return;
    }
    if (action === OvenPanelMenuAction.Programming) {
      let path = paths.programming;
      if (selectedCountryId != null) {
        path += `/${selectedCountryId}`;
        if (selectedCityId != null) {
          path += `/${selectedCityId}`;
          if (selectedBakeryId != null) {
            path += `/${selectedBakeryId}`;
            if (selectedOvenModel != null) {
              path += `/${selectedOvenModel.id}`;
            }
          }
        }
      }
      navigate(path);
      return;
    }
    if (action === OvenPanelMenuAction.Statistics) {
      let path = paths.statistics;
      if (selectedCountryId != null) {
        path += `/${selectedCountryId}`;
        if (selectedCityId != null) {
          path += `/${selectedCityId}`;
          if (selectedBakeryId != null) {
            path += `/${selectedBakeryId}`;
            if (selectedOvenModel != null) {
              path += `/${selectedOvenModel.id}`;
            }
          }
        }
      }
      navigate({
        pathname: path,
        search: routerUtils
          .createSearchParams({ovenGroupId, ovenId, ovenChamberId, ovenPanelId})
          .toString(),
      });
      return;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: `calc(${pageHeight} - 112px)`,
        paddingBottom: 6,
      }}>
      {ovenModels.length === 0 ? (
        <Box sx={{width: '65vw', padding: 2}}>
          <Typography variant="body2" color="secondary">
            {t('bakery_ovens_not_found_label')}
          </Typography>
        </Box>
      ) : (
        <Fragment>
          <Box sx={{width: '150px', marginTop: 2}}>
            <GradientOverflow>
              <OvenModelList
                ovenModels={ovenModels}
                selectedOvenModelId={selectedOvenModel?.id ?? undefined}
                onSelectOvenModel={onSelectOvenModel}
              />
            </GradientOverflow>
          </Box>
          <OvenList
            ovenGroups={selectedOvenModel?.ovenGroups ?? []}
            ovens={selectedOvenModel?.ovens?.filter((oven) => oven.ovenGroupId == null) ?? []}
            selectedOvenGroupId={selectedOvenGroupId ?? ''}
            selectedOvenId={selectedOvenId ?? ''}
            onSelectOvenGroup={onSelectOvenGroup}
            onSelectOven={onSelectOven}
            onActionClick={handleActionClick}
            onDeleteOvenGroup={onDeleteOvenGroup}
            onDeleteOven={onDeleteOven}
            onDeleteOvenChamber={onDeleteOvenChamber}
          />
        </Fragment>
      )}
    </Box>
  );
}

export default BakeryOvens;
