import Recipe from '../../models/entities/recipe';
import {
  CreateRecipeRequest,
  DeleteRecipeParams,
  GetRecipeParams,
  GetRecipeQuery,
  GetRecipesQuery,
  UpdateRecipeParams,
  UpdateRecipeRequest,
} from '../../models/requests/recipe';
import {
  CreateRecipeResponse,
  DeleteRecipeResponse,
  GetRecipeResponse,
  GetRecipesResponse,
  UpdateRecipeResponse,
} from '../../models/responses/recipe';
import {ApplicationError} from '../../utils/errors';
import {RecipeService} from '../recipe';
import {data} from './data';

const mockRecipeService: RecipeService = {
  getRecipes: function (args: {query?: GetRecipesQuery}): Promise<GetRecipesResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const recipes = data.recipes
          .filter(
            (recipe) => query?.ovenModelId == null || recipe.ovenModelId === query.ovenModelId,
          )
          .map((recipe) => ({
            ...recipe,
            recipePhases: data.recipePhases
              .filter((recipePhase) => recipePhase.recipeId === recipe.id)
              .map((recipePhase) => ({...recipePhase})),
            recipeTypes: data.recipeTypes
              .filter((recipeType) =>
                data.recipeRecipeTypes.some(
                  (recipeRecipeType) =>
                    recipeRecipeType.recipeTypeId === recipeType.id &&
                    recipeRecipeType.recipeId === recipe.id,
                ),
              )
              .map((recipeType) => ({...recipeType})),
            bakeries: data.bakeries
              .filter((bakery) =>
                data.ovenPanels.some(
                  (ovenPanel) =>
                    ovenPanel.bakeryId === bakery.id &&
                    data.ovenPanelRecipes.some(
                      (ovenPanelRecipe) =>
                        ovenPanelRecipe.ovenPanelId === ovenPanel.id &&
                        ovenPanelRecipe.recipeId === recipe.id,
                    ),
                ),
              )
              .map((bakery) => ({...bakery})),
          }));

        resolve(recipes);
      }, 500),
    );
  },
  getRecipe: function (args: {
    params: GetRecipeParams;
    query: GetRecipeQuery;
  }): Promise<GetRecipeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const recipe = data.recipes.find((recipe) => recipe.id === params.recipeId);

        if (recipe == null) {
          reject(ApplicationError.notFound('Recipe'));
          return;
        }

        resolve({
          ...recipe,
          recipePhases: data.recipePhases
            .filter((recipePhase) => recipePhase.recipeId === recipe.id)
            .map((recipePhase) => ({...recipePhase})),
          recipeTypes: data.recipeTypes
            .filter((recipeType) =>
              data.recipeRecipeTypes.some(
                (recipeRecipeType) =>
                  recipeRecipeType.recipeTypeId === recipeType.id &&
                  recipeRecipeType.recipeId === recipe.id,
              ),
            )
            .map((recipeType) => ({...recipeType})),
          bakeries: data.bakeries
            .filter((bakery) =>
              data.ovenPanels.some(
                (ovenPanel) =>
                  ovenPanel.bakeryId === bakery.id &&
                  data.ovenPanelRecipes.some(
                    (ovenPanelRecipe) =>
                      ovenPanelRecipe.ovenPanelId === ovenPanel.id &&
                      ovenPanelRecipe.recipeId === recipe.id,
                  ),
              ),
            )
            .map((bakery) => ({...bakery})),
        });

        resolve(recipe);
      }, 500),
    );
  },
  createRecipe: function (args: {request: CreateRecipeRequest}): Promise<CreateRecipeResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {request} = args;

        const recipe: Recipe = {
          id: request.id,
          name: request.name,
          nameEn: request.nameEn,
          nameEs: request.nameEs,
          namePt: request.namePt,
          nameFr: request.nameFr,
          namePl: request.namePl,
          description: request.description,
          procedure: request.procedure,
          ovenModelId: request.ovenModelId,
          ovenSubModelId: request.ovenSubModelId,
          recipeSchemaId: request.recipeSchemaId,
          companyId: request.companyId,
          modifiedAt: new Date().toISOString(),
        };

        data.recipes.push(recipe);

        request.recipePhases?.forEach((requestRecipePhase) =>
          data.recipePhases.push({
            id: requestRecipePhase.id,
            index: requestRecipePhase.index,
            duration: requestRecipePhase.duration,
            temperature: requestRecipePhase.temperature,
            topTemperature: requestRecipePhase.topTemperature,
            floorTemperature: requestRecipePhase.floorTemperature,
            topPower: requestRecipePhase.topPower,
            floorPower: requestRecipePhase.floorPower,
            steamInjectionNumber: requestRecipePhase.steamInjectionNumber,
            steamInjectionDuration: requestRecipePhase.steamInjectionDuration,
            steamExitValveOpened: requestRecipePhase.steamExitValveOpened,
            turbineSpeed: requestRecipePhase.turbineSpeed,
            turbineRestSeconds: requestRecipePhase.turbineRestSeconds,
            recipeId: recipe.id,
            companyId: recipe.companyId,
          }),
        );

        request.recipeTypes?.forEach((requestRecipeType) =>
          data.recipeRecipeTypes.push({
            recipeId: recipe.id,
            recipeTypeId: requestRecipeType.id,
          }),
        );

        request.bakeries?.forEach((requestBakery) => {
          const ovenPanels = data.ovenPanels.filter(
            (ovenPanel) => ovenPanel.bakeryId === requestBakery.id,
          );

          ovenPanels.forEach((ovenPanel) => {
            request.recipeTypes?.forEach((recipeType) => {
              if (
                !data.ovenPanelRecipeTypes.some(
                  (ovenPanelRecipeType) =>
                    ovenPanelRecipeType.ovenPanelId === ovenPanel.id &&
                    ovenPanelRecipeType.recipeTypeId === recipeType.id,
                )
              ) {
                data.ovenPanelRecipeTypes.push({
                  ovenPanelId: ovenPanel.id,
                  recipeTypeId: recipeType.id,
                });
              }
            });
          });

          ovenPanels.forEach((ovenPanel) =>
            data.ovenPanelRecipes.push({ovenPanelId: ovenPanel.id, recipeId: recipe.id}),
          );
        });

        resolve(recipe.id);
      }, 500),
    );
  },
  updateRecipe: function (args: {
    params: UpdateRecipeParams;
    request: UpdateRecipeRequest;
  }): Promise<UpdateRecipeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        const recipe = data.recipes.find((recipe) => recipe.id === params.recipeId);

        if (recipe == null) {
          reject(ApplicationError.notFound('Recipe'));
          return;
        }

        recipe.name = request.name;
        recipe.nameEn = request.nameEn;
        recipe.nameEs = request.nameEs;
        recipe.namePt = request.namePt;
        recipe.nameFr = request.nameFr;
        recipe.namePl = request.namePl;
        recipe.description = request.description;
        recipe.procedure = request.procedure;
        recipe.modifiedAt = new Date().toISOString();

        if (request.recipePhases != null) {
          data.recipePhases = data.recipePhases.filter(
            (recipePhase) => recipePhase.recipeId !== recipe.id,
          );

          request.recipePhases?.forEach((requestRecipePhase) =>
            data.recipePhases.push({
              id: requestRecipePhase.id,
              index: requestRecipePhase.index,
              duration: requestRecipePhase.duration,
              temperature: requestRecipePhase.temperature,
              topTemperature: requestRecipePhase.topTemperature,
              floorTemperature: requestRecipePhase.floorTemperature,
              topPower: requestRecipePhase.topPower,
              floorPower: requestRecipePhase.floorPower,
              steamInjectionNumber: requestRecipePhase.steamInjectionNumber,
              steamInjectionDuration: requestRecipePhase.steamInjectionDuration,
              steamExitValveOpened: requestRecipePhase.steamExitValveOpened,
              turbineSpeed: requestRecipePhase.turbineSpeed,
              turbineRestSeconds: requestRecipePhase.turbineRestSeconds,
              recipeId: recipe.id,
              companyId: recipe.companyId,
            }),
          );
        }

        if (request.recipeTypes != null) {
          data.recipeRecipeTypes = data.recipeRecipeTypes.filter(
            (recipeRecipeType) => recipeRecipeType.recipeId !== recipe.id,
          );

          request.recipeTypes?.forEach((requestRecipeType) =>
            data.recipeRecipeTypes.push({
              recipeId: recipe.id,
              recipeTypeId: requestRecipeType.id,
            }),
          );
        }

        if (request.bakeries != null) {
          data.ovenPanelRecipes = data.ovenPanelRecipes.filter(
            (ovenPanelRecipe) => ovenPanelRecipe.recipeId !== recipe.id,
          );

          request.bakeries?.forEach((requestBakery) => {
            const ovenPanels = data.ovenPanels.filter(
              (ovenPanel) => ovenPanel.bakeryId === requestBakery.id,
            );

            ovenPanels.forEach((ovenPanel) => {
              request.recipeTypes?.forEach((recipeType) => {
                if (
                  !data.ovenPanelRecipeTypes.some(
                    (ovenPanelRecipeType) =>
                      ovenPanelRecipeType.ovenPanelId === ovenPanel.id &&
                      ovenPanelRecipeType.recipeTypeId === recipeType.id,
                  )
                ) {
                  data.ovenPanelRecipeTypes.push({
                    ovenPanelId: ovenPanel.id,
                    recipeTypeId: recipeType.id,
                  });
                }
              });
            });

            ovenPanels.forEach((ovenPanel) =>
              data.ovenPanelRecipes.push({ovenPanelId: ovenPanel.id, recipeId: recipe.id}),
            );
          });
        }

        resolve(recipe.id);
      }, 500),
    );
  },
  deleteRecipe: function (args: {params: DeleteRecipeParams}): Promise<DeleteRecipeResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const recipe = data.recipes.find((recipe) => recipe.id === params.recipeId);

        if (recipe == null) {
          reject(ApplicationError.notFound('Recipe'));
          return;
        }

        data.ovenPanelSchedules = data.ovenPanelSchedules.filter(
          (ovenPanelSchedule) =>
            !data.schedules.some(
              (schedule) =>
                schedule.id === ovenPanelSchedule.scheduleId &&
                schedule.recipeId === params.recipeId,
            ),
        );

        data.schedules = data.schedules.filter((schedule) => schedule.recipeId !== params.recipeId);

        data.ovenPanelRecipes = data.ovenPanelRecipes.filter(
          (ovenPanelRecipe) => ovenPanelRecipe.recipeId !== params.recipeId,
        );

        data.recipeRecipeTypes = data.recipeRecipeTypes.filter(
          (recipeRecipeType) => recipeRecipeType.recipeId !== params.recipeId,
        );

        data.recipePhases = data.recipePhases.filter(
          (recipePhase) => recipePhase.recipeId !== params.recipeId,
        );

        data.recipes = data.recipes.filter((recipe) => recipe.id !== params.recipeId);

        resolve(params.recipeId);
      }, 500),
    );
  },
};

export default mockRecipeService;
