import {Box} from '@mui/material';
import {Schema, Validator} from 'jsonschema';
import {useTranslation} from 'react-i18next';
import {OvenModelId} from '../../../../models/entities/oven-model';
import Recipe from '../../../../models/entities/recipe';
import RecipePhase from '../../../../models/entities/recipe-phase';
import numberUtils from '../../../../utils/numbers';
import {getEmptyRecipePhase} from '../RecipeSettings';
import DurationRow from './phase/DurationRow';
import PowerRow from './phase/PowerRow';
import SteamExitValveOpenedRow from './phase/SteamExitValveOpenedRow';
import SteamInjectionDurationRow from './phase/SteamInjectionDurationRow';
import SteamInjectionNumberRow from './phase/SteamInjectionNumberRow';
import TemperatureRow from './phase/TemperatureRow';
import TurbineRestSecondsRow from './phase/TurbineRestSecondsRow';
import TurbineSpeedRow from './phase/TurbineSpeedRow';

function hasError(recipePhase: RecipePhase, recipePhaseSchema: Schema, parameter: string) {
  const errors = new Validator().validate(recipePhase, recipePhaseSchema).errors;
  return errors.some((error) => error.property === `instance.${parameter}`);
}

export type PhaseStepProps = {
  selectedRecipePhaseIndex: number;
  recipePhaseSchema: Schema;
  recipeBuild: Recipe;
  setRecipeBuild: (setter: (previousValue: Recipe) => Recipe) => void;
};

function PhaseStep(props: PhaseStepProps) {
  const {selectedRecipePhaseIndex, recipePhaseSchema, recipeBuild, setRecipeBuild} = props;
  const selectedRecipePhase =
    recipeBuild.recipePhases?.[selectedRecipePhaseIndex] ??
    getEmptyRecipePhase(1, recipeBuild.id, recipeBuild.companyId);

  const {t} = useTranslation();

  function handleChangeTemperature(temperature: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        temperature:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(temperature)
            : recipePhase.temperature,
      })),
    }));
  }

  function handleChangeTopTemperature(topTemperature: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        topTemperature:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(topTemperature)
            : recipePhase.topTemperature,
      })),
    }));
  }

  function handleChangeFloorTemperature(floorTemperature: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        floorTemperature:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(floorTemperature)
            : recipePhase.floorTemperature,
      })),
    }));
  }

  function handleChangeTopPower(topPower: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        topPower: index === selectedRecipePhaseIndex ? topPower : recipePhase.topPower,
      })),
    }));
  }

  function handleChangeFloorPower(floorPower: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        floorPower: index === selectedRecipePhaseIndex ? floorPower : recipePhase.floorPower,
      })),
    }));
  }

  function handleChangeDuration(duration: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        duration:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(duration) * 60
            : recipePhase.duration,
      })),
    }));
  }

  function handleChangeSteamInjectionNumber(steamInjectionNumber: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        steamInjectionNumber:
          index === selectedRecipePhaseIndex
            ? steamInjectionNumber
            : recipePhase.steamInjectionNumber,
      })),
    }));
  }

  function handleChangeSteamInjectionDuration(steamInjectionDuration: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        steamInjectionDuration:
          index === selectedRecipePhaseIndex
            ? steamInjectionDuration * 60
            : recipePhase.steamInjectionDuration,
      })),
    }));
  }

  function handleChangeSteamExitValveOpened(steamExitValveOpened: boolean) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        steamExitValveOpened:
          index === selectedRecipePhaseIndex
            ? steamExitValveOpened
            : recipePhase.steamExitValveOpened,
      })),
    }));
  }

  function handleChangeTurbineSpeed(turbineSpeed: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        turbineSpeed: index === selectedRecipePhaseIndex ? turbineSpeed : recipePhase.turbineSpeed,
      })),
    }));
  }

  function handleChangeTurbineRestSeconds(turbineRestSeconds: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        turbineRestSeconds:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(turbineRestSeconds)
            : recipePhase.turbineRestSeconds,
      })),
    }));
  }

  function renderParameters() {
    switch (recipeBuild.ovenModelId) {
      case OvenModelId.Compactram:
      case OvenModelId.Electram:
      case OvenModelId.Modulram: {
        return (
          <Box sx={{width: '65vw'}}>
            <TemperatureRow
              label={t('recipe_settings_phases_top_temperature_label')}
              message={t('recipe_settings_phases_top_temperature_message')}
              temperature={(selectedRecipePhase?.topTemperature ?? 0).toString()}
              onChange={handleChangeTopTemperature}
              error={hasError(selectedRecipePhase, recipePhaseSchema, 'topTemperature')}
            />
            <TemperatureRow
              label={t('recipe_settings_phases_floor_temperature_label')}
              message={t('recipe_settings_phases_floor_temperature_message')}
              temperature={(selectedRecipePhase?.floorTemperature ?? 0).toString()}
              onChange={handleChangeFloorTemperature}
              error={hasError(selectedRecipePhase, recipePhaseSchema, 'floorTemperature')}
            />
            <PowerRow
              label={t('recipe_settings_phases_top_power_label')}
              message={t('recipe_settings_phases_top_power_message')}
              power={selectedRecipePhase?.topPower ?? 0}
              onChange={handleChangeTopPower}
            />
            <PowerRow
              label={t('recipe_settings_phases_floor_power_label')}
              message={t('recipe_settings_phases_floor_power_message')}
              power={selectedRecipePhase?.floorPower ?? 0}
              onChange={handleChangeFloorPower}
            />
            <DurationRow
              duration={(selectedRecipePhase.duration / 60).toString()}
              onChange={(value) => handleChangeDuration(value)}
              error={hasError(selectedRecipePhase, recipePhaseSchema, 'duration')}
            />
            <SteamInjectionDurationRow
              steamInjectionDuration={(selectedRecipePhase.steamInjectionDuration ?? 0) / 60}
              onChange={handleChangeSteamInjectionDuration}
            />
            <SteamExitValveOpenedRow
              steamExitValveOpened={selectedRecipePhase.steamExitValveOpened ?? false}
              onChange={handleChangeSteamExitValveOpened}
            />
          </Box>
        );
      }
      case OvenModelId.Rotoram: {
        return (
          <Box sx={{width: '65vw'}}>
            <TemperatureRow
              label={t('recipe_settings_phases_temperature_label')}
              message={t('recipe_settings_phases_temperature_message')}
              temperature={(selectedRecipePhase?.temperature ?? 0).toString()}
              onChange={handleChangeTemperature}
              error={hasError(selectedRecipePhase, recipePhaseSchema, 'temperature')}
            />
            <DurationRow
              duration={(selectedRecipePhase.duration / 60).toString()}
              onChange={(value) => handleChangeDuration(value)}
              error={hasError(selectedRecipePhase, recipePhaseSchema, 'duration')}
            />
            <SteamInjectionNumberRow
              steamInjectionNumber={selectedRecipePhase.steamInjectionNumber ?? 0}
              onChange={handleChangeSteamInjectionNumber}
            />
            <SteamExitValveOpenedRow
              steamExitValveOpened={selectedRecipePhase.steamExitValveOpened ?? false}
              onChange={handleChangeSteamExitValveOpened}
            />
            <TurbineRestSecondsRow
              turbineRestSeconds={(selectedRecipePhase?.turbineRestSeconds ?? 0).toString()}
              onChange={handleChangeTurbineRestSeconds}
              error={hasError(selectedRecipePhase, recipePhaseSchema, 'turbineRestSeconds')}
            />
          </Box>
        );
      }
      case OvenModelId.Turboram: {
        return (
          <Box sx={{width: '65vw'}}>
            <TemperatureRow
              label={t('recipe_settings_phases_temperature_label')}
              message={t('recipe_settings_phases_temperature_message')}
              temperature={(selectedRecipePhase?.temperature ?? 0).toString()}
              onChange={handleChangeTemperature}
              error={hasError(selectedRecipePhase, recipePhaseSchema, 'temperature')}
            />
            <DurationRow
              duration={(selectedRecipePhase.duration / 60).toString()}
              onChange={(value) => handleChangeDuration(value)}
              error={hasError(selectedRecipePhase, recipePhaseSchema, 'duration')}
            />
            <SteamInjectionNumberRow
              steamInjectionNumber={selectedRecipePhase.steamInjectionNumber ?? 0}
              onChange={handleChangeSteamInjectionNumber}
            />
            <SteamExitValveOpenedRow
              steamExitValveOpened={selectedRecipePhase.steamExitValveOpened ?? false}
              onChange={handleChangeSteamExitValveOpened}
            />
            <TurbineSpeedRow
              turbineSpeed={selectedRecipePhase.turbineSpeed ?? 0}
              onChange={handleChangeTurbineSpeed}
            />
          </Box>
        );
      }
    }
  }

  return <Box>{renderParameters()}</Box>;
}

export default PhaseStep;
