import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import useLanguage from '../../hooks/common/use-language';
import SimpleList from '../common/SimpleList';
import {pageHeight} from '../navigation/Navbar';

function LanguageSettings() {
  const {t} = useTranslation();
  const [language, setLanguage] = useLanguage();

  return (
    <Box
      sx={{
        height: `calc(${pageHeight} - 112px)`,
        padding: 2,
        paddingBottom: 6,
      }}>
      <SimpleList
        options={['en', 'pt'].map((language) => ({
          id: language,
          primary: t(language),
        }))}
        selectedOption={language}
        onChangeSelectedOption={setLanguage}
      />
    </Box>
  );
}

export default LanguageSettings;
