import {GetCitiesQuery, GetCityParams} from '../models/requests/city';
import {GetCitiesResponse, GetCityResponse} from '../models/responses/city';
import baseService from './base';
import endpoints from './endpoints';

export type CityService = {
  getCities: (args: {query?: GetCitiesQuery}) => Promise<GetCitiesResponse>;
  getCity: (args: {params: GetCityParams}) => Promise<GetCityResponse>;
};

const cityService: CityService = {
  getCities: async function (args: {query?: GetCitiesQuery}): Promise<GetCitiesResponse> {
    const {query} = args;

    const result = await baseService.get<GetCitiesResponse, GetCitiesQuery>(
      endpoints.cities,
      query,
    );

    return result;
  },
  getCity: async function (args: {params: GetCityParams}): Promise<GetCityResponse> {
    const {params} = args;

    const result = await baseService.get<GetCityResponse>(`${endpoints.cities}/${params.cityId}`);

    return result;
  },
};

export default cityService;
