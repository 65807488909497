import i18next from 'i18next';
import {logout} from '../state/auth';
import useErrorStore from '../state/errors';

export enum ApplicationErrorCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  InvalidEmailOrPassword = 10000,
  InvalidEmailOrRecoveryCode = 10001,
  InvalidInviteCode = 10002,
  ScheduleOverlapping = 10003,
  InvalidModifiedAt = 10004,
}

export class ApplicationError extends Error {
  code: ApplicationErrorCode;
  messages: string[];

  constructor(code: number, messages: string[]) {
    super();
    this.code = code;
    this.messages = messages;
  }

  toString() {
    return this.messages.join(';');
  }

  static badRequest(messages?: string[]) {
    return new ApplicationError(ApplicationErrorCode.BadRequest, messages ?? ['Bad request.']);
  }

  static unauthorized(message: string) {
    return new ApplicationError(ApplicationErrorCode.Unauthorized, [`Unauthorized (${message}).`]);
  }

  static forbidden() {
    return new ApplicationError(ApplicationErrorCode.Forbidden, ['Access forbidden.']);
  }

  static notFound(resourceName: string) {
    return new ApplicationError(ApplicationErrorCode.NotFound, [`${resourceName} not found.`]);
  }

  static internalError(message?: string | string[]) {
    const messages = Array.isArray(message) ? message : [message ?? 'Internal server error.'];
    return new ApplicationError(ApplicationErrorCode.InternalServerError, messages);
  }

  static invalidEmailOrPassword() {
    return new ApplicationError(ApplicationErrorCode.InvalidEmailOrPassword, [
      'Invalid email or password.',
    ]);
  }

  static invalidEmailOrRecoveryCode() {
    return new ApplicationError(ApplicationErrorCode.InvalidEmailOrRecoveryCode, [
      'Invalid email or recovery code.',
    ]);
  }

  static invalidInviteCode() {
    return new ApplicationError(ApplicationErrorCode.InvalidInviteCode, ['Invalid invite code.']);
  }

  static scheduleOverlapping() {
    return new ApplicationError(ApplicationErrorCode.ScheduleOverlapping, [
      'Schedule overlapping.',
    ]);
  }
}

const errorUtils = {
  applicationError: function (error: any) {
    if (error instanceof ApplicationError) {
      return error;
    }
    return new ApplicationError(
      error.code ?? 500,
      error.messages ?? [error.message ?? 'Unknown error.'],
    );
  },
  match: function (error: ApplicationError, codes: ApplicationErrorCode[]) {
    return codes.some((code) => error.code === code);
  },
  setErrors: function (error: ApplicationError | string[]) {
    if (error instanceof ApplicationError) {
      useErrorStore.setState({errors: error.messages});
      return;
    }
    useErrorStore.setState({errors: error});
  },
  setError: function (error: string) {
    useErrorStore.setState({errors: [error]});
  },
  clearErrors: function () {
    useErrorStore.setState({errors: []});
  },
  handleMatch: function (
    error: any,
    codes: ApplicationErrorCode[],
    onMatch: (error: ApplicationError) => void,
  ) {
    const applicationError = this.applicationError(error);
    if (this.match(applicationError, codes)) {
      onMatch(applicationError);
      return;
    }
    useErrorStore.setState({errors: applicationError.messages});
  },
  handleError: function (error: any, onError?: (error: ApplicationError) => void) {
    const applicationError = this.applicationError(error);
    if (applicationError.code === ApplicationErrorCode.Unauthorized) {
      logout();
      useErrorStore.setState({
        errors: [i18next.t('authorization_token_expired_error')],
      });
      return;
    }
    if (applicationError.code === ApplicationErrorCode.InternalServerError) {
      applicationError.messages = [i18next.t('general_error')];
      applicationError.message = i18next.t('general_error');
    }
    if (onError) {
      onError(applicationError);
      return;
    }
    useErrorStore.setState({errors: applicationError.messages});
  },
};

export default errorUtils;
