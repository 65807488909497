import Bakery from '../../models/entities/bakery';
import {
  CreateBakeryRequest,
  DeleteBakeryParams,
  GetBakeryOvenModelParams,
  GetBakeryOvenModelQuery,
  GetBakeryOvenModelsParams,
  GetBakeryOvenModelsQuery,
  GetBakeryParams,
  SyncBakeryParams,
  UpdateBakeryParams,
  UpdateBakeryRequest,
} from '../../models/requests/bakery';
import {
  CreateBakeryResponse,
  DeleteBakeryResponse,
  GetBakeryOvenModelResponse,
  GetBakeryOvenModelsResponse,
  GetBakeryResponse,
  SyncBakeryResponse,
  UpdateBakeryResponse,
} from '../../models/responses/bakery';
import {ApplicationError} from '../../utils/errors';
import {BakeryService} from '../bakery';
import {data} from './data';

const mockBakeryService: BakeryService = {
  getBakery: function (args: {params: GetBakeryParams}): Promise<GetBakeryResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const bakery = data.bakeries.find((bakery) => bakery.id === params.bakeryId);

        if (bakery == null) {
          reject(ApplicationError.notFound('Bakery'));
          return;
        }

        resolve({...bakery});
      }, 500),
    );
  },
  createBakery: function (args: {request: CreateBakeryRequest}): Promise<CreateBakeryResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {request} = args;

        const bakery: Bakery = {
          id: request.id,
          name: request.name,
          cityId: request.cityId,
          companyId: request.companyId,
        };

        data.bakeries.push(bakery);

        resolve(bakery.id);
      }, 500),
    );
  },
  updateBakery: function (args: {
    params: UpdateBakeryParams;
    request: UpdateBakeryRequest;
  }): Promise<UpdateBakeryResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        const bakery = data.bakeries.find((bakery) => bakery.id === params.bakeryId);

        if (bakery == null) {
          reject(ApplicationError.notFound('Bakery'));
          return;
        }

        bakery.name = request.name;

        resolve(bakery.id);
      }, 500),
    );
  },
  deleteBakery: function (args: {params: DeleteBakeryParams}): Promise<DeleteBakeryResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const bakery = data.bakeries.find((bakery) => bakery.id === params.bakeryId);

        if (bakery == null) {
          reject(ApplicationError.notFound('Bakery'));
          return;
        }

        data.bakeries = data.bakeries.filter((bakery) => bakery.id !== params.bakeryId);

        resolve(params.bakeryId);
      }, 500),
    );
  },
  getBakeryOvenModels: function (args: {
    params: GetBakeryOvenModelsParams;
    query?: GetBakeryOvenModelsQuery;
  }): Promise<GetBakeryOvenModelsResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const bakery = data.bakeries.find((bakery) => bakery.id === params.bakeryId);

        if (bakery == null) {
          reject(ApplicationError.notFound('Bakery'));
          return;
        }

        const ovenModelIds = new Set(
          data.ovenPanels
            .filter((ovenPanel) => ovenPanel.bakeryId === params.bakeryId)
            .map((ovenPanel) => ovenPanel.ovenModelId),
        );

        const ovenModels = data.ovenModels
          .filter((ovenModel) => ovenModelIds.has(ovenModel.id))
          .map((ovenModel) => ({
            ...ovenModel,
            ovenGroups: data.ovenGroups
              .filter(
                (ovenGroup) =>
                  ovenGroup.ovenModelId === ovenModel.id && ovenGroup.bakeryId === params.bakeryId,
              )
              .map((ovenGroup) => ({
                ...ovenGroup,
                ovens: data.ovens
                  .filter((oven) => oven.ovenGroupId === ovenGroup.id)
                  .map((oven) => ({
                    ...oven,
                    ovenChambers: data.ovenChambers
                      .filter((ovenChamber) => ovenChamber.ovenId === oven.id)
                      .map((ovenChamber) => ({
                        ...ovenChamber,
                        ovenPanels: data.ovenPanels
                          .filter((ovenPanel) => ovenPanel.ovenChamberId === ovenChamber.id)
                          .map((ovenPanel) => ({...ovenPanel})),
                      })),
                    ovenPanels: data.ovenPanels
                      .filter((ovenPanel) => ovenPanel.ovenId === oven.id)
                      .map((ovenPanel) => ({...ovenPanel})),
                  })),
              })),
            ovens: data.ovens
              .filter(
                (oven) => oven.ovenModelId === ovenModel.id && oven.bakeryId === params.bakeryId,
              )
              .map((oven) => ({
                ...oven,
                ovenChambers: data.ovenChambers
                  .filter((ovenChamber) => ovenChamber.ovenId === oven.id)
                  .map((ovenChamber) => ({
                    ...ovenChamber,
                    ovenPanels: data.ovenPanels
                      .filter((ovenPanel) => ovenPanel.ovenChamberId === ovenChamber.id)
                      .map((ovenPanel) => ({...ovenPanel})),
                  })),
                ovenPanels: data.ovenPanels
                  .filter((ovenPanel) => ovenPanel.ovenId === oven.id)
                  .map((ovenPanel) => ({...ovenPanel})),
              })),
          }));

        resolve(ovenModels);
      }, 500),
    );
  },
  getBakeryOvenModel: function (args: {
    params: GetBakeryOvenModelParams;
    query?: GetBakeryOvenModelQuery;
  }): Promise<GetBakeryOvenModelResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const bakery = data.bakeries.find((bakery) => bakery.id === params.bakeryId);

        if (bakery == null) {
          reject(ApplicationError.notFound('Bakery'));
          return;
        }

        const ovenModelId = data.ovenPanels
          .filter((ovenPanel) => ovenPanel.bakeryId === params.bakeryId)
          .map((ovenPanel) => ovenPanel.ovenModelId)
          .find((ovenModelId) => ovenModelId === params.ovenModelId);

        const ovenModel = data.ovenModels.find((ovenModel) => ovenModel.id === ovenModelId);

        if (ovenModel == null) {
          reject(ApplicationError.notFound('Oven model'));
          return;
        }

        resolve({
          ...ovenModel,
          ovenGroups: data.ovenGroups
            .filter(
              (ovenGroup) =>
                ovenGroup.ovenModelId === ovenModel.id && ovenGroup.bakeryId === params.bakeryId,
            )
            .map((ovenGroup) => ({
              ...ovenGroup,
              ovens: data.ovens
                .filter((oven) => oven.ovenGroupId === ovenGroup.id)
                .map((oven) => ({
                  ...oven,
                  ovenChambers: data.ovenChambers
                    .filter((ovenChamber) => ovenChamber.ovenId === oven.id)
                    .map((ovenChamber) => ({
                      ...ovenChamber,
                      ovenPanels: data.ovenPanels
                        .filter((ovenPanel) => ovenPanel.ovenChamberId === ovenChamber.id)
                        .map((ovenPanel) => ({...ovenPanel})),
                    })),
                  ovenPanels: data.ovenPanels
                    .filter((ovenPanel) => ovenPanel.ovenId === oven.id)
                    .map((ovenPanel) => ({...ovenPanel})),
                })),
            })),
          ovens: data.ovens
            .filter(
              (oven) => oven.ovenModelId === ovenModel.id && oven.bakeryId === params.bakeryId,
            )
            .map((oven) => ({
              ...oven,
              ovenChambers: data.ovenChambers
                .filter((ovenChamber) => ovenChamber.ovenId === oven.id)
                .map((ovenChamber) => ({
                  ...ovenChamber,
                  ovenPanels: data.ovenPanels
                    .filter((ovenPanel) => ovenPanel.ovenChamberId === ovenChamber.id)
                    .map((ovenPanel) => ({...ovenPanel})),
                })),
              ovenPanels: data.ovenPanels
                .filter((ovenPanel) => ovenPanel.ovenId === oven.id)
                .map((ovenPanel) => ({...ovenPanel})),
            })),
        });
      }, 500),
    );
  },
  syncBakery: function (args: {params: SyncBakeryParams}): Promise<SyncBakeryResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {params} = args;

        resolve(params.bakeryId);
      }, 500),
    );
  },
};

export default mockBakeryService;
