import {Box, Collapse, SxProps, Typography} from '@mui/material';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Oven from '../../../../models/entities/oven';
import {OvenModelId} from '../../../../models/entities/oven-model';
import {OvenSubModelId} from '../../../../models/entities/oven-sub-model';
import useAuthStore from '../../../../state/auth';
import arrayUtils from '../../../../utils/arrays';
import {AddOvenData, AddOvenStep, AddOvenSteps} from '../AddOven';

export type SubModelStepProps = {
  ovens: Oven[];
  setSteps: (setter: (steps: AddOvenSteps) => AddOvenSteps) => void;
  setSelectedStep: (step: AddOvenStep) => void;
  setAddOvenData: (setter: (data: AddOvenData) => AddOvenData) => void;
};

function SubModelStep(props: SubModelStepProps) {
  const {ovens, setSteps, setSelectedStep, setAddOvenData} = props;
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const [expandedItem, setExpandedItem] = useState<'none' | 'combi' | 'homeModule'>('none');

  const pairOvens = useMemo(
    () =>
      ovens.filter(
        (oven) =>
          oven.ovenModelId === OvenModelId.Turboram &&
          oven.bakeryId !== user?.companyId &&
          oven.ovenGroupId == null,
      ),
    [ovens, user],
  );

  const itemSx: SxProps = {
    width: '100%',
    paddingBottom: 3,
    color: 'secondary.main',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
      color: 'primary.main',
    },
  };

  function handleSelectOvenSubModel(
    ovenSubModelId: number | null,
    nextStep: 'serialNumber' | 'ovens',
  ) {
    const isOvens = nextStep === 'ovens';
    setAddOvenData(() => ({
      ovenModelId: OvenModelId.Turboram,
      ovenSubModelId,
      selectedOvenId: null,
      serialNumber: '',
      topOvenSerialNumber: '',
      bottomOvenSerialNumber: '',
      ovenChamberSerialNumbers: [],
      description: '',
    }));
    setSteps(() => ({
      model: {visible: true, error: false},
      subModel: {visible: true, error: false},
      option: {visible: false, error: false},
      ovens: {visible: isOvens, error: isOvens},
      chambersNumber: {visible: false, error: false},
      serialNumber: {visible: !isOvens, error: !isOvens},
      description: {visible: false, error: false},
    }));
    setSelectedStep(nextStep);
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', padding: 2}}>
      <Box sx={itemSx} onClick={() => handleSelectOvenSubModel(null, 'serialNumber')}>
        <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
          {t('add_oven_turboram_single_label')}
        </Typography>
      </Box>
      <Box
        sx={{
          ...itemSx,
          fontWeight: expandedItem === 'combi' ? 'bold' : 'normal',
          color: expandedItem === 'combi' ? 'primary.main' : 'text.primary',
        }}
        onClick={() => setExpandedItem(expandedItem === 'combi' ? 'none' : 'combi')}>
        <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
          {t('add_oven_turboram_combi_label')}
        </Typography>
      </Box>
      <Collapse in={expandedItem === 'combi'} unmountOnExit>
        <Box sx={{display: 'flex', flexDirection: 'column', paddingInline: 3}}>
          <Box
            sx={itemSx}
            onClick={() => handleSelectOvenSubModel(OvenSubModelId.TurboramCombi, 'serialNumber')}>
            <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
              {t('add_oven_register_new_oven_label')}
            </Typography>
          </Box>
          {!arrayUtils.isNullOrEmpty(pairOvens) && (
            <Box
              sx={itemSx}
              onClick={() => handleSelectOvenSubModel(OvenSubModelId.TurboramCombi, 'ovens')}>
              <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
                {t('add_oven_pair_new_oven_with_existing_oven_label')}
              </Typography>
            </Box>
          )}
        </Box>
      </Collapse>
      <Box sx={itemSx} onClick={() => handleSelectOvenSubModel(null, 'serialNumber')}>
        <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
          {t('add_oven_turboram_xl_label')}
        </Typography>
      </Box>
      <Box
        sx={{
          ...itemSx,
          fontWeight: expandedItem === 'homeModule' ? 'bold' : 'normal',
          color: expandedItem === 'homeModule' ? 'primary.main' : 'text.primary',
        }}
        onClick={() =>
          setExpandedItem((expandedItem) => (expandedItem === 'homeModule' ? 'none' : 'homeModule'))
        }>
        <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
          {t('add_oven_turboram_single_plus_home_module_label')}
        </Typography>
      </Box>
      <Collapse in={expandedItem === 'homeModule'} unmountOnExit>
        <Box sx={{display: 'flex', flexDirection: 'column', paddingInline: 3}}>
          <Box
            sx={itemSx}
            onClick={() =>
              handleSelectOvenSubModel(OvenSubModelId.TurboramPlusHomeModule, 'serialNumber')
            }>
            <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
              {t('add_oven_register_new_oven_label')}
            </Typography>
          </Box>
          {!arrayUtils.isNullOrEmpty(pairOvens) && (
            <Box
              sx={itemSx}
              onClick={() =>
                handleSelectOvenSubModel(OvenSubModelId.TurboramPlusHomeModule, 'ovens')
              }>
              <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
                {t('add_oven_pair_new_oven_with_existing_oven_label')}
              </Typography>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
}

export default SubModelStep;
