import {Box, Dialog, IconButton, Typography} from '@mui/material';
import {useEffect} from 'react';
import {ReactComponent as AcceptIcon} from '../../assets/icons/accept.svg';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import Icon from './Icon';

function SplashScreen() {
  const {splashScreen, clear} = useSplashScreen();

  const {title, caption, subCaption, nextAction, acceptAction, declineAction} = splashScreen ?? {};

  const isQuestion = acceptAction != null;

  useEffect(() => {
    if (splashScreen != null && !isQuestion) {
      setTimeout(() => {
        nextAction?.();
        clear();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splashScreen]);

  function handleAccept() {
    acceptAction?.();
    clear();
  }

  function handleDecline() {
    declineAction?.();
    clear();
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100vh',
          padding: '10vw',
          backgroundColor: (theme) => theme.palette.primary.main,
        },
      }}
      fullScreen
      open={splashScreen != null}
      keepMounted={false}>
      <Typography
        variant="h4"
        sx={{
          maxWidth: {xs: '100vw', sm: '100vw', md: '60vw'},
          marginBottom: '4vh',
          fontWeight: 500,
          color: 'custom.white',
        }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{marginBottom: '4vh', color: 'custom.white'}}>
        {caption}
      </Typography>
      <Typography variant="body1" sx={{color: 'custom.white'}}>
        {subCaption}
      </Typography>
      {isQuestion && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '10vh',
            display: 'flex',
            justifyContent: 'center',
            width: '80vw',
          }}>
          <IconButton onClick={handleDecline}>
            <Icon IconComponent={DeclineIcon} color="custom.white" size="35px" />
          </IconButton>
          <Box sx={{margin: 2}} />
          <IconButton onClick={handleAccept}>
            <Icon IconComponent={AcceptIcon} color="custom.white" size="35px" />
          </IconButton>
        </Box>
      )}
    </Dialog>
  );
}

export default SplashScreen;
