import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {OvenModelId} from '../../../../models/entities/oven-model';
import {AddOvenData, AddOvenStep, AddOvenSteps} from '../AddOven';

export type OptionStepProps = {
  setSteps: (setter: (steps: AddOvenSteps) => AddOvenSteps) => void;
  setSelectedStep: (step: AddOvenStep) => void;
  setAddOvenData: (setter: (data: AddOvenData) => AddOvenData) => void;
};

function OptionStep(props: OptionStepProps) {
  const {setSteps, setSelectedStep, setAddOvenData} = props;
  const {t} = useTranslation();

  function handleSelectOption(option: 'new' | 'existing') {
    const isExisting = option === 'existing';
    setAddOvenData(() => ({
      ovenModelId: OvenModelId.Modulram,
      ovenSubModelId: null,
      selectedOvenId: null,
      serialNumber: '',
      topOvenSerialNumber: '',
      bottomOvenSerialNumber: '',
      ovenChamberSerialNumbers: [],
      description: '',
    }));
    setSteps(() => ({
      model: {visible: true, error: false},
      subModel: {visible: false, error: false},
      option: {visible: true, error: false},
      ovens: {visible: isExisting, error: isExisting},
      chambersNumber: {visible: !isExisting, error: !isExisting},
      serialNumber: {visible: false, error: false},
      description: {visible: false, error: false},
    }));
    setSelectedStep(isExisting ? 'ovens' : 'chambersNumber');
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, padding: 2}}>
      <Box
        sx={{
          width: '100%',
          color: 'secondary.main',
          cursor: 'pointer',
          '&:hover': {
            fontWeight: 'bold',
            color: 'primary.main',
          },
        }}
        onClick={() => handleSelectOption('new')}>
        <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
          {t('add_oven_register_new_oven_label')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          color: 'secondary.main',
          cursor: 'pointer',
          '&:hover': {
            fontWeight: 'bold',
            color: 'primary.main',
          },
        }}
        onClick={() => handleSelectOption('existing')}>
        <Typography sx={{color: 'inherit', fontWeight: 'inherit'}} variant="body2">
          {t('add_oven_add_new_chamber_to_existing_oven_label')}
        </Typography>
      </Box>
    </Box>
  );
}

export default OptionStep;
