import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as InfoIcon} from '../../assets/icons/info.svg';
import {ReactComponent as NewNotificationsIcon} from '../../assets/icons/new-notification.svg';
import {ReactComponent as NotificationsIcon} from '../../assets/icons/notification.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings.svg';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import Menu from '../common/Menu';

function ProfileMenu() {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const {data: hasUnseenNotifications = false} = useQuery({
    enabled: user != null,
    queryKey: ['notifications'],
    queryFn: () =>
      services.notification.getNotifications({
        query: {userId: user?.id, companyId: user?.companyId},
      }),
    select: (notifications) => notifications.some((notification) => !notification.isSeen),
  });

  return (
    <Menu
      textColor="transparent"
      items={[
        {
          text: t('profile_menu_information_label'),
          icon: InfoIcon,
          selected: pathname.startsWith(paths.profileInformation),
          onSelectItem: () => navigate(`${paths.profileInformation}/preferences`),
        },
        {
          text: t('profile_menu_notifications_label'),
          icon: hasUnseenNotifications ? NewNotificationsIcon : NotificationsIcon,
          selected: pathname.startsWith(paths.profileNotifications),
          onSelectItem: () => navigate(`${paths.profileNotifications}/warnings`),
        },
        {
          text: t('profile_menu_settings_label'),
          icon: SettingsIcon,
          selected: pathname.startsWith(paths.profileSettings),
          onSelectItem: () => navigate(`${paths.profileSettings}/language`),
        },
      ]}
    />
  );
}

export default ProfileMenu;
