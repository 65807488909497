import {Box} from '@mui/material';
import {
  BarElement,
  CategoryScale,
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import {TFunction} from 'i18next';
import {Bar} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
import dateUtils from '../../utils/dates';
import {Language} from '../../utils/types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const grey = 'RGB(152, 150, 150)';
const transparentGrey = 'RGBA(152, 150, 150, 0.7)';
const red = 'RGB(227, 6, 19)';
const transparentRed = 'RGBA(227, 6, 19, 0.5)';

type StatisticsData = {
  date: string;
  onTimeInMinutes: number;
  offTimeInMinutes: number;
  cookingTimeInMinutes: number;
};

function getChartOptions(): ChartOptions<'bar'> {
  return {
    locale: 'uk',
    responsive: true,
    plugins: {
      legend: {position: 'top', labels: {color: grey}},
    },
    scales: {
      y: {
        title: {
          display: true,
          text: '', //locals.getText('statistics_chart_y_axis_label'),
          color: grey,
        },
        ticks: {color: grey},
      },
      x: {
        title: {
          display: true,
          text: '', //locals.getText('statistics_chart_x_axis_label'),
          color: grey,
        },
        ticks: {color: grey},
      },
    },
  };
}

function getChartData(
  statistics: StatisticsData[],
  t: TFunction,
  language: Language,
): ChartData<'bar'> {
  const labels: string[] = [];
  const offTimeDataset: ChartDataset<'bar'> = {
    label: t('statistics_chart_off_time_label'),
    data: [],
    backgroundColor: transparentGrey,
    categoryPercentage: 0.3,
  };
  const onTimeDataset: ChartDataset<'bar'> = {
    label: t('statistics_chart_on_time_label'),
    data: [],
    backgroundColor: transparentRed,
    categoryPercentage: 0.3,
  };
  const cookingTimeDataset: ChartDataset<'bar'> = {
    label: t('statistics_chart_cooking_time_label'),
    data: [],
    backgroundColor: red,
    categoryPercentage: 0.3,
  };
  statistics.forEach((data) => {
    labels.push(dateUtils.toLocaleDateString(new Date(data.date), language));
    offTimeDataset.data.push(data.offTimeInMinutes);
    onTimeDataset.data.push(data.onTimeInMinutes);
    cookingTimeDataset.data.push(data.cookingTimeInMinutes);
  });
  return {
    labels,
    datasets: [offTimeDataset, onTimeDataset, cookingTimeDataset],
  };
}

type ChartProps = {
  statistics: {
    date: string;
    onTimeInMinutes: number;
    offTimeInMinutes: number;
    cookingTimeInMinutes: number;
  }[];
};

function Chart(props: ChartProps) {
  const {t, i18n} = useTranslation();
  return (
    <Box sx={{width: '100%', height: '100%', maxWidth: '650px'}}>
      <Bar
        options={getChartOptions()}
        data={getChartData(props.statistics, t, i18n.language as Language)}
      />
    </Box>
  );
}

export default Chart;
