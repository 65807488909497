import {Box, InputBase, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {ReactComponent as AddIcon} from '../../../../assets/icons/add.svg';
import Recipe from '../../../../models/entities/recipe';
import RecipeType from '../../../../models/entities/recipe-type';
import services from '../../../../services/provider';
import useAuthStore from '../../../../state/auth';
import cryptoUtils from '../../../../utils/crypto';
import stringUtils from '../../../../utils/strings';
import GradientOverflow from '../../../common/GradientOverflow';
import IconButton from '../../../common/IconButton';
import LoadingBackdrop from '../../../common/LoadingBackdrop';
import Span from '../../../common/Span';
import Switch from '../../../common/Switch';
import {pageHeight} from '../../../navigation/Navbar';

type GeneralStepProps = {
  recipeBuild: Recipe;
  setRecipeBuild: (setter: (previousValue: Recipe) => Recipe) => void;
  setError: (error: boolean) => void;
  isUpdate?: boolean;
};

function GeneralStep(props: GeneralStepProps) {
  const {recipeBuild, setRecipeBuild, setError, isUpdate} = props;

  const recipeBuildRecipeTypeIdsSet = new Set(
    recipeBuild.recipeTypes?.map((recipeType) => recipeType.id) ?? [],
  );

  const queryClient = useQueryClient();
  const {t, i18n} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const [recipeTypeDescription, setRecipeTypeDescription] = useState('');

  const {data: recipeTypes = [], isLoading: isLoadingRecipeTypes} = useQuery({
    enabled: user != null,
    queryKey: ['recipeTypes', {ovenModelId: recipeBuild.ovenModelId, companyId: user?.companyId}],
    queryFn: () =>
      services.recipeType.getRecipeTypes({
        query: {
          ovenModelId: recipeBuild.ovenModelId,
          companyId: user?.companyId,
        },
      }),
  });

  const {mutate: createRecipeType, isLoading: isLoadingCreateRecipeType} = useMutation({
    mutationFn: services.recipeType.createRecipeType,
    onSuccess: (_, {request: recipeType}) => {
      queryClient.setQueryData<RecipeType[] | undefined>(
        ['recipeTypes', {ovenModelId: recipeBuild.ovenModelId}],
        (recipeTypes) => (recipeTypes != null ? [...recipeTypes, recipeType] : [recipeType]),
      );
      queryClient.invalidateQueries(['recipeTypes', {ovenModelId: recipeBuild.ovenModelId}]);
      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        recipeTypes: [...(recipeBuild.recipeTypes ?? []), recipeType],
      }));
      setRecipeTypeDescription('');
    },
  });

  function handleCreateRecipeType() {
    if (user != null && !stringUtils.isNullOrWhiteSpace(recipeTypeDescription)) {
      createRecipeType({
        request: {
          id: cryptoUtils.uuid(),
          description: recipeTypeDescription,
          descriptionEn: i18n.language === 'en' ? recipeTypeDescription : undefined,
          descriptionEs: i18n.language === 'es' ? recipeTypeDescription : undefined,
          descriptionPt: i18n.language === 'pt' ? recipeTypeDescription : undefined,
          descriptionFr: i18n.language === 'fr' ? recipeTypeDescription : undefined,
          descriptionPl: i18n.language === 'pl' ? recipeTypeDescription : undefined,
          ovenModelId: recipeBuild.ovenModelId,
          ovenSubModelId: null,
          companyId: user.companyId,
        },
      });
    }
  }

  function handleChangeRecipeName(recipeName: string) {
    setError(stringUtils.isNullOrWhiteSpace(recipeName));
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      name: recipeName,
      nameEn: i18n.language === 'en' ? recipeName : undefined,
      nameEs: i18n.language === 'es' ? recipeName : undefined,
      namePt: i18n.language === 'pt' ? recipeName : undefined,
      nameFr: i18n.language === 'fr' ? recipeName : undefined,
      namePl: i18n.language === 'pl' ? recipeName : undefined,
      description: recipeName,
    }));
  }

  function handleCheckRecipeType(recipeTypeId: string, checked: boolean) {
    const recipeType = recipeTypes.find((recipeType) => recipeType.id === recipeTypeId);
    if (recipeType != null) {
      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        recipeTypes: checked
          ? [...(recipeBuild.recipeTypes ?? []), recipeType]
          : recipeBuild.recipeTypes?.filter((recipeType) => recipeType.id !== recipeTypeId),
      }));
    }
  }

  function renderRecipeTypes() {
    return (
      <Box
        sx={{
          height: `calc(${pageHeight} - 464px)`,
          width: '30vw',
          marginBottom: 2,
        }}>
        <GradientOverflow hideScrollbar>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 3,
            }}>
            <Typography variant="body2" sx={{color: 'text.primary'}}>
              {t('recipe_type_all_label')}
            </Typography>
            <Switch checked />
          </Box>
          {recipeTypes.map((recipeType) => (
            <Box
              key={recipeType.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 3,
              }}>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                {recipeType.description}
              </Typography>
              <Switch
                checked={recipeBuildRecipeTypeIdsSet.has(recipeType.id)}
                onChange={(_, checked) => handleCheckRecipeType(recipeType.id, checked)}
              />
            </Box>
          ))}
        </GradientOverflow>
      </Box>
    );
  }

  const isLoading = isLoadingRecipeTypes || isLoadingCreateRecipeType;

  return (
    <Box sx={{padding: 2, marginBottom: 8}}>
      <Box sx={{width: '30vw'}}>
        <Typography
          variant="body2"
          sx={{fontWeight: 'bold', color: 'text.primary', marginBottom: 1}}>
          {t('recipe_settings_general_step_recipe_name')}
        </Typography>
        <InputBase
          sx={{
            width: '100%',
            fontSize: '0.875rem',
            color: 'text.primary',
            borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
          }}
          value={recipeBuild.name}
          onChange={(event) => handleChangeRecipeName(event.target.value)}
        />
        <Box sx={{minHeight: '22px', textAlign: 'end', marginBottom: 2}}></Box>
      </Box>
      <Typography variant="body2" sx={{fontWeight: 'bold', color: 'text.primary', marginBottom: 3}}>
        {t('recipe_settings_general_step_recipe_type_title')}
        {!isUpdate && (recipeBuild.recipeTypes?.length ?? 0) === 0 && (
          <Span sx={{fontWeight: 'normal', color: 'text.secondary'}}>
            {` ${t('recipe_settings_general_step_recipe_type_caption')}`}
          </Span>
        )}
      </Typography>
      {renderRecipeTypes()}
      <Box sx={{display: 'flex', alignItems: 'center', width: '30vw'}}>
        <InputBase
          sx={{
            width: '100%',
            fontSize: '0.875rem',
            color: 'text.primary',
            borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
            marginRight: 6,
          }}
          placeholder={t('recipe_settings_general_step_add_recipe_type')}
          value={recipeTypeDescription}
          onChange={(event) => setRecipeTypeDescription(event.target.value)}
          onKeyUp={(event) => (event.key === 'Enter' ? handleCreateRecipeType() : null)}
        />
        <IconButton
          IconComponent={AddIcon}
          size="small"
          iconSize="20px"
          onClick={handleCreateRecipeType}
          disabled={stringUtils.isNullOrWhiteSpace(recipeTypeDescription)}
        />
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default GeneralStep;
