import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import DashboardMenu from './DashboardMenu';
import {pageHeight} from './Navbar';

function Dashboard() {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: pageHeight,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '800px',
          height: pageHeight,
          padding: 8,
        }}>
        <Box sx={{flex: 0.5}}>
          <Typography
            variant="h4"
            color="secondary"
            sx={{
              height: '124px',
              display: 'flex',
              alignItems: 'flex-end',
              fontWeight: '500',
            }}>
            {t('dashboard_title_part_one')}
            <br />
            {t('dashboard_title_part_two')}
            <br />
            {t('dashboard_title_part_three')}
          </Typography>
        </Box>
        <DashboardMenu />
      </Box>
    </Box>
  );
}

export default Dashboard;
