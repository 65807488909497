const localStorageUtils = {
  getKey: function (key: string) {
    return `ramalhos-backoffice:${key}`;
  },
  getValue: function <TValueType>(key: string, fallbackValue: TValueType) {
    try {
      const appKey = this.getKey(key);
      const value = localStorage.getItem(appKey);
      return value ? (JSON.parse(value) as TValueType) : fallbackValue;
    } catch (error) {
      return fallbackValue;
    }
  },
  setValue: function <TValueType>(key: string, value: TValueType) {
    try {
      const appKey = this.getKey(key);
      localStorage.setItem(appKey, JSON.stringify(value));
    } catch (error) {
      // Ignore write errors.
    }
  },
  removeValue: function (key: string) {
    try {
      const appKey = this.getKey(key);
      localStorage.removeItem(appKey);
    } catch (error) {
      // Ignore write errors.
    }
  },
};

export default localStorageUtils;
