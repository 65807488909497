import {
  CreateRecipeTypeRequest,
  DeleteRecipeTypeParams,
  DeleteRecipeTypeRecipeParams,
  GetRecipeTypesQuery,
  UpdateRecipeTypeParams,
  UpdateRecipeTypeRequest,
} from '../models/requests/recipe-type';
import {
  CreateRecipeTypeResponse,
  DeleteRecipeTypeRecipeResponse,
  DeleteRecipeTypeResponse,
  GetRecipeTypesResponse,
  UpdateRecipeTypeResponse,
} from '../models/responses/recipe-type';
import baseService from './base';
import endpoints from './endpoints';

export type RecipeTypeService = {
  getRecipeTypes: (args: {query?: GetRecipeTypesQuery}) => Promise<GetRecipeTypesResponse>;
  createRecipeType: (args: {request: CreateRecipeTypeRequest}) => Promise<CreateRecipeTypeResponse>;
  updateRecipeType: (args: {
    params: UpdateRecipeTypeParams;
    request: UpdateRecipeTypeRequest;
  }) => Promise<UpdateRecipeTypeResponse>;
  deleteRecipeType: (args: {params: DeleteRecipeTypeParams}) => Promise<DeleteRecipeTypeResponse>;
  deleteRecipeTypeRecipe: (args: {
    params: DeleteRecipeTypeRecipeParams;
  }) => Promise<DeleteRecipeTypeRecipeResponse>;
};

const recipeTypeService: RecipeTypeService = {
  getRecipeTypes: async function (args: {
    query?: GetRecipeTypesQuery;
  }): Promise<GetRecipeTypesResponse> {
    const {query} = args;

    const result = await baseService.get<GetRecipeTypesResponse, GetRecipeTypesQuery>(
      endpoints.recipeTypes,
      query,
    );

    return result;
  },
  createRecipeType: async function (args: {
    request: CreateRecipeTypeRequest;
  }): Promise<CreateRecipeTypeResponse> {
    const {request} = args;

    const result = await baseService.post<CreateRecipeTypeResponse, CreateRecipeTypeRequest>(
      endpoints.recipeTypes,
      request,
    );

    return result;
  },
  updateRecipeType: async function (args: {
    params: UpdateRecipeTypeParams;
    request: UpdateRecipeTypeRequest;
  }): Promise<UpdateRecipeTypeResponse> {
    const {params, request} = args;

    const result = await baseService.patch<UpdateRecipeTypeResponse, UpdateRecipeTypeRequest>(
      `${endpoints.recipeTypes}/${params.recipeTypeId}`,
      request,
    );

    return result;
  },
  deleteRecipeType: async function (args: {
    params: DeleteRecipeTypeParams;
  }): Promise<DeleteRecipeTypeResponse> {
    const {params} = args;

    const result = await baseService.delete<DeleteRecipeTypeResponse>(
      `${endpoints.recipeTypes}/${params.recipeTypeId}`,
    );

    return result;
  },
  deleteRecipeTypeRecipe: async function (args: {
    params: DeleteRecipeTypeRecipeParams;
  }): Promise<DeleteRecipeTypeRecipeResponse> {
    const {params} = args;

    const result = await baseService.delete<DeleteRecipeTypeRecipeResponse>(
      `${endpoints.recipeTypes}/${params.recipeTypeId}/recipes/${params.recipeId}`,
    );

    return result;
  },
};

export default recipeTypeService;
