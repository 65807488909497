import {NavigateFunction} from 'react-router-dom';
import {create} from 'zustand';
import {queryClient} from '../App';
import {subscriptionToken} from '../firebase/app';
import User from '../models/entities/user';
import paths from '../routes/paths';
import services, {ServiceType, serviceType} from '../services/provider';
import cryptoUtils from '../utils/crypto';
import errorUtils from '../utils/errors';
import localStorageUtils from '../utils/local-storage';
import {clearBreadcrumbsStore} from './breadcrumbs';
import {clearErrorStore} from './errors';

type AuthStore = {
  token: string | null;
  user: User | null;
  isLoading: boolean;
  setUser: (user: User) => void;
};

const useAuthStore = create<AuthStore>((set) => ({
  token: localStorageUtils.getValue('token', null),
  user: null,
  isLoading: false,
  setUser: (user: User) => set({user}),
}));

function clearAuthStore() {
  useAuthStore.setState({token: null, user: null});
}

function setAuthorizationToken(token: string) {
  const rememberMe = localStorageUtils.getValue('rememberme', false);
  if (rememberMe) {
    localStorageUtils.setValue('token', token);
  }
  services.base.setAuthorizationHeader(token);
}

function removeAuthorizationToken() {
  localStorageUtils.removeValue('token');
  services.base.removeAuthorizationHeader();
}

function subscribeToPushNotifications() {
  services.subscription
    .subscribe({
      request: {
        token: subscriptionToken,
      },
    })
    .then(() => {
      console.log('subscribed to push notifications');
    })
    .catch((error) => {
      console.log('could not subscribe to push notifications');
    });
}

function unsubscribeToPushNotifications() {
  services.subscription
    .unsubscribe({
      request: {
        token: subscriptionToken,
      },
    })
    .then(() => {
      console.log('unsubscribed to push notifications');
    })
    .catch((error) => {
      console.log('could not unsubscribe to push notifications');
    });
}

export function setAuthData(token: string, user: User) {
  setAuthorizationToken(token);
  subscribeToPushNotifications();
  useAuthStore.setState({token, user});
}

function getUserId(token: string) {
  const decodedToken = cryptoUtils.decodeJwt(token!, {user: {id: ''}});
  return serviceType === ServiceType.Web ? decodedToken.user.id : token!;
}

export async function persistedLogin(path: string, navigate: NavigateFunction) {
  try {
    const token = localStorageUtils.getValue<string | null>('token', null);
    if (token == null) {
      logout();
      return;
    }
    setAuthorizationToken(token);
    const userId = getUserId(token);
    useAuthStore.setState({token, isLoading: true});
    const user = await services.user.getUser({
      params: {
        userId,
      },
    });
    subscribeToPushNotifications();
    useAuthStore.setState({user});
    if ([paths.home, paths.welcome, paths.login, paths.signup].includes(path)) {
      navigate(paths.dashboard);
    }
  } catch (error) {
    logout();
    errorUtils.handleError(error);
  } finally {
    useAuthStore.setState({isLoading: false});
  }
}

export function logout() {
  unsubscribeToPushNotifications();
  removeAuthorizationToken();
  queryClient.clear();
  clearAuthStore();
  clearErrorStore();
  clearBreadcrumbsStore();
}

export default useAuthStore;
