import {Box, Typography} from '@mui/material';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {OvenModelId} from '../../models/entities/oven-model';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import bakeryUtils from '../../utils/bakeries';
import cleaningUtils from '../../utils/cleaning';
import dateUtils, {weekDays} from '../../utils/dates';
import numberUtils from '../../utils/numbers';
import recipeUtils from '../../utils/recipes';
import scheduleUtils from '../../utils/schedules';
import {Language} from '../../utils/types';
import OvenModelList from '../bakeries/OvenModelList';
import ActionBanner from '../common/ActionBanner';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import {pageHeight} from '../navigation/Navbar';
import BakeryList from './BakeryList';
import CityList from './CityList';
import CountryTabs from './CountryTabs';
import ScheduleList, {ScheduleListItemProps} from './ScheduleList';
import SchedulesMenu from './SchedulesMenu';
import SearchField from './SearchField';
import WeekDayTabs from './WeekDayTabs';

export type ScheduleOrderBy = 'time' | 'oven';

function Schedules() {
  const navigate = useNavigate();
  const {countryId, cityId, bakeryId, ovenModelId, weekDay} = useParams();
  const queryClient = useQueryClient();
  const {t, i18n} = useTranslation();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const {user, isLoading: isLoadingUser} = useAuthStore();

  const [searchText, setSearchText] = useState('');
  const [orderBy, setOrderBy] = useState<ScheduleOrderBy>('time');

  const {data: countries = [], isLoading: isLoadingCountries} = useQuery({
    enabled: user != null,
    queryKey: ['companyCountries', user?.companyId],
    queryFn: () =>
      services.company.getCompanyCountries({
        params: {
          companyId: user?.companyId ?? '',
        },
        query: {
          expand: ['districts', 'cities', 'bakeries'],
        },
      }),
  });

  const {selectedCountry, cities, selectedCity, bakeries, selectedBakery} = useMemo(() => {
    const selectedCountryId = numberUtils.parseInt(countryId);
    const selectedCountry = countries.find((country) => country.id === selectedCountryId);
    const cities = selectedCountry?.districts?.flatMap((district) => district.cities ?? []) ?? [];
    const selectedCityId = numberUtils.parseInt(cityId);
    const selectedCity = cities.find((city) => city.id === selectedCityId);
    const bakeries = bakeryUtils.filterStockBakeries(selectedCity?.bakeries ?? [], user);
    const selectedBakery = bakeries.find((bakery) => bakery.id === bakeryId);
    return {selectedCountry, cities, selectedCity, bakeries, selectedBakery};
  }, [user, countries, countryId, cityId, bakeryId]);

  const {data: ovenModels = [], isLoading: isLoadingOvenModels} = useQuery({
    enabled: user != null && selectedBakery != null,
    queryKey: ['bakeryOvenModels', selectedBakery?.id],
    queryFn: () =>
      services.bakery.getBakeryOvenModels({
        params: {
          bakeryId: selectedBakery!.id,
        },
      }),
  });

  const selectedOvenModel = ovenModels.find(
    (ovenModel) => ovenModel.id === numberUtils.parseInt(ovenModelId),
  );
  const selectedWeekDay = dateUtils.parseWeekDay(weekDay, 1);

  const {data: schedules = [], isLoading: isLoadingSchedules} = useQuery({
    enabled: user != null && selectedOvenModel != null,
    queryKey: [
      'schedules',
      {
        weekDay: selectedWeekDay,
        ovenModelId: selectedOvenModel?.id,
        companyId: user?.companyId,
        expand: [
          'recurrences',
          'recipe',
          'recipePhases',
          'cleaning',
          'ovenPanels',
          'ovenChamber',
          'oven',
          'ovenGroup',
        ],
      },
    ],
    queryFn: () =>
      services.schedule.getSchedules({
        query: {
          weekDay: selectedWeekDay,
          ovenModelId: selectedOvenModel!.id,
          companyId: user!.companyId,
          expand: [
            'recurrences',
            'recipe',
            'recipePhases',
            'cleaning',
            'ovenPanels',
            'ovenChamber',
            'oven',
            'ovenGroup',
          ],
        },
      }),
  });

  const {mutate: deleteOvenPanelSchedule, isLoading: isLoadingDeleteOvenPanelSchedule} =
    useMutation({
      mutationFn: services.ovenPanel.deleteOvenPanelSchedule,
      onSuccess: () => {
        queryClient.invalidateQueries('schedules');
      },
    });

  const items = useMemo(() => {
    const items: ScheduleListItemProps[] = [];
    schedules.forEach((schedule) => {
      const startTime = scheduleUtils.getStartTime(schedule);
      const endTime = scheduleUtils.getEndTime(schedule);
      (schedule.ovenPanels ?? []).forEach((ovenPanel) => {
        items.push({
          scheduleId: schedule.id,
          startTime,
          endTime,
          recipeId: schedule.recipeId,
          recipeName:
            schedule.recipe != null
              ? recipeUtils.getName(schedule.recipe, i18n.language as Language)
              : null,
          cleaningId: schedule.cleaningId,
          cleaningDescription:
            schedule.cleaning != null ? cleaningUtils.getDescription(schedule.cleaning, t) : null,
          ovenPanelId: ovenPanel.id,
          ovenPanelIndex: ovenPanel.ovenChamberOrder ?? ovenPanel.ovenOrder ?? 1,
          ovenChamberId: ovenPanel.ovenChamberId,
          ovenChamberIndex: ovenPanel.ovenChamber?.ovenOrder,
          ovenId: ovenPanel.ovenId,
          ovenDescription: (ovenPanel.ovenChamber?.oven ?? ovenPanel.oven)?.description,
          ovenIndex: (ovenPanel.ovenChamber?.oven ?? ovenPanel.oven)?.ovenGroupOrder,
          ovenGroupId: (ovenPanel.ovenChamber?.oven ?? ovenPanel.oven)?.ovenGroupId,
          ovenGroupDescription: (ovenPanel.ovenChamber?.oven ?? ovenPanel.oven)?.ovenGroup
            ?.description,
        });
      });
    });
    if (orderBy === 'time') {
      items.sort((itemA, itemB) => {
        if (itemA.startTime > itemB.startTime) return 1;
        if (itemA.startTime < itemB.startTime) return -1;
        return 0;
      });
    } else {
      items.sort((itemA, itemB) => {
        const ovenKeyA = `${itemA.ovenGroupDescription} ${itemA.ovenDescription} ${itemA.ovenIndex} ${itemA.ovenChamberIndex}`;
        const ovenKeyB = `${itemB.ovenGroupDescription} ${itemB.ovenDescription} ${itemB.ovenIndex} ${itemB.ovenChamberIndex}`;
        if (ovenKeyA < ovenKeyB) return -1;
        if (ovenKeyA > ovenKeyB) return 1;
        return 0;
      });
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedules, orderBy]);

  useEffect(() => {
    if (selectedCountry == null && !arrayUtils.isNullOrEmpty(countries)) {
      navigate(`${paths.programming}/${countries[0].id}`, {replace: true});
    }
  }, [countries, selectedCountry, navigate]);

  useEffect(() => {
    if (
      selectedCountry != null &&
      selectedCity != null &&
      selectedBakery != null &&
      selectedOvenModel == null &&
      !arrayUtils.isNullOrEmpty(ovenModels)
    ) {
      navigate(
        `${paths.programming}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}/${ovenModels[0].id}/${selectedWeekDay}`,
        {replace: true},
      );
    }
  }, [
    ovenModels,
    selectedCountry,
    selectedCity,
    selectedBakery,
    selectedOvenModel,
    selectedWeekDay,
    navigate,
  ]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('programming_breadcrumb'),
      onClick: () => navigate(paths.programming),
    });
    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => navigate(`${paths.programming}/${selectedCountry.id}`),
      });

      if (selectedCity != null) {
        breadcrumbs.push({
          title: selectedCity.name,
          onClick: () => navigate(`${paths.programming}/${selectedCountry.id}/${selectedCity.id}`),
        });

        if (selectedBakery != null) {
          breadcrumbs.push({
            title: selectedBakery.name,
            onClick: () =>
              navigate(
                `${paths.programming}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}`,
              ),
          });

          if (selectedOvenModel != null) {
            breadcrumbs.push({
              title: selectedOvenModel.description,
              onClick: () =>
                navigate(
                  `${paths.programming}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}/${selectedOvenModel.id}`,
                ),
            });

            breadcrumbs.push({
              title: t(weekDays[selectedWeekDay].key),
            });
          }
        }
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedCity, selectedBakery, selectedOvenModel, selectedWeekDay]);

  function handleUpdateSchedule(scheduleId: string) {
    const schedule = schedules.find((schedule) => schedule.id === scheduleId);
    if (schedule?.recipeId != null) {
      navigate(
        `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${selectedOvenModel?.id}/${selectedWeekDay}/recipe/${scheduleId}`,
      );
    }
    if (schedule?.cleaningId != null) {
      navigate(
        `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${selectedOvenModel?.id}/${selectedWeekDay}/cleaning/${scheduleId}`,
      );
    }
  }

  const isLoading =
    isLoadingUser ||
    isLoadingCountries ||
    isLoadingOvenModels ||
    isLoadingSchedules ||
    isLoadingDeleteOvenPanelSchedule;
  const renderCountryTabs = selectedOvenModel == null;
  const renderDayTabs = selectedOvenModel != null;
  const renderCityList = selectedCountry != null && selectedCity == null;
  const renderBakeryList = selectedCity != null && selectedBakery == null;
  const renderOvenModelList = selectedBakery != null;
  const renderSchedules = selectedOvenModel != null;
  const renderOvensNotFoundLabel = !isLoading && selectedOvenModel == null;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
        }}>
        <Box
          sx={{
            width: '140px',
            height: `calc(${pageHeight} - 96px)`,
            marginTop: '64px',
          }}>
          {renderOvenModelList && (
            <GradientOverflow>
              <OvenModelList
                ovenModels={ovenModels}
                selectedOvenModelId={selectedOvenModel?.id}
                onSelectOvenModel={(ovenModelId) =>
                  navigate(
                    `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${ovenModelId}/${selectedWeekDay}`,
                  )
                }
              />
            </GradientOverflow>
          )}
        </Box>
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{display: 'flex'}}>
          <Box sx={{width: '65vw'}}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '112px',
              }}>
              {renderCountryTabs && (
                <CountryTabs
                  countries={countries}
                  selectedCountryId={selectedCountry?.id}
                  onSelectCountry={(countryId) => navigate(`${paths.programming}/${countryId}`)}
                />
              )}
              {renderDayTabs && (
                <WeekDayTabs
                  selectedWeekDay={selectedWeekDay}
                  onChangeSelectedWeekDay={(weekDay) =>
                    navigate(
                      `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${selectedOvenModel?.id}/${weekDay}`,
                    )
                  }
                />
              )}
              <SearchField
                searchText={searchText}
                onChangeSearchText={setSearchText}
                selectedCity={selectedCity}
                selectedBakery={selectedBakery}
                onReturnToBakeries={() =>
                  navigate(`${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}`)
                }
              />
            </Box>
            {renderCityList && (
              <CityList
                searchText={searchText}
                cities={cities}
                onSelectCity={(cityId) =>
                  navigate(`${paths.programming}/${selectedCountry?.id}/${cityId}`)
                }
                citiesNotFoundMessage={
                  selectedCountry == null ? '' : t('programming_cities_not_found_label')
                }
              />
            )}
            {renderBakeryList && (
              <BakeryList
                searchText={searchText}
                bakeries={bakeries}
                onSelectBakery={(bakeryId) =>
                  navigate(
                    `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${bakeryId}`,
                  )
                }
                bakeriesNotFoundMessage={
                  selectedCity == null ? '' : t('programming_bakeries_not_found_label')
                }
              />
            )}
            {renderSchedules && (
              <Fragment>
                <Box sx={{marginBlock: 2}}>
                  <ActionBanner
                    text={t('programming_add_recipe_schedule_label')}
                    onClick={() =>
                      navigate(
                        `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${ovenModelId}/${selectedWeekDay}/recipe`,
                      )
                    }
                  />
                </Box>
                {selectedOvenModel?.id === OvenModelId.Turboram && (
                  <Box sx={{marginBottom: 2}}>
                    <ActionBanner
                      color="custom.blue"
                      text={t('programming_add_cleaning_schedule_label')}
                      onClick={() =>
                        navigate(
                          `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${ovenModelId}/${selectedWeekDay}/cleaning`,
                        )
                      }
                    />
                  </Box>
                )}
              </Fragment>
            )}
          </Box>
          <Box sx={{width: '15vw'}}>
            {renderSchedules && <SchedulesMenu orderBy={orderBy} setOrderBy={setOrderBy} />}
          </Box>
        </Box>
        {renderSchedules && (
          <Box
            sx={{
              height:
                selectedOvenModel?.id === OvenModelId.Turboram
                  ? `calc(${pageHeight} - 248px)`
                  : `calc(${pageHeight} - 228px)`,
              paddingBottom: 6,
            }}>
            <ScheduleList
              items={items}
              onSelectItem={handleUpdateSchedule}
              onDeleteItem={(ovenPanelId, scheduleId) =>
                deleteOvenPanelSchedule({
                  params: {ovenPanelId, scheduleId},
                })
              }
              itemsNotFoundLabel={isLoading ? '' : t('programming_schedules_not_found_label')}
            />
          </Box>
        )}
        {renderOvensNotFoundLabel && (
          <Box sx={{padding: 2}}>
            <Typography variant="body2" sx={{color: 'text.primary'}}>
              {t('programming_ovens_not_found_label')}
            </Typography>
          </Box>
        )}
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default Schedules;
