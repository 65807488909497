import {Box, InputBase, SxProps, Typography} from '@mui/material';
import {useRef} from 'react';

function getInputSx(color?: string): SxProps {
  return {
    width: '16px',
    height: '36px',
    fontSize: '0.875rem',
    color,
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input': {
      textAlign: 'right',
    },
    '& .Mui-disabled': {
      color,
      WebkitTextFillColor: 'inherit',
    },
  };
}

function getHour(value: string) {
  if (value.length < 3) return '';
  if (value.length === 3) return value[0];
  return value.slice(0, 2);
}

function getMinutes(value: string) {
  if (value.length < 3) return value;
  if (value.length === 3) return value.slice(1);
  return value.slice(2, 4);
}

type TimeInputProps = {
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  color?: string;
  showPlaceholder?: boolean;
  disabled?: boolean;
};

function TimeInput(props: TimeInputProps) {
  const {value, onChange, onFocus, onBlur, color, showPlaceholder, disabled} =
    props;

  const hourInputRef = useRef<HTMLInputElement>(null);
  const minutesInputRef = useRef<HTMLInputElement>(null);

  function handleHourFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.preventDefault();
    minutesInputRef.current?.focus();
  }

  function handleMinutesFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.preventDefault();
    event.target.setSelectionRange(2, 2);
    onFocus?.();
  }

  function handleMinutesBlur(event: React.FocusEvent<HTMLInputElement>) {
    onBlur?.();
  }

  function handleMinutesMouseDown(event: React.MouseEvent<HTMLInputElement>) {
    event.preventDefault();
    minutesInputRef.current?.focus();
  }

  function handleMinutesKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown'
    ) {
      event.preventDefault();
      return;
    }
    const hourInput = hourInputRef.current;
    if (hourInput != null && event.key === 'Backspace') {
      event.preventDefault();
      const hour = hourInput.value;
      if (value.length === 4) {
        onChange(`${hour[0]}${value[1]}${value[2]}`);
        return;
      }
      if (value.length === 3) {
        onChange(`${value[0]}${value[1]}`);
        return;
      }
      if (value.length === 2) {
        onChange(value[0]);
        return;
      }
      if (value.length === 1) {
        onChange('');
        return;
      }
    }
  }

  function handleMinutesChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;
    const numbersRegex = /^[0-9]*$/;
    if (!numbersRegex.test(inputValue)) return;
    if (inputValue.length === 1 && value.length === 0) {
      onChange(inputValue);
      return;
    }
    if (inputValue.length === 2 && value.length === 1) {
      onChange(inputValue);
      return;
    }
    if (inputValue.length === 3 && value.length === 2) {
      onChange(inputValue);
      return;
    }
    if (inputValue.length === 3 && value.length === 3) {
      onChange(`${value[0]}${inputValue[0]}${inputValue[1]}${inputValue[2]}`);
      return;
    }
  }

  return (
    <Box sx={{display: 'flex'}}>
      <InputBase
        inputRef={hourInputRef}
        sx={getInputSx(color)}
        placeholder={showPlaceholder ? '00' : ''}
        value={getHour(value)}
        onFocus={handleHourFocus}
        disabled={disabled}
      />
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '36px',
          color,
          cursor: 'default',
        }}
        variant="body2">
        :
      </Typography>
      <InputBase
        inputRef={minutesInputRef}
        sx={getInputSx(color)}
        placeholder={showPlaceholder ? '00' : ''}
        value={getMinutes(value)}
        onFocus={handleMinutesFocus}
        onBlur={handleMinutesBlur}
        onMouseDown={handleMinutesMouseDown}
        onKeyDown={handleMinutesKeyDown}
        onChange={handleMinutesChange}
        disabled={disabled}
      />
    </Box>
  );
}

export default TimeInput;
