import {
  DeleteNotificationParams,
  DeleteNotificationsRequest,
  GetNotificationsQuery,
  UpdateNotificationParams,
  UpdateNotificationRequest,
} from '../models/requests/notification';
import {
  DeleteNotificationResponse,
  DeleteNotificationsResponse,
  GetNotificationsResponse,
  UpdateNotificationResponse,
} from '../models/responses/notification';
import baseService from './base';
import endpoints from './endpoints';

export type NotificationService = {
  getNotifications: (args: {query?: GetNotificationsQuery}) => Promise<GetNotificationsResponse>;
  updateNotification: (args: {
    params: UpdateNotificationParams;
    request: UpdateNotificationRequest;
  }) => Promise<UpdateNotificationResponse>;
  deleteNotification: (args: {
    params: DeleteNotificationParams;
  }) => Promise<DeleteNotificationResponse>;
  deleteNotifications: (args: {
    request: DeleteNotificationsRequest;
  }) => Promise<DeleteNotificationsResponse>;
};

const notificationService: NotificationService = {
  getNotifications: async function (args: {
    query?: GetNotificationsQuery;
  }): Promise<GetNotificationsResponse> {
    const {query} = args;

    const result = await baseService.get<GetNotificationsResponse, GetNotificationsQuery>(
      endpoints.notifications,
      query,
    );

    return result;
  },
  updateNotification: async function (args: {
    params: UpdateNotificationParams;
    request: UpdateNotificationRequest;
  }): Promise<UpdateNotificationResponse> {
    const {params, request} = args;

    const result = await baseService.patch<UpdateNotificationResponse, UpdateNotificationRequest>(
      `${endpoints.notifications}/${params.notificationId}`,
      request,
    );

    return result;
  },
  deleteNotification: async function (args: {
    params: DeleteNotificationParams;
  }): Promise<DeleteNotificationResponse> {
    const {params} = args;

    const result = await baseService.delete<DeleteNotificationResponse>(
      `${endpoints.notifications}/${params.notificationId}`,
    );

    return result;
  },
  deleteNotifications: async function (args: {
    request: DeleteNotificationsRequest;
  }): Promise<DeleteNotificationsResponse> {
    const {request} = args;

    const result = await baseService.delete<
      DeleteNotificationsResponse,
      null,
      DeleteNotificationsRequest
    >(`${endpoints.notifications}/batch`, null, request);

    return result;
  },
};

export default notificationService;
