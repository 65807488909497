import {GetDistrictsQuery} from '../../models/requests/district';
import {GetDistrictsResponse} from '../../models/responses/district';
import {DistrictService} from '../district';
import {data} from './data';

const mockDistrictService: DistrictService = {
  getDistricts: function (args: {query?: GetDistrictsQuery}): Promise<GetDistrictsResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {query} = args;

        const districts = data.districts
          .filter((district) => query?.countryId == null || district.countryId === query.countryId)
          .map((district) => ({...district}));

        resolve(districts);
      }, 500),
    );
  },
};

export default mockDistrictService;
