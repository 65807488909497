import {Box} from '@mui/material';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {queryClient} from '../../App';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/oven-chamber';
import OvenGroup from '../../models/entities/oven-group';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import bakeryUtils from '../../utils/bakeries';
import numberUtils from '../../utils/numbers';
import ActionBanner from '../common/ActionBanner';
import LoadingBackdrop from '../common/LoadingBackdrop';
import BakeryList from './BakeryList';
import BakeryMenu, {BakeryMenuItem} from './BakeryMenu';
import BakeryOvens from './BakeryOvens';
import BakerySettings from './BakerySettings';
import CityList from './CityList';
import CountryTabs from './CountryTabs';
import SearchField from './SearchField';

function parseMenuItem(value?: string): BakeryMenuItem {
  switch (value) {
    case 'ovens':
    case 'settings':
    case 'recipes':
      return value;
    default:
      return 'settings';
  }
}

function Bakeries() {
  const navigate = useNavigate();
  const {countryId, cityId, bakeryId, menuItem, ovenModelId} = useParams();
  const {t} = useTranslation();
  const {splash} = useSplashScreen();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const {user, isLoading: isLoadingUser} = useAuthStore();

  const [searchText, setSearchText] = useState('');
  const [ovenGroupId, setOvenGroupId] = useState('');
  const [ovenId, setOvenId] = useState('');

  const {data: countries = [], isLoading: isLoadingCountries} = useQuery({
    enabled: user != null,
    queryKey: ['companyCountries', user?.companyId],
    queryFn: () =>
      services.company.getCompanyCountries({
        params: {
          companyId: user!.companyId,
        },
        query: {
          expand: ['districts', 'cities', 'bakeries'],
        },
      }),
  });

  const {selectedCountry, cities, selectedCity, bakeries, selectedBakery} = useMemo(() => {
    const selectedCountryId = numberUtils.parseInt(countryId);
    const selectedCountry = countries.find((country) => country.id === selectedCountryId);
    const cities = selectedCountry?.districts?.flatMap((district) => district.cities ?? []) ?? [];
    const selectedCityId = numberUtils.parseInt(cityId);
    const selectedCity = cities.find((city) => city.id === selectedCityId);
    const bakeries = bakeryUtils.filterStockBakeries(selectedCity?.bakeries ?? [], user);
    const selectedBakery = bakeries.find((bakery) => bakery.id === bakeryId);
    return {selectedCountry, cities, selectedCity, bakeries, selectedBakery};
  }, [user, countries, countryId, cityId, bakeryId]);

  const {data: ovenModels = [], isLoading: isLoadingOvenModels} = useQuery({
    enabled: user != null && selectedBakery != null,
    queryKey: ['bakeryOvenModels', selectedBakery?.id],
    queryFn: () =>
      services.bakery.getBakeryOvenModels({
        params: {
          bakeryId: selectedBakery!.id,
        },
        query: {
          expand: ['ovenGroups', 'ovens', 'ovenChambers', 'ovenPanels'],
        },
      }),
  });

  const selectedOvenModel = ovenModels.find(
    (ovenModel) => ovenModel.id === numberUtils.parseInt(ovenModelId),
  );
  const selectedMenuItem = parseMenuItem(menuItem);

  const {mutate: deleteOvenGroup, isLoading: isLoadingDeleteOvenGroup} = useMutation({
    mutationFn: services.ovenGroup.deleteOvenGroup,
    onSuccess: () => {
      queryClient.invalidateQueries('bakeryOvenModels');
      setOvenGroupId('');
      setOvenId('');
    },
  });

  const {mutate: updateOven, isLoading: isLoadingUpdateOven} = useMutation({
    mutationFn: services.oven.updateOven,
    onSuccess: () => {
      queryClient.invalidateQueries('bakeryOvenModels');
      setOvenGroupId('');
      setOvenId('');
    },
  });

  const {mutate: updateOvenChamber, isLoading: isLoadingUpdateOvenChamber} = useMutation({
    mutationFn: services.ovenChamber.updateOvenChamber,
    onSuccess: () => {
      queryClient.invalidateQueries('bakeryOvenModels');
      setOvenGroupId('');
      setOvenId('');
    },
  });

  useEffect(() => {
    if (selectedCountry == null && !arrayUtils.isNullOrEmpty(countries)) {
      navigate(`${paths.bakeries}/${countries[0].id}`, {replace: true});
    }
  }, [countries, selectedCountry, navigate]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('bakeries_breadcrumb'),
      onClick: () => navigate(paths.bakeries),
    });
    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => navigate(`${paths.bakeries}/${selectedCountry.id}`),
      });

      if (selectedCity != null) {
        breadcrumbs.push({
          title: selectedCity.name,
          onClick: () => navigate(`${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}`),
        });

        if (selectedBakery != null) {
          breadcrumbs.push({
            title: selectedBakery.name,
            onClick: () =>
              navigate(
                `${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}`,
              ),
          });

          const menuItemBreadcrumb =
            selectedMenuItem === 'settings'
              ? t('bakery_settings_breadcrumb')
              : selectedMenuItem === 'ovens'
              ? t('bakery_ovens_breadcrumb')
              : t('bakery_recipes_breadcrumb');

          breadcrumbs.push({
            title: menuItemBreadcrumb,
            onClick: () =>
              navigate(
                `${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}/${
                  selectedBakery.id
                }/${selectedMenuItem}${
                  selectedMenuItem === 'ovens' && ovenModels.length > 0
                    ? `/${ovenModels[0].id}`
                    : ''
                }`,
              ),
          });

          if (selectedMenuItem === 'ovens' && selectedOvenModel != null) {
            breadcrumbs.push({
              title: selectedOvenModel.description,
            });
          }
        }
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCountry,
    selectedCity,
    selectedBakery,
    selectedMenuItem,
    ovenModels,
    selectedOvenModel,
    selectedMenuItem,
  ]);

  function handleDeleteOvenGroup(ovenGroup: OvenGroup) {
    splash({
      title: t('splash_screen_delete_oven_title'),
      caption: t('splash_screen_delete_oven_caption'),
      subCaption: t('splash_screen_delete_oven_sub_caption'),
      acceptAction: () =>
        deleteOvenGroup({
          params: {ovenGroupId: ovenGroup.id},
        }),
    });
  }

  function handleDeleteOven(oven: Oven) {
    splash({
      title: t('splash_screen_delete_oven_title'),
      caption: t('splash_screen_delete_oven_caption'),
      subCaption: t('splash_screen_delete_oven_sub_caption'),
      acceptAction: () =>
        updateOven({
          params: {ovenId: oven.id},
          request: {
            ...oven,
            ovenGroupId: null,
            ovenGroupOrder: null,
            bakeryId: user?.companyId,
          },
        }),
    });
  }

  function handleDeleteOvenChamber(ovenChamber: OvenChamber) {
    splash({
      title: t('splash_screen_delete_oven_title'),
      caption: t('splash_screen_delete_oven_caption'),
      subCaption: t('splash_screen_delete_oven_sub_caption'),
      acceptAction: () =>
        updateOvenChamber({
          params: {ovenChamberId: ovenChamber.id},
          request: {
            ...ovenChamber,
            ovenId: null,
            ovenOrder: null,
            bakeryId: user?.companyId,
          },
        }),
    });
  }

  const isLoading =
    isLoadingUser ||
    isLoadingCountries ||
    isLoadingOvenModels ||
    isLoadingDeleteOvenGroup ||
    isLoadingUpdateOven ||
    isLoadingUpdateOvenChamber;

  const renderBakeryMenu = selectedBakery != null;
  const renderActionBanner = selectedBakery == null;
  const renderCityList = selectedCity == null;
  const renderBakeryList = selectedCity != null && selectedBakery == null;
  const renderBakerySettings =
    selectedCountry != null &&
    selectedCity != null &&
    selectedBakery != null &&
    selectedMenuItem === 'settings';
  const renderBakeryOvens = selectedBakery != null && selectedMenuItem === 'ovens';

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
          marginBlock: '112px',
        }}>
        {renderBakeryMenu && (
          <BakeryMenu
            selectedItem={selectedMenuItem}
            onSelectItem={(menuItem) =>
              navigate(
                `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${
                  selectedBakery?.id
                }/${menuItem}${
                  menuItem === 'ovens' && ovenModels.length > 0 ? `/${ovenModels[0].id}` : ''
                }`,
              )
            }
          />
        )}
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{width: '65vw'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '112px',
            }}>
            <CountryTabs
              countries={countries}
              selectedCountryId={selectedCountry?.id}
              onSelectCountry={(countryId) => navigate(`${paths.bakeries}/${countryId}`)}
            />
            <SearchField
              searchText={searchText}
              setSearchText={setSearchText}
              selectedCity={selectedCity}
              selectedBakery={selectedBakery}
              onReturnToBakeries={() =>
                navigate(`${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}`)
              }
            />
          </Box>
          {renderActionBanner && (
            <Box sx={{marginBlock: 2}}>
              <ActionBanner
                text={t('bakeries_create_bakery_button_label')}
                onClick={() => navigate(paths.bakeriesCreate)}
              />
            </Box>
          )}
          {renderCityList && (
            <CityList
              searchText={searchText}
              cities={cities}
              onSelectCity={(cityId) =>
                navigate(`${paths.bakeries}/${selectedCountry?.id}/${cityId}`)
              }
              citiesNotFoundMessage={
                selectedCountry == null ? '' : t('bakeries_cities_not_found_label')
              }
            />
          )}
          {renderBakeryList && (
            <BakeryList
              searchText={searchText}
              bakeries={bakeries}
              onSelectBakery={(bakeryId) =>
                navigate(
                  `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${bakeryId}/${selectedMenuItem}`,
                )
              }
              bakeriesNotFoundMessage={
                selectedCity == null ? '' : t('bakeries_bakeries_not_found_label')
              }
            />
          )}
        </Box>
        {renderBakerySettings && (
          <BakerySettings
            selectedCountry={selectedCountry}
            selectedCity={selectedCity}
            selectedBakery={selectedBakery}
          />
        )}
        {renderBakeryOvens && (
          <BakeryOvens
            ovenModels={ovenModels}
            selectedCountryId={selectedCountry?.id}
            selectedCityId={selectedCity?.id}
            selectedBakeryId={selectedBakery?.id}
            selectedOvenModel={selectedOvenModel}
            selectedOvenGroupId={ovenGroupId}
            selectedOvenId={ovenId}
            onSelectOvenModel={(ovenModelId) =>
              navigate(
                `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${selectedMenuItem}/${ovenModelId}`,
              )
            }
            onSelectOvenGroup={(ovenGroupId) =>
              setOvenGroupId((selectedOvenGroupId) =>
                ovenGroupId === selectedOvenGroupId ? '' : ovenGroupId,
              )
            }
            onSelectOven={(ovenId) =>
              setOvenId((selectedOvenId) => (ovenId === selectedOvenId ? '' : ovenId))
            }
            onDeleteOvenGroup={handleDeleteOvenGroup}
            onDeleteOven={handleDeleteOven}
            onDeleteOvenChamber={handleDeleteOvenChamber}
          />
        )}
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default Bakeries;
