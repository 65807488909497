import {Box, InputBase, Typography} from '@mui/material';
import {ChangeEvent, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as AcceptSimpleIcon} from '../../../../../assets/icons/accept-simple.svg';
import {ReactComponent as DeclineSimpleIcon} from '../../../../../assets/icons/decline-simple.svg';
import Turboram5tImage from '../../../../../assets/images/turboram-5t-front.png';
import Turboram9tImage from '../../../../../assets/images/turboram-9t-front.png';
import TurboramHomeModuleImage from '../../../../../assets/images/turboram-home-module-front.png';
import TurboramXlImage from '../../../../../assets/images/turboram-xl-front.png';
import {OvenModelId} from '../../../../../models/entities/oven-model';
import {OvenSubModelId} from '../../../../../models/entities/oven-sub-model';
import useAuthStore from '../../../../../state/auth';
import stringUtils from '../../../../../utils/strings';
import Icon from '../../../../common/Icon';
import Image from '../../../../common/Image';
import {SerialNumberStepProps} from './SerialNumberStep';

function TurboramCombiSerialNumberStep(props: SerialNumberStepProps) {
  const {ovens, steps, setSteps, selectedStep, setSelectedStep, addOvenData, setAddOvenData} =
    props;
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);

  const [selectedOvenPosition, setSelectedOvenPosition] = useState<'top' | 'bottom'>('bottom');

  const {topOven, bottomOven} = useMemo(() => {
    let topOven = ovens.find((oven) => oven.serialNumber === addOvenData.topOvenSerialNumber);
    const topOvenIsValid =
      topOven != null &&
      topOven.ovenModelId === OvenModelId.Turboram &&
      (topOven.bakeryId === user?.companyId ||
        (selectedOvenPosition === 'top' && topOven.id === addOvenData.selectedOvenId));
    const bottomOven = ovens.find(
      (oven) => oven.serialNumber === addOvenData.bottomOvenSerialNumber,
    );
    if (!topOvenIsValid) {
      topOven = undefined;
    }
    const bottomOvenIsValid =
      bottomOven != null &&
      bottomOven.id !== topOven?.id &&
      bottomOven.ovenModelId === OvenModelId.Turboram &&
      (bottomOven.bakeryId === user?.companyId ||
        (selectedOvenPosition === 'bottom' && bottomOven.id === addOvenData.selectedOvenId));
    return {
      topOven: topOvenIsValid ? topOven : null,
      bottomOven: bottomOvenIsValid ? bottomOven : null,
    };
  }, [
    user,
    ovens,
    addOvenData.selectedOvenId,
    addOvenData.topOvenSerialNumber,
    addOvenData.bottomOvenSerialNumber,
    selectedOvenPosition,
  ]);

  function validateSerialNumbers(topOvenSerialNumber: string, bottomOvenSerialNumber: string) {
    const topOven = ovens.find((oven) => oven.serialNumber === topOvenSerialNumber);
    const bottomOven = ovens.find((oven) => oven.serialNumber === bottomOvenSerialNumber);
    const isValid =
      topOven != null &&
      topOven.ovenModelId === OvenModelId.Turboram &&
      (topOven.bakeryId === user?.companyId ||
        (selectedOvenPosition === 'top' && topOven.id === addOvenData.selectedOvenId)) &&
      bottomOven != null &&
      bottomOven.id !== topOven.id &&
      bottomOven.ovenModelId === OvenModelId.Turboram &&
      (bottomOven.bakeryId === user?.companyId ||
        (selectedOvenPosition === 'bottom' && bottomOven.id === addOvenData.selectedOvenId));
    setSteps((steps) => ({
      ...steps,
      serialNumber: {visible: true, error: !isValid},
      description: {
        ...steps.description,
        error: stringUtils.isNullOrWhiteSpace(addOvenData.description),
      },
    }));
  }

  function handleChangeSelectedOvenPosition() {
    setSelectedOvenPosition(selectedOvenPosition === 'top' ? 'bottom' : 'top');
    setAddOvenData((addOvenData) => ({
      ...addOvenData,
      topOvenSerialNumber: addOvenData.bottomOvenSerialNumber,
      bottomOvenSerialNumber: addOvenData.topOvenSerialNumber,
    }));
  }

  function handleChangeTopOvenSerialNumber(event: ChangeEvent<HTMLInputElement>) {
    setAddOvenData((addOvenData) => ({...addOvenData, topOvenSerialNumber: event.target.value}));
    setSteps((steps) => ({
      ...steps,
      serialNumber: {visible: true, error: true},
      description: {
        ...steps.description,
        error: stringUtils.isNullOrWhiteSpace(addOvenData.description),
      },
    }));
    validateSerialNumbers(event.target.value, addOvenData.bottomOvenSerialNumber);
  }

  function handleChangeBottomOvenSerialNumber(event: ChangeEvent<HTMLInputElement>) {
    setAddOvenData((addOvenData) => ({...addOvenData, bottomOvenSerialNumber: event.target.value}));
    setSteps((steps) => ({
      ...steps,
      serialNumber: {visible: true, error: true},
      description: {
        ...steps.description,
        error: stringUtils.isNullOrWhiteSpace(addOvenData.description),
      },
    }));
    validateSerialNumbers(addOvenData.topOvenSerialNumber, event.target.value);
  }

  function handleChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    setAddOvenData((addOvenData) => ({...addOvenData, description: event.target.value}));
    setSteps((steps) => ({
      ...steps,
      description: {visible: true, error: stringUtils.isNullOrWhiteSpace(event.target.value)},
    }));
  }

  if (addOvenData.selectedOvenId != null) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'space-between', padding: 2}}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
          {selectedOvenPosition === 'top' && topOven != null && (
            <Box sx={{opacity: 0.5}}>
              <Typography sx={{fontWeight: 'bold', color: 'text.primary'}} variant="body2">
                {topOven.description}
              </Typography>
              <Typography sx={{color: 'text.primary'}} variant="body2">{`${t(
                'add_oven_serial_number_label',
              )}: ${topOven.serialNumber}`}</Typography>
            </Box>
          )}
          {selectedOvenPosition === 'bottom' && bottomOven != null && (
            <Box sx={{opacity: 0.5}}>
              <Typography sx={{fontWeight: 'bold', color: 'text.primary'}} variant="body2">
                {bottomOven.description}
              </Typography>
              <Typography sx={{color: 'text.primary'}} variant="body2">{`${t(
                'add_oven_serial_number_label',
              )}: ${bottomOven.serialNumber}`}</Typography>
            </Box>
          )}
          {selectedOvenPosition === 'top' ? (
            <Box
              sx={{opacity: selectedStep === 'serialNumber' ? 1 : 0.5}}
              onClick={() => setSelectedStep('serialNumber')}>
              <Typography sx={{color: 'text.primary'}} variant="body2">
                {t('add_oven_oven_serial_number_label')}
              </Typography>
              <InputBase
                sx={{
                  width: '25vw',
                  marginTop: 2,
                  fontSize: '0.875rem',
                  color: 'text.primary',
                  borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
                }}
                value={addOvenData.bottomOvenSerialNumber}
                onChange={handleChangeBottomOvenSerialNumber}
                endAdornment={
                  stringUtils.isNullOrWhiteSpace(addOvenData.bottomOvenSerialNumber) ? undefined : (
                    <Icon
                      IconComponent={bottomOven == null ? DeclineSimpleIcon : AcceptSimpleIcon}
                      color="custom.grey"
                      size="16px"
                    />
                  )
                }
              />
              <Box sx={{minHeight: '22px', textAlign: 'end'}}>
                {!stringUtils.isNullOrWhiteSpace(addOvenData.bottomOvenSerialNumber) &&
                  bottomOven == null && (
                    <Typography sx={{color: 'primary.main'}} variant="caption">
                      {t('add_oven_invalid_code_label')}
                    </Typography>
                  )}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{opacity: selectedStep === 'serialNumber' ? 1 : 0.5}}
              onClick={() => setSelectedStep('serialNumber')}>
              <Typography sx={{color: 'text.primary'}} variant="body2">
                {t('add_oven_oven_serial_number_label')}
              </Typography>
              <InputBase
                sx={{
                  width: '25vw',
                  marginTop: 2,
                  fontSize: '0.875rem',
                  color: 'text.primary',
                  borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
                }}
                value={addOvenData.topOvenSerialNumber}
                onChange={handleChangeTopOvenSerialNumber}
                endAdornment={
                  stringUtils.isNullOrWhiteSpace(addOvenData.topOvenSerialNumber) ? undefined : (
                    <Icon
                      IconComponent={topOven == null ? DeclineSimpleIcon : AcceptSimpleIcon}
                      color="custom.grey"
                      size="16px"
                    />
                  )
                }
              />
              <Box sx={{minHeight: '22px', textAlign: 'end'}}>
                {!stringUtils.isNullOrWhiteSpace(addOvenData.topOvenSerialNumber) &&
                  topOven == null && (
                    <Typography sx={{color: 'primary.main'}} variant="caption">
                      {t('add_oven_invalid_code_label')}
                    </Typography>
                  )}
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: steps.description.visible ? 'block' : 'none',
              opacity: selectedStep === 'description' ? 1 : 0.5,
            }}
            onClick={() => setSelectedStep('description')}>
            <Typography sx={{color: 'text.primary'}} variant="body2">
              {t('add_oven_oven_description_label')}
            </Typography>
            <InputBase
              sx={{
                width: '25vw',
                marginTop: 2,
                fontSize: '0.875rem',
                color: 'text.primary',
                borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
              }}
              value={addOvenData.description}
              onChange={handleChangeDescription}
            />
          </Box>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
          <Image
            sx={{
              width: '200px',
              marginBottom: topOven == null ? 4 : 0,
              opacity: topOven == null ? 0.5 : 1,
            }}
            src={
              topOven != null
                ? topOven.ovenSubModelId === OvenSubModelId.TurboramHomeModule
                  ? TurboramHomeModuleImage
                  : topOven.ovenSubModelId === OvenSubModelId.TurboramXL
                  ? TurboramXlImage
                  : topOven.ovenSubModelId === OvenSubModelId.Turboram9T
                  ? Turboram9tImage
                  : Turboram5tImage
                : Turboram5tImage
            }
            alt="Turboram Top"
          />
          {bottomOven != null && (
            <Image
              sx={{width: '200px'}}
              src={
                bottomOven.ovenSubModelId === OvenSubModelId.TurboramHomeModule
                  ? TurboramHomeModuleImage
                  : bottomOven.ovenSubModelId === OvenSubModelId.TurboramXL
                  ? TurboramXlImage
                  : bottomOven.ovenSubModelId === OvenSubModelId.Turboram9T
                  ? Turboram9tImage
                  : Turboram5tImage
              }
              alt="Turboram Bottom"
            />
          )}
          {topOven != null && bottomOven != null && (
            <Typography
              sx={{marginTop: 2, textAlign: 'end', color: 'text.primary', cursor: 'pointer'}}
              variant="caption"
              onClick={handleChangeSelectedOvenPosition}>
              {t('add_oven_change_oven_order_label')}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: 2}}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
        <Box
          sx={{opacity: selectedStep === 'serialNumber' ? 1 : 0.5}}
          onClick={() => setSelectedStep('serialNumber')}>
          <Typography sx={{color: 'text.primary'}} variant="body2">
            {t('add_oven_top_oven_serial_number_label')}
          </Typography>
          <InputBase
            sx={{
              width: '25vw',
              marginTop: 2,
              fontSize: '0.875rem',
              color: 'text.primary',
              borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
            }}
            value={addOvenData.topOvenSerialNumber}
            onChange={handleChangeTopOvenSerialNumber}
            endAdornment={
              stringUtils.isNullOrWhiteSpace(addOvenData.topOvenSerialNumber) ? undefined : (
                <Icon
                  IconComponent={topOven == null ? DeclineSimpleIcon : AcceptSimpleIcon}
                  color="custom.grey"
                  size="16px"
                />
              )
            }
          />
          <Box sx={{minHeight: '22px', textAlign: 'end'}}>
            {!stringUtils.isNullOrWhiteSpace(addOvenData.topOvenSerialNumber) && topOven == null && (
              <Typography sx={{color: 'primary.main'}} variant="caption">
                {t('add_oven_invalid_code_label')}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{opacity: selectedStep === 'serialNumber' ? 1 : 0.5}}
          onClick={() => setSelectedStep('serialNumber')}>
          <Typography sx={{color: 'text.primary'}} variant="body2">
            {t('add_oven_bottom_oven_serial_number_label')}
          </Typography>
          <InputBase
            sx={{
              width: '25vw',
              marginTop: 2,
              fontSize: '0.875rem',
              color: 'text.primary',
              borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
            }}
            value={addOvenData.bottomOvenSerialNumber}
            onChange={handleChangeBottomOvenSerialNumber}
            endAdornment={
              stringUtils.isNullOrWhiteSpace(addOvenData.bottomOvenSerialNumber) ? undefined : (
                <Icon
                  IconComponent={bottomOven == null ? DeclineSimpleIcon : AcceptSimpleIcon}
                  color="custom.grey"
                  size="16px"
                />
              )
            }
          />
          <Box sx={{minHeight: '22px', textAlign: 'end'}}>
            {!stringUtils.isNullOrWhiteSpace(addOvenData.bottomOvenSerialNumber) &&
              bottomOven == null && (
                <Typography sx={{color: 'primary.main'}} variant="caption">
                  {t('add_oven_invalid_code_label')}
                </Typography>
              )}
          </Box>
        </Box>
        <Box
          sx={{
            display: steps.description.visible ? 'block' : 'none',
            opacity: selectedStep === 'description' ? 1 : 0.5,
          }}
          onClick={() => setSelectedStep('description')}>
          <Typography sx={{color: 'text.primary'}} variant="body2">
            {t('add_oven_oven_description_label')}
          </Typography>
          <InputBase
            sx={{
              width: '25vw',
              marginTop: 2,
              fontSize: '0.875rem',
              color: 'text.primary',
              borderBottom: (theme) => '1px solid ' + theme.palette.custom.grey,
            }}
            value={addOvenData.description}
            onChange={handleChangeDescription}
          />
        </Box>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px', margin: 2}}>
        {topOven != null && (
          <Image
            sx={{width: '200px'}}
            src={
              topOven.ovenSubModelId === OvenSubModelId.TurboramHomeModule
                ? TurboramHomeModuleImage
                : topOven.ovenSubModelId === OvenSubModelId.TurboramXL
                ? TurboramXlImage
                : topOven.ovenSubModelId === OvenSubModelId.Turboram9T
                ? Turboram9tImage
                : Turboram5tImage
            }
            alt="Turboram Top"
          />
        )}
        {bottomOven != null && (
          <Image
            sx={{width: '200px'}}
            src={
              bottomOven.ovenSubModelId === OvenSubModelId.TurboramHomeModule
                ? TurboramHomeModuleImage
                : bottomOven.ovenSubModelId === OvenSubModelId.TurboramXL
                ? TurboramXlImage
                : bottomOven.ovenSubModelId === OvenSubModelId.Turboram9T
                ? Turboram9tImage
                : Turboram5tImage
            }
            alt="Turboram Bottom"
          />
        )}
      </Box>
    </Box>
  );
}

export default TurboramCombiSerialNumberStep;
