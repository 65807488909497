import {Box} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import paths from '../../routes/paths';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import Tabs from '../common/Tabs';
import InformationList from './InformationList';

function getTabIndex(tab?: string) {
  switch (tab) {
    case 'preferences':
    default:
      return 0;
  }
}

function Information() {
  const navigate = useNavigate();
  const {tab} = useParams();
  const {t} = useTranslation();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const tabs = [{value: 'preferences', label: t('profile_information_tabs_preferences_label')}];
  const selectedTabIndex = getTabIndex(tab);
  const selectedTab = tabs[selectedTabIndex];

  useEffect(() => {
    setBreadcrumbs([
      {
        title: t('profile_breadcrumb'),
        onClick: () => navigate(paths.profile),
      },
      {
        title: t('profile_information_breadcrumb'),
        onClick: () => navigate(`${paths.profileInformation}/preferences`),
      },
      {
        title: selectedTab.label,
      },
    ]);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <Box sx={{width: '80vw'}}>
      <Box sx={{height: '112px'}}>
        <Tabs
          hideIndicator
          values={tabs.map((tab) => tab.label)}
          selectedTabIndex={selectedTabIndex}
        />
      </Box>
      <InformationList />
    </Box>
  );
}

export default Information;
