import {create} from 'zustand';

export type Breadcrumb = {
  title: string;
  onClick?: () => void;
};

type BreadcrumbsStore = {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
};

const useBreadcrumbsStore = create<BreadcrumbsStore>((set) => ({
  breadcrumbs: [],
  setBreadcrumbs: (breadcrumbs) => set({breadcrumbs}),
}));

export function clearBreadcrumbsStore() {
  useBreadcrumbsStore.setState({breadcrumbs: []});
}

export default useBreadcrumbsStore;
