import Recipe from '../models/entities/recipe';
import {Language} from './types';

const recipeUtils = {
  getName: function (recipe: Recipe, language: Language) {
    switch (language) {
      case 'en':
        return recipe.nameEn ?? recipe.name;
      case 'es':
        return recipe.nameEs ?? recipe.name;
      case 'pt':
        return recipe.namePt ?? recipe.name;
      case 'fr':
        return recipe.nameFr ?? recipe.name;
      case 'pl':
        return recipe.namePl ?? recipe.name;
      default:
        return recipe.name;
    }
  },
  getDuration: function (recipe: Recipe) {
    return (
      recipe.recipePhases?.reduce((duration, recipePhase) => duration + recipePhase.duration, 0) ??
      0
    );
  },
};

export default recipeUtils;
